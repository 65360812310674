import React, { useCallback } from 'react';
import {
  Button,
  Flex,
  Text,
  useBreakpointValue,
  View,
} from '@aws-amplify/ui-react';
import { MdOutlineOpenInNew } from 'react-icons/md';
import TableWithPaginator from 'components/TableWithPaginator';
import { useNavigate } from 'react-router-dom';
import { dateOnly } from 'utils/dates';
import { throttle } from 'lodash';
import useHabitat from 'hooks/utils/useHabitat';
import { useTranslation } from 'react-i18next';
import ResultsCounter from 'components/ResultsCounter';
import useAsync from 'hooks/utils/useAsync';
import { queryRootFormsByHabitat } from 'services/graphql/RootForm';
import Skeleton from 'components/Skeleton';
import styles from './AffiliateFormsPage.module.css';

const AffiliateFormsPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  localStorage.removeItem('goto');

  const titleStyle = useBreakpointValue({
    base: 'theme-subtitle-s1',
    large: 'theme-headline-medium',
  });

  // Get context

  const { habitat } = useHabitat();

  const fetchForms = useCallback(async () => {
    const forms = await queryRootFormsByHabitat(habitat?.id || '');
    return forms.sort(
      (a, b) =>
        new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
    );
  }, [habitat]);

  // Get Forms
  const { value: forms } = useAsync({
    asyncFunction: fetchForms,
  });

  const onClickView = (id: string) => {
    navigate(`../${id}`);
  };

  return (
    <Flex
      padding={{ base: '24px', large: '32px' }}
      direction="column"
      gap="60px"
    >
      <Flex
        direction={{
          base: 'column',
          medium: 'row',
        }}
        justifyContent="space-between"
        alignItems="center"
      >
        <Flex direction="column">
          <span className={`${titleStyle} ${styles.title}`}>
            {t('pages.habitat.affiliate.forms.title')}
          </span>
        </Flex>
      </Flex>
      <Flex direction="column" gap="16px">
        <Flex
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Flex direction="column" alignItems="start" gap="12px">
            <View className="theme-subtitle-s2">
              <Text as="span" alignSelf="center">
                {t('pages.habitat.affiliate.forms.table.title')}
              </Text>
            </View>
            <ResultsCounter number={(forms || []).length} skeleton={!forms} />
          </Flex>
        </Flex>

        <TableWithPaginator
          headers={[
            {
              id: 'name',
              value: t('pages.habitat.affiliate.forms.table.name'),
              width: '70%',
            },
            {
              id: 'dateCreated',
              value: t('pages.habitat.affiliate.forms.table.dateCreated'),
              width: '15%',
            },

            {
              id: 'view',
              value: t('pages.habitat.affiliate.forms.table.view'),
              textAlign: 'center',
              width: '15%',
            },
          ]}
          data={
            forms
              ? forms.map((data, index) => ({
                  id: index,
                  cells: [
                    { value: data.name, id: 'name' },
                    { value: dateOnly(data.createdAt), id: 'dateCreated' },
                    {
                      value: (
                        <Flex width="100%" justifyContent="center">
                          <Button
                            variation="link"
                            padding="0"
                            onClick={throttle(() => onClickView(data.id), 500)}
                          >
                            <MdOutlineOpenInNew
                              size="24px"
                              color="var(--amplify-colors-neutral-90)"
                            />
                          </Button>
                        </Flex>
                      ),
                      id: 'view',
                    },
                  ],
                }))
              : [0, 1, 2, 3, 4].map((index) => ({
                  id: index,
                  cells: [
                    {
                      value: <Skeleton variation="text" numOfCharacters={15} />,
                      id: 'name',
                    },
                    {
                      value: <Skeleton variation="text" numOfCharacters={8} />,
                      id: 'dateCreated',
                    },
                    {
                      value: (
                        <Flex width="100%" justifyContent="center">
                          <Skeleton variation="text" numOfCharacters={2} />
                        </Flex>
                      ),
                      id: 'view',
                    },
                  ],
                }))
          }
        />
      </Flex>
    </Flex>
  );
};

export default AffiliateFormsPage;
