/* eslint-disable no-shadow */

import {
  FormAnswer,
  FormTranslation,
  Habitat,
  RootForm,
  TestApplication,
  TestCycle,
} from 'API';

export const enum DISPLAY {
  ERROR = 'error',
  APPLICATION = 'application',
}

export const enum ERROR {
  CYCLE_NOT_FOUND = 'Cycle not found',
  UNEXPECTED_ERROR = 'Unexpected error',
}

interface DisplayErrorProps {
  display: DISPLAY.ERROR;
  data: {
    error: ERROR;
  };
}

interface DisplayFormProps {
  display: DISPLAY.APPLICATION;
  data: {
    formAnswers: FormAnswer[];
  };
}

export type DataProps = DisplayErrorProps | DisplayFormProps;

interface FormProps {
  habitat?: Habitat;
  application?: TestApplication;
  cycle?: TestCycle;
  rootForm?: RootForm;
  formContainer?: boolean;
  formSchema: object;
  serializedHelpContent?: string;
  translations: FormTranslation[];
}

export default FormProps;
