import { Components } from 'formiojs';
import City from './City';
import Review from './Review';
import CustomFile from './CustomFile';

Components.setComponents({
  city: City,
  review: Review,
  file: CustomFile,
});
