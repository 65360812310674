import React, { useState } from 'react';
import CustomButton from 'components/CustomButton';
import { useTranslation } from 'react-i18next';
import { FormTranslation } from 'API';
import {
  queryFormTranslation,
  updateFormTranslation,
} from 'services/graphql/FormTranslations';
import JsonField from '../JsonField';
import styles from './Tab.module.css';
import { TJson } from '../JsonField/JsonField';

interface ITabProperties {
  formTranslation: FormTranslation;
  setLoading: (loading: boolean) => void;
  revalidate: () => void;
}

const Tab = ({ formTranslation, setLoading, revalidate }: ITabProperties) => {
  const { t } = useTranslation();

  const [autoTranslation, setAutoTranslation] = useState(
    JSON.parse(formTranslation.autoTexts) as TJson
  );
  const [manualTranslation, setManualTranslation] = useState(
    JSON.parse(formTranslation.manualTexts) as TJson
  );

  return (
    <div className={`${styles.tabContainer}`}>
      <div className={`${styles.firstRow}`}>
        <span className="theme-subtitle-s1">
          {t(
            'pages.admin.forms.edit.components.translationsTab.components.tab.autoText'
          )}
        </span>
        <CustomButton
          onClick={async () => {
            try {
              setLoading(true);

              const originalFormTranslation = await queryFormTranslation(
                formTranslation.id
              );

              if (!originalFormTranslation) {
                throw new Error("Form translation doesn't exists");
              }

              const formTranslationWithChanges = {
                id: formTranslation.id,
                manualTexts: JSON.stringify(manualTranslation),
                autoTexts: JSON.stringify(autoTranslation),
              };

              await updateFormTranslation(formTranslationWithChanges);

              revalidate();
            } catch (error) {
              console.log('Error updating translations.');
            } finally {
              setLoading(false);
            }
          }}
        >
          {t(
            'pages.admin.forms.edit.components.translationsTab.components.tab.update'
          )}
        </CustomButton>
      </div>
      <JsonField
        json={autoTranslation}
        onChange={(newJson) => {
          setAutoTranslation(newJson);
        }}
        disableAdd
        disableDelete
        disableUpdateKey
      />
      <span className="theme-subtitle-s1">
        {t(
          'pages.admin.forms.edit.components.translationsTab.components.tab.manualText'
        )}
      </span>
      <JsonField
        json={manualTranslation}
        onChange={(newJson) => {
          setManualTranslation(newJson);
        }}
      />
    </div>
  );
};

export default Tab;
