import { Flex, Text, View } from '@aws-amplify/ui-react';
import CustomButton from 'components/CustomButton/CustomButton';
import ExpandableCard from 'components/ExpandableCard';
import LexicalEditor from 'components/LexicalEditor';
import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ReportStatus } from 'API';
import StatusChip from '../StatusChip';
import style from './ReportCard.module.css';

interface IProperties {
  rootFormName?: string;
  cycleName?: string;
  title: string;
  status: ReportStatus;
  editorState: string;
  applicationRoute?: string;
}

const ReportCard = ({
  rootFormName,
  cycleName,
  title,
  status,
  editorState,
  applicationRoute: applicantionRoute,
}: IProperties) => {
  const { t } = useTranslation();
  return (
    <ExpandableCard>
      <div className={style.container}>
        <Flex flex="1" width="100%" direction="column">
          <View className={`theme-subtitle-s1 ${style.title}`}>
            <Text>{title}</Text>
          </View>
          <View className={`theme-subtitle-s2 ${style.form}`}>
            <Text>{rootFormName}</Text>
          </View>
          <View>
            <Text as="span" className={style.cycle}>
              {cycleName}
            </Text>
          </View>
          <StatusChip status={status} />
          <LexicalEditor serializedEditorState={editorState} />
          {applicantionRoute && (
            <Flex justifyContent="right">
              <Link to={applicantionRoute}>
                <CustomButton>
                  {t('components.decisionCard.button')}
                </CustomButton>
              </Link>
            </Flex>
          )}
        </Flex>
      </div>
    </ExpandableCard>
  );
};

export default ReportCard;
