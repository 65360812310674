import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Wizard } from '@formio/react';
import { generateSubmission } from 'utils/formio';
import { Options } from '@formio/react/lib/components/Form';
import CustomButton from 'components/CustomButton';
import { convertTranlationsToObject } from 'utils/translations';
import { ApplicationTypes, FormAnswer } from 'API';
import { ApplicationTabProps } from './ApplicationTab.types';
import PaperApplicationTable from './components/PaperApplicationTable';
import style from './ApplicationTab.module.css';

const ApplicationTab = ({
  application,
  formAnswers,
  formSchema,
  revision,
  handleReviseUpdate,
  updatingRevision,
  showPrevious,
  translations,
}: ApplicationTabProps) => {
  const { i18n, t } = useTranslation();
  const { language } = i18n;
  const [formInstance, setFormInstance] = useState<typeof Wizard>();

  const render = {
    [ApplicationTypes.ONLINE]: (
      <>
        <Form
          key={`review-${language}`}
          form={formSchema}
          options={
            {
              readOnly: !revision,
              renderMode: 'flat',
              language: translations.length === 0 ? 'en' : language,
              i18n: convertTranlationsToObject(translations),
            } as Options
          }
          formReady={(instance: typeof Wizard) => {
            setFormInstance(instance);
          }}
          submission={generateSubmission(
            formAnswers as FormAnswer[],
            showPrevious
              ? (application?.version || 1) - 1
              : application?.version,
            revision
          )}
          onSubmit={handleReviseUpdate}
        />
        {revision && formInstance && (
          <div className={style.buttonContainer}>
            <CustomButton
              onClick={async () => {
                try {
                  await formInstance.submit();
                } catch (error) {
                  console.log('error', error);
                }
              }}
            >
              {t(
                'pages.habitat.affiliate.cycles.cycle.application.components.applicationTab.update'
              )}
            </CustomButton>
          </div>
        )}
      </>
    ),
    [ApplicationTypes.PAPER]: (
      <PaperApplicationTable application={application} />
    ),
    loading: <p>Loading</p>,
  }[updatingRevision ? 'loading' : application?.type || 'loading'];

  return <div className={style.formContainer}>{render}</div>;
};

export default ApplicationTab;
