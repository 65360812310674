import {
  Button,
  Flex,
  Text,
  useAuthenticator,
  useBreakpointValue,
  View,
} from '@aws-amplify/ui-react';
import { useEffect, useState } from 'react';
import { MdOutlineOpenInNew } from 'react-icons/md';
import TableWithPaginator from 'components/TableWithPaginator';
import Toggle from 'components/Toggle';
import { Link } from 'react-router-dom';
import { dateOnly } from 'utils/dates';
import Chip from 'components/Chip';
import { stringToHumanReadable } from 'utils/strings';
import useHabitat from 'hooks/utils/useHabitat';
import { useTranslation } from 'react-i18next';
import {
  ReviewStatus,
  RootForm,
  SubmissionStatus,
  TestApplication,
  TestCycle,
} from 'API';
import { queryRootFormsByHabitat } from 'services/graphql/RootForm';
import { queryTestCycleByRootForm } from 'services/graphql/TestCycle';
import { queryTestApplicationsByTestCycle } from 'services/graphql/TestApplication';
import Skeleton from 'components/Skeleton';
import style from './ApplicantApplicationsPage.module.css';

const ReviewStatusChip = ({ status }: { status: ReviewStatus }) =>
  status === ReviewStatus.PENDING ? (
    <Chip variation="warning" text={stringToHumanReadable(status)} />
  ) : (
    <Chip text="Reviewed" variation="active" />
  );

type DataProps =
  | {
      applications: TestApplication[];
      rootForms: RootForm[];
      cycles: TestCycle[];
    }
  | undefined;

const ApplicantApplicationsPage = () => {
  const [submissionStatusFilter, setSubmissionStatusFilter] =
    useState<SubmissionStatus>(SubmissionStatus.INCOMPLETE);
  const { t } = useTranslation();
  const { habitat } = useHabitat();
  const { user } = useAuthenticator((context) => [context.user]);
  const [data, setData] = useState<DataProps>(undefined);
  const [loading, setLoading] = useState(0);
  const titleStyle = useBreakpointValue({
    base: 'theme-subtitle-s1',
    large: 'theme-headline-medium',
  });

  useEffect(() => {
    if (habitat && user) {
      const fetchData = async () => {
        try {
          setLoading((prevLoading) => prevLoading + 1);
          const rootFormsResponse = await queryRootFormsByHabitat(habitat.id);

          const cyclesResponsesArray = await Promise.allSettled(
            rootFormsResponse.map((rootForm) =>
              queryTestCycleByRootForm({ rootformID: rootForm.id })
            )
          );

          const cycles = cyclesResponsesArray.reduce(
            (cyclesArray, currentCycleResponse) =>
              currentCycleResponse.status === 'fulfilled'
                ? cyclesArray.concat(
                    currentCycleResponse.value.map((cycle) => ({
                      ...cycle,
                      TestApplications: undefined,
                    }))
                  )
                : cyclesArray,
            [] as TestCycle[]
          );

          const applicationsResponseArray = await Promise.allSettled(
            cycles.map((cycle) =>
              queryTestApplicationsByTestCycle({
                testcycleID: cycle.id,
                filter: {
                  ownerID: { eq: user?.username },
                },
              })
            )
          );

          const applications = applicationsResponseArray.reduce(
            (applicationsArray, currentApplicationResponse) =>
              currentApplicationResponse.status === 'fulfilled'
                ? applicationsArray.concat(
                    currentApplicationResponse.value.map((application) => ({
                      ...application,
                      Decisions: undefined,
                      FormAnswers: undefined,
                      Notes: undefined,
                    }))
                  )
                : applicationsArray,
            [] as TestApplication[]
          );

          setData({
            applications,
            rootForms: rootFormsResponse.map((rootForm) => ({
              ...rootForm,
              Cycles: undefined,
            })),
            cycles,
          });
        } catch (error) {
          console.log('Error while fetching data.');
        } finally {
          setLoading((prevLoading) => prevLoading - 1);
        }
      };
      fetchData();
    }
  }, [habitat, user]);

  const loadingCells = [0, 1, 2, 3, 4, 5, 6].map((index) => ({
    id: index,
    cells: [
      {
        id: 'name',
        value: <Skeleton variation="text" numOfCharacters={15} />,
      },
      {
        value: <Skeleton variation="text" numOfCharacters={10} />,
        id: 'date',
      },
      {
        value: <Skeleton variation="text" numOfCharacters={8} />,
        id: 'status',
      },
      {
        value: <Skeleton variation="text" numOfCharacters={3} />,
        id: 'view',
      },
    ],
  }));

  return (
    <Flex padding="32px" direction="column" gap="24px">
      <Flex
        direction={{
          base: 'column',
          medium: 'row',
        }}
        justifyContent="space-between"
        alignItems="center"
      >
        <Flex direction="column" gap="0px">
          <span className={`${titleStyle} ${style.title}`}>
            {t('pages.habitat.applicant.applications.title')}
          </span>
          <Text className={`theme-subtitle-s1 ${style.subtitle}`}>
            {t('pages.habitat.applicant.applications.subtitle')}
          </Text>
        </Flex>
        <Flex className={`${style.toggleContainer}`}>
          <Toggle
            option1={{
              value: SubmissionStatus.INCOMPLETE,
              label: t(
                'pages.habitat.applicant.applications.toogle.incomplete'
              ),
            }}
            option2={{
              value: SubmissionStatus.COMPLETED,
              label: t('pages.habitat.applicant.applications.toogle.complete'),
            }}
            active={submissionStatusFilter}
            onChange={(newValue) => {
              setSubmissionStatusFilter(newValue);
            }}
          />
        </Flex>
      </Flex>
      <View className="theme-subtitle-s2">
        <Text as="span">
          {t('pages.habitat.applicant.applications.table.title')}
        </Text>
      </View>
      <TableWithPaginator
        headers={[
          {
            id: 'name',
            value: t('pages.habitat.applicant.applications.table.name'),
          },

          {
            id: 'date',
            value:
              submissionStatusFilter === SubmissionStatus.INCOMPLETE
                ? t('pages.habitat.applicant.applications.table.dateStarted')
                : t('pages.habitat.applicant.applications.table.dateCompleted'),
          },
          {
            id: 'status',
            value:
              submissionStatusFilter === SubmissionStatus.INCOMPLETE
                ? t('pages.habitat.applicant.applications.table.status')
                : t('pages.habitat.applicant.applications.table.reviewStatus'),
            textAlign: 'center',
          },
          {
            id: 'view',
            value: t('pages.habitat.applicant.applications.table.view'),
            textAlign: 'center',
          },
        ]}
        data={
          loading
            ? loadingCells
            : data?.applications
                .filter(
                  (application) =>
                    application.submissionStatus === submissionStatusFilter
                )
                .map((application, index) => ({
                  id: index,
                  cells: [
                    {
                      value:
                        data.rootForms.find((rootForm) => {
                          const foundCycle = data.cycles.find(
                            (cycle) => cycle.id === application.testcycleID
                          );
                          return (
                            foundCycle && rootForm.id === foundCycle.rootformID
                          );
                        })?.name || 'Unknown',
                      id: 'name',
                    },
                    {
                      value: dateOnly(
                        submissionStatusFilter === SubmissionStatus.INCOMPLETE
                          ? application.createdAt || ''
                          : application.submittedDate
                      ),
                      id: 'date',
                    },
                    {
                      value: (
                        <Flex width="100%" justifyContent="center">
                          {submissionStatusFilter ===
                          SubmissionStatus.INCOMPLETE ? (
                            <Chip
                              text={stringToHumanReadable(
                                application.submissionStatus
                              )}
                              variation="danger"
                            />
                          ) : (
                            <ReviewStatusChip
                              status={application.reviewStatus}
                            />
                          )}
                        </Flex>
                      ),
                      id: 'status',
                    },
                    {
                      value: (
                        <Flex width="100%" justifyContent="center">
                          <Link to={`../${application.testcycleID}`}>
                            <Button variation="link" padding="0">
                              <MdOutlineOpenInNew
                                size="24px"
                                color="var(--amplify-colors-neutral-90)"
                              />
                            </Button>
                          </Link>
                        </Flex>
                      ),
                      id: 'view',
                    },
                  ],
                })) || []
        }
      />
    </Flex>
  );
};

export default ApplicantApplicationsPage;
