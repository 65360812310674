import { cognitoUserPoolsTokenProvider } from 'aws-amplify/auth/cognito';
import { Components } from 'formiojs';
import { downloadWithUrl } from 'utils/files';
import { isElement } from 'utils/type';

const sendTokenToServiceWorker = () =>
  cognitoUserPoolsTokenProvider.getTokens().then((tokens) => {
    navigator.serviceWorker.ready.then((registration) => {
      registration.active?.postMessage({
        token: `${tokens?.idToken?.toString()}`,
      });
    });
  });

class CustomFile extends Components.components.file {
  attach(element: unknown) {
    if (isElement(element)) {
      const anchors = element.querySelectorAll('a:not(.browse)');

      for (const anchor of anchors) {
        if (anchor instanceof HTMLAnchorElement) {
          const newAElement = document.createElement('a');
          newAElement.onclick = async (event) => {
            event.preventDefault();
            await sendTokenToServiceWorker();
            const response = await fetch(anchor.href);
            if (response.ok) {
              downloadWithUrl(response.url, 'file');
            }
          };
          newAElement.href = '#';
          newAElement.innerHTML = anchor.innerHTML;
          anchor.replaceWith(newAElement);
        }
      }
    }

    return super.attach(element);
  }

  browseFiles(attrs?: object): Promise<unknown> {
    return sendTokenToServiceWorker().then(() => super.browseFiles(attrs));
  }
}

export default CustomFile;
