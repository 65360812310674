import React from 'react';
import CustomButton from 'components/CustomButton';
import Modal from 'components/Modal';
import { useTranslation } from 'react-i18next';
import styles from './ErrorModal.module.css';

interface IErrorModalProps {
  open: boolean;
  onClickClose: () => void;
}

const ErrorModal = ({ open, onClickClose }: IErrorModalProps) => {
  const { t } = useTranslation();
  return (
    <Modal
      open={open}
      onClickClose={onClickClose}
      title={t('pages.admin.forms.new.components.errorModal.title')}
      width="360px"
    >
      <p>{t('pages.admin.forms.new.components.errorModal.message')}</p>
      <div className={`${styles.buttonContainer}`}>
        <CustomButton onClick={onClickClose}>
          {t('pages.admin.forms.new.components.errorModal.close')}
        </CustomButton>
      </div>
    </Modal>
  );
};

export default ErrorModal;
