import { ReactNode, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { queryAllMaintenances } from 'services/graphql/Maintenance';

interface CheckMaintenanceProps {
  children: ReactNode;
}

const CheckMaintenance = ({ children }: CheckMaintenanceProps) => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchMaintenance = async () => {
      setLoading(true);

      const maintenances = await queryAllMaintenances();
      if (maintenances.length < 0) return;

      const shouldNavigate = maintenances[0]?.maintenance;

      if (shouldNavigate) {
        navigate('/maintenance');
      }

      setLoading(false);
    };
    fetchMaintenance();
  }, [navigate]);

  if (loading) return null;

  return children;
};

export default CheckMaintenance;
