import { CreateUserInput, ListUsersQueryVariables, UpdateUserInput } from 'API';
import { generateClient } from 'aws-amplify/api';
import CustomGraphQlError from 'classes/GraphqlError';
import { customCreateUser, customUpdateUser } from 'myGraphql/myMutations';
import { customGetUser, customListUsers } from 'myGraphql/myQueries';

/**
 * Query all the Users that have the _deleted property not equal to true.
 * @param variables The variables passed to the query.
 * @returns An array of Users.
 */
export const queryAllUsers = async (
  variables: ListUsersQueryVariables | undefined = undefined
) => {
  const client = generateClient();

  const itemsToReturn = [];
  let currentNextToken: string | null | undefined;

  do {
    const response = await client.graphql({
      query: customListUsers,
      variables: {
        filter: {
          ...(variables ? variables.filter : {}),
        },
        nextToken: currentNextToken,
      },
    });

    if (response.errors) {
      throw new CustomGraphQlError(
        'Error while querying users.',
        response.errors
      );
    }

    const { items, nextToken } = response.data.listUsers;

    currentNextToken = nextToken;

    itemsToReturn.push(...items);
  } while (currentNextToken);

  return itemsToReturn;
};

/**
 * Query an User.
 * @param id The User id.
 * @returns The User found or null or undefined.
 */
export const queryUser = async (id: string) => {
  const client = generateClient();

  const response = await client.graphql({
    query: customGetUser,
    variables: {
      id,
    },
  });

  if (response.errors) {
    throw new CustomGraphQlError('Error while querying user.', response.errors);
  }

  return response.data.getUser;
};

/**
 * Persists a new User.
 * @param user The User to persist.
 * @returns The persisted User.
 */
export const newUser = async (user: CreateUserInput) => {
  const client = generateClient();

  const response = await client.graphql({
    query: customCreateUser,
    variables: {
      input: user,
    },
  });

  if (response.errors) {
    throw new CustomGraphQlError(
      'Error while creating new user.',
      response.errors
    );
  }

  return response.data.createUser;
};

/**
 * Updates a User.
 * @param input The User with changes.
 * @returns The updated User.
 */
export const updateUser = async (input: UpdateUserInput) => {
  const client = generateClient();

  const response = await client.graphql({
    query: customUpdateUser,
    variables: {
      input,
    },
  });

  if (response.errors) {
    throw new CustomGraphQlError(
      'Error while updating testCycle.',
      response.errors
    );
  }

  return response.data.updateUser;
};
