import React from 'react';
import { TextField } from '@aws-amplify/ui-react';
import { MdOutlineAdd, MdOutlineClose } from 'react-icons/md';
import CustomButton from 'components/CustomButton';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import styles from './JsonField.module.css';

export type TJson = {
  [key: string]: string;
};

interface IJsonFieldProps {
  json: TJson;
  onChange: (newJson: TJson) => void;
  disableDelete?: boolean;
  disableAdd?: boolean;
  disableUpdateKey?: boolean;
}

const JsonField = ({
  json,
  onChange,
  disableAdd,
  disableDelete,
  disableUpdateKey,
}: IJsonFieldProps) => {
  const { t } = useTranslation();
  const handleKeyChange = (value: string, key: string) => {
    if (disableUpdateKey) {
      return;
    }

    const newKey = value;

    const keys = Object.keys(json);

    if (keys.includes(newKey)) {
      return;
    }

    const newJson = keys.reduce((acc: TJson, val) => {
      if (val === key) {
        acc[newKey] = json[key];
      } else {
        acc[val] = json[val];
      }
      return acc;
    }, {});

    onChange(newJson);
  };

  const handleValueChange = (value: string, key: string) => {
    const newJson = { ...json };
    newJson[key] = value;
    onChange(newJson);
  };

  const handleRemoveKeyValue = (key: string) => {
    const newJson = { ...json };

    delete newJson[key];

    onChange(newJson);
  };

  const handleAddKeyValue = () => {
    const latestNewKey = Object.keys(json)
      .filter((key) => key.startsWith('new-key-'))
      .map((key) => Number(key.replace('new-key-', '')))
      .sort();

    const newKeyNumber = (latestNewKey.at(-1) || 0) + 1;

    const newJson = {
      ...json,
      ...{ [`new-key-${newKeyNumber}`]: '' },
    };

    onChange(newJson);
  };

  return (
    <div className={`${styles.container}`}>
      {isEmpty(json) ||
        Object.entries(json).map(([key, value], index) => (
          <div className={`${styles.row}`} key={index}>
            <TextField
              label="key"
              labelHidden
              value={key}
              className={`${styles.input}`}
              readOnly={disableAdd}
              onChange={(event) => {
                const { value } = event.currentTarget;
                handleKeyChange(value, key);
              }}
            />
            <TextField
              label="value"
              labelHidden
              value={value}
              className={`${styles.input}`}
              onChange={(event) => {
                const newValue = event.currentTarget.value;
                handleValueChange(newValue, key);
              }}
            />
            {!disableDelete && (
              <CustomButton onClick={() => handleRemoveKeyValue(key)}>
                <MdOutlineClose />
              </CustomButton>
            )}
          </div>
        ))}
      {!disableAdd && (
        <div className={`${styles.buttonContainer}`}>
          <CustomButton onClick={handleAddKeyValue} icon={<MdOutlineAdd />}>
            {t(
              'pages.admin.forms.edit.components.translationsTab.components.jsonField.add'
            )}
          </CustomButton>
        </div>
      )}
    </div>
  );
};

export default JsonField;
