import React, { Suspense, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Await, useLoaderData } from 'react-router-dom';
import CustomButton from 'components/CustomButton';
import { MdOutlineAdd } from 'react-icons/md';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { get } from 'aws-amplify/api';
import { uploadData } from 'aws-amplify/storage';
import useHabitat from 'hooks/utils/useHabitat';
import { getEditorStateWithFilesInBucket } from 'utils/lexicalEditor';
import { v4 } from 'uuid';
import { TReportsData } from 'router/loaders/reports';
import Skeleton from 'components/Skeleton';
import { newReport } from 'services/graphql/Report';
import { TReportSchema } from './components/ReportModal/ReportModal.schema';
import styles from './AffiliateReportsPage.module.css';
import ReportModal from './components/ReportModal';
import ReportCard from './components/ReportCard';

const ADMINS_EMAILS = process.env.REACT_APP_ADMINS || '';

const AffiliateReportsPage = () => {
  const { habitat } = useHabitat();

  const { t } = useTranslation();

  const { user } = useAuthenticator((context) => [context.user]);

  const [email, setEmail] = useState<string>();

  const [loading, setLoading] = useState(0);

  const [reportModal, setReportModal] = useState(false);

  const { reportsData } = useLoaderData() as {
    reportsData: Promise<TReportsData>;
  };

  const uploadReportFiles = async (file: File) => {
    const result = await uploadData({
      path: `public/reports/${habitat?.urlName}/${v4()}_${file.name}`,
      data: file,
    }).result;

    return result;
  };

  const handleOnValidReport = async (data: TReportSchema) => {
    setLoading((previousLoading) => previousLoading + 1);
    try {
      const editorStateWithFilesInS3 = await getEditorStateWithFilesInBucket(
        data.content,
        uploadReportFiles
      );

      await newReport({
        habitatID: habitat?.id || '',
        status: data.status,
        content: JSON.stringify(editorStateWithFilesInS3),
        title: data.title,
        testCycleId: data.testCycleId,
      });

      setReportModal(false);
    } catch (error) {
      console.log('An error ocurred while creating the report');
    }
    setLoading((previousLoading) => previousLoading - 1);
  };

  useEffect(() => {
    const getEmail = async () => {
      const attributesResponse = user.username
        ? await get({
            apiName: 'userAPI',
            path: '/',
            options: {
              queryParams: {
                sub: user.username,
              },
            },
          }).response
        : undefined;

      if (attributesResponse?.statusCode !== 200) {
        return;
      }

      const { attributes } =
        (await attributesResponse.body.json()) as unknown as {
          attributes: Record<string, string>[];
        };

      if (!attributes) {
        return;
      }

      setEmail(
        attributes.find((attr: Record<string, string>) => attr.Name === 'email')
          ?.Value
      );
    };

    getEmail();
  }, [user.username]);

  return (
    <div className={styles.page}>
      <div className={`${styles.cta}`}>
        <h3 className="theme-headline-medium">
          {t('pages.habitat.affiliate.reports.title')}
        </h3>
        <div className={`theme-body-medium ${styles.subtitle}`}>
          <span>{t('pages.habitat.affiliate.reports.subtitle')}</span>
        </div>
      </div>
      {ADMINS_EMAILS.split(' ').includes(email || '') && (
        <div className={styles.buttonsContainer}>
          <Suspense>
            <Await resolve={reportsData}>
              {({ rootForms, cycles }: TReportsData) => (
                <>
                  <ReportModal
                    rootForms={rootForms.map((rootForm) => ({
                      ...rootForm,
                      Cycles: undefined,
                    }))}
                    cycles={cycles.map((cycle) => ({
                      ...cycle,
                      TestApplications: undefined,
                    }))}
                    open={reportModal}
                    onClose={() => setReportModal(false)}
                    onValid={handleOnValidReport}
                    loading={loading}
                  />
                  <CustomButton
                    icon={<MdOutlineAdd />}
                    onClick={() => setReportModal(true)}
                  >
                    {t('pages.habitat.affiliate.reports.add')}
                  </CustomButton>
                </>
              )}
            </Await>
          </Suspense>
        </div>
      )}
      <Suspense
        fallback={
          <div className={styles.reportsContainer}>
            {[1, 2, 3, 4, 5].map((number) => (
              <Skeleton key={number} className={styles.reportCardSkeleton} />
            ))}
          </div>
        }
      >
        <Await resolve={reportsData}>
          {({ reports, rootForms, cycles }: TReportsData) =>
            reports.length ? (
              <div className={styles.reportsContainer}>
                {reports.map((report) => (
                  <ReportCard
                    key={report.id}
                    rootFormName={
                      rootForms.find(
                        (rf) =>
                          rf.id ===
                          cycles.find((c) => c.id === report.testCycleId)
                            ?.rootformID
                      )?.name || ''
                    }
                    cycleName={
                      cycles.find((c) => c.id === report.testCycleId)?.name ||
                      ''
                    }
                    title={report.title}
                    status={report.status}
                    editorState={report.content}
                  />
                ))}
              </div>
            ) : (
              <div
                className={`theme-body-medium ${styles.subtitle} ${styles.noReports}`}
              >
                <p style={{ textAlign: 'center' }}>
                  {t('pages.habitat.affiliate.reports.noReports')}
                  <a href="mailto:support@habitat-app.org">
                    support@habitat-app.org
                  </a>
                </p>
              </div>
            )
          }
        </Await>
      </Suspense>
    </div>
  );
};

export default AffiliateReportsPage;
