import { Form, Wizard } from '@formio/react';
import { Options } from '@formio/react/lib/components/Form';
import LoadingOverlay from 'components/LoadingOverlay';
import { Suspense } from 'react';
import { Await, useLoaderData, useSearchParams } from 'react-router-dom';
import { TPrintData } from 'router/loaders/print';
import { convertTranlationsToObject } from 'utils/translations';

declare global {
  interface Window {
    hfhSetSubmission: (submission: unknown) => void;
    hfhIsSubmissionSet: () => boolean;
  }
}

const Print = () => {
  const { printData } = useLoaderData() as { printData: TPrintData };
  const [searchParams] = useSearchParams();

  const language = searchParams.get('language') || 'en';

  return (
    <Suspense fallback={<LoadingOverlay />}>
      <Await resolve={printData}>
        {(printData: TPrintData) => {
          if (!printData) {
            return null;
          }

          const { formSchema, translations } = printData;

          return (
            <Form
              form={formSchema}
              options={
                {
                  readOnly: true,
                  renderMode: 'flat',
                  language: translations.length === 0 ? 'en' : language,
                  i18n: convertTranlationsToObject(translations),
                } as Options
              }
              formReady={(form: typeof Wizard) => {
                window.hfhSetSubmission = (submission: unknown) => {
                  form.submission = submission;
                };
                window.hfhIsSubmissionSet = () => form.submissionSet;
              }}
            />
          );
        }}
      </Await>
    </Suspense>
  );
};

export default Print;
