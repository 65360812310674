import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { MdOutlineMenu } from 'react-icons/md';
import { Button, Flex, useAuthenticator } from '@aws-amplify/ui-react';
import getRoutes, { getTitle } from 'utils/routes';
import useAsync from 'hooks/utils/useAsync';
import { queryAllUsers } from 'services/graphql/User';
import { useCallback } from 'react';
import NotificationButton from './components/NotificationButton';
import ProfileBadge from './components/ProfileBadge';
import Translate from './components/Translate';
import style from './TopBar.module.css';
import TopBarProps from './TopBarProps.types';

const TopBar = ({ mobile, onExpand }: TopBarProps) => {
  const { t } = useTranslation();

  const location = useLocation();

  const routes = getRoutes(t);

  const title = getTitle(location.pathname, routes);

  const { user } = useAuthenticator((context) => [
    context.authStatus,
    context.user,
  ]);

  const asyncFunction = useCallback(async () => {
    const users = await queryAllUsers({
      filter: { owner: { eq: user?.username } },
    });

    return users;
  }, [user]);

  const { value: userData } = useAsync({
    asyncFunction,
  });

  let initials = '';

  if (userData && userData.length > 0) {
    const tempUser = userData[0];
    const firstNameInit = tempUser?.firstName.charAt(0);
    const lastNameInit = tempUser?.lastName.charAt(0);
    initials = firstNameInit + lastNameInit;
  }

  return (
    <div className={`${style.topBar}`}>
      <Flex alignItems="center">
        {mobile && (
          <Button variation="menu" padding="12px" onClick={onExpand}>
            <MdOutlineMenu size="24px" />
          </Button>
        )}
        <Flex className={`${style.title}`}>{title}</Flex>
      </Flex>
      <Flex gap="12px">
        <NotificationButton />
        <Translate />
        <ProfileBadge initials={initials} />
      </Flex>
    </div>
  );
};

export default TopBar;
