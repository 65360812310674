import { CreateReportInput } from 'API';
import { generateClient } from 'aws-amplify/api';
import CustomGraphQlError from 'classes/GraphqlError';
import { customCreateReport } from 'myGraphql/myMutations';
import { customReportsByHabitatID } from 'myGraphql/myQueries';

/**
 * Query all the Reports of a Habitat with the _deleted property not equal to true.
 * @param habitatId The Habitat id.
 * @returns An array of Reports.
 */
export const queryReportsByHabitat = async (habitatId: string) => {
  const client = generateClient();

  let nextTokenFlag: string | null | undefined;

  const itemsToReturn = [];

  do {
    const response = await client.graphql({
      query: customReportsByHabitatID,
      variables: {
        habitatID: habitatId,
        nextToken: nextTokenFlag,
      },
    });

    if (response.errors) {
      throw new CustomGraphQlError(
        'Error while querying reports by habitat.',
        response.errors
      );
    }

    const { nextToken, items } = response.data.reportsByHabitatID;

    nextTokenFlag = nextToken;

    itemsToReturn.push(...items);
  } while (nextTokenFlag);

  return itemsToReturn;
};

/**
 * Persists a new Report.
 * @param report The Report to persist.
 * @returns The persisted Report.
 */
export const newReport = async (report: CreateReportInput) => {
  const client = generateClient();

  const response = await client.graphql({
    query: customCreateReport,
    variables: {
      input: report,
    },
  });

  if (response.errors) {
    throw new CustomGraphQlError(
      'Error while creating new report.',
      response.errors
    );
  }

  return response.data.createReport;
};
