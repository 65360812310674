import React, { Suspense, useState } from 'react';
import { Await, useLoaderData } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  MdOutlineLibraryAddCheck,
  MdOutlineNoteAlt,
  MdPersonOutline,
} from 'react-icons/md';
import ApplicantTab from 'components/ApplicantTab';
import DecisionsTab from 'components/DecisionsTab';
import { TUserData } from 'router/loaders/user';
import LocalNavigation from 'components/LocalNavigation';
import BreadCrumbs from 'components/BreadCrumbs/BreadCrumbs';
import GoBack from 'components/GoBack';
import { useBreakpointValue } from '@aws-amplify/ui-react';
import style from './AffiliateUserDetails.module.css';
import ApplicationsTab from './components/ApplicationsTab';

const AffiliateUserDetails = () => {
  const [activeTab, setActiveTab] = useState(0);

  const isSmall = useBreakpointValue({
    base: true,
    medium: false,
  });

  const titleStyle = useBreakpointValue({
    base: 'theme-subtitle-s1',
    large: 'theme-headline-medium',
  });

  const { t } = useTranslation();

  const { userData } = useLoaderData() as {
    userData: Promise<TUserData>;
  };

  const breadCrumbsItems = [
    {
      label: t(
        'pages.habitat.affiliate.users.userDetails.breadcrumbs.usersDashboard'
      ),
      to: '..',
    },
    {
      label: t('pages.habitat.affiliate.users.userDetails.breadcrumbs.details'),
    },
  ];

  return (
    <div className={style.page}>
      <div>
        {!isSmall && <BreadCrumbs items={breadCrumbsItems} />}
        <div className={`${style.titleContainer}`}>
          <GoBack />
          <span className={`${titleStyle} ${style.title}`}>
            {t('pages.habitat.affiliate.users.userDetails.title')}
          </span>
        </div>
      </div>
      <div className={`${style.detailsContainer}`}>
        <LocalNavigation
          items={[
            {
              label: t('pages.habitat.affiliate.users.userDetails.tabs.user'),
              icon: <MdPersonOutline />,
            },
            {
              label: t(
                'pages.habitat.affiliate.users.userDetails.tabs.applications'
              ),
              icon: <MdOutlineNoteAlt />,
            },

            {
              label: t(
                'pages.habitat.affiliate.users.userDetails.tabs.decisions'
              ),
              icon: <MdOutlineLibraryAddCheck />,
            },
          ]}
          current={activeTab}
          onChange={(newCurrent) => {
            setActiveTab(newCurrent);
          }}
        />
        <div className={style.tabContainer}>
          {activeTab === 0 && (
            <Suspense fallback={<ApplicantTab skeleton />}>
              <Await resolve={userData}>
                {(response: TUserData) => {
                  const { user, attributes } = response;

                  return <ApplicantTab user={user} attributes={attributes} />;
                }}
              </Await>
            </Suspense>
          )}
          {activeTab === 1 && (
            <Suspense
              fallback={
                <ApplicationsTab
                  applications={[]}
                  cycles={[]}
                  rootForms={[]}
                  skeleton
                />
              }
            >
              <Await resolve={userData} errorElement={<h1>Error</h1>}>
                {({ applications, cycles, rootForms }: TUserData) => (
                  <ApplicationsTab
                    applications={applications}
                    cycles={cycles}
                    rootForms={rootForms}
                  />
                )}
              </Await>
            </Suspense>
          )}
          {activeTab === 2 && (
            <Suspense fallback={<DecisionsTab decisions={[]} skeleton />}>
              <Await resolve={userData} errorElement={<h1>Error</h1>}>
                {({ decisions }: TUserData) => (
                  <DecisionsTab decisions={decisions} />
                )}
              </Await>
            </Suspense>
          )}
        </div>
      </div>
    </div>
  );
};

export default AffiliateUserDetails;
