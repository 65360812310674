import React from 'react';

import CustomButton from 'components/CustomButton';
import { generateTranslatiosOfForm } from 'utils/translations';
import { Tabs } from '@aws-amplify/ui-react';
import { useTranslation } from 'react-i18next';
import { FormTranslation, Language } from 'API';
import styles from './TranslationsTab.module.css';
import Tab from './components/Tab';

interface ITranslationsTabProps {
  formSchemaId: string;
  formTranslations: FormTranslation[];
  setLoading: (loading: boolean) => void;
  revalidate: () => void;
}

const TranslationsTab = ({
  formSchemaId,
  formTranslations,
  setLoading,
  revalidate,
}: ITranslationsTabProps) => {
  const { t } = useTranslation();

  const handleGenerateTranslations = async () => {
    try {
      setLoading(true);
      await generateTranslatiosOfForm(formSchemaId);
      revalidate();
    } catch (error) {
      console.log('Error generating translations.', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <div className={`${styles.buttonsContainer}`}>
        <CustomButton onClick={handleGenerateTranslations}>
          {t(
            'pages.admin.forms.edit.components.translationsTab.generateTranslations'
          )}
        </CustomButton>
      </div>
      {formTranslations.length > 0 ? (
        <Tabs
          defaultValue={Language.EN}
          items={formTranslations.map((translation) => ({
            value: translation.lang,
            label: translation.lang,
            content: (
              <Tab
                formTranslation={translation}
                setLoading={setLoading}
                revalidate={revalidate}
              />
            ),
          }))}
          isLazy
        />
      ) : (
        <span>
          {t(
            'pages.admin.forms.edit.components.translationsTab.noTranslations'
          )}
        </span>
      )}
    </div>
  );
};

export default TranslationsTab;
