import { Flex, View, ViewProps } from '@aws-amplify/ui-react';
import { forwardRef, LegacyRef } from 'react';
import styles from './CustomCard.module.css';

// eslint-disable-next-line react/display-name
const CustomCard = forwardRef(
  (
    { children, className, ...otherProps }: ViewProps,
    ref: LegacyRef<HTMLDivElement> | undefined
  ) => (
    <View className={`${styles.card} ${className}`} {...otherProps} ref={ref}>
      <Flex direction="column" width="100%" height="100%">
        {children}
      </Flex>
    </View>
  )
);

export default CustomCard;
