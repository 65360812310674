import {
  CreateTestApplicationInput,
  ListTestApplicationsQueryVariables,
  TestApplicationsByTestcycleIDQueryVariables,
  UpdateTestApplicationInput,
} from 'API';
import { generateClient } from 'aws-amplify/api';
import CustomGraphQlError from 'classes/GraphqlError';
import {
  customCreateTestApplication,
  customDeleteTestApplication,
  customUpdateTestApplication,
} from 'myGraphql/myMutations';
import {
  customGetTestApplication,
  customListTestApplications,
  customTestApplicationsByTestcycleID,
} from 'myGraphql/myQueries';

/**
 * Query all the TestApplications of a TestCycle that have the _deleted property not equal to true.
 * @param variables The variables passed to the query.
 * @returns An array of TestApplications.
 */
export const queryTestApplicationsByTestCycle = async (
  variables: TestApplicationsByTestcycleIDQueryVariables
) => {
  const client = generateClient();

  let nextTokenFlag: string | null | undefined;

  const itemsToReturn = [];

  do {
    const response = await client.graphql({
      query: customTestApplicationsByTestcycleID,
      variables: {
        ...variables,
        nextToken: nextTokenFlag,
      },
    });

    if (response.errors) {
      throw new CustomGraphQlError(
        'Error while querying testApplications by testCycle.',
        response.errors
      );
    }

    const { nextToken, items } = response.data.testApplicationsByTestcycleID;

    nextTokenFlag = nextToken;

    itemsToReturn.push(...items);
  } while (nextTokenFlag);

  return itemsToReturn;
};

/**
 * Query all the TestApplications that have the _deleted property not equal to true.
 * @param variables The variables passed to the query.
 * @returns An array of TestApplications.
 */
export const queryAllTestApplications = async (
  variables: ListTestApplicationsQueryVariables | undefined = undefined
) => {
  const client = generateClient();

  const itemsToReturn = [];
  let currentNextToken: string | null | undefined;

  do {
    const response = await client.graphql({
      query: customListTestApplications,
      variables: {
        filter: {
          ...(variables ? variables.filter : {}),
        },
        nextToken: currentNextToken,
      },
    });

    if (response.errors) {
      throw new CustomGraphQlError(
        'Error while querying testApplications.',
        response.errors
      );
    }

    const { items, nextToken } = response.data.listTestApplications;

    currentNextToken = nextToken;

    itemsToReturn.push(...items);
  } while (currentNextToken);

  return itemsToReturn;
};

/**
 * Query a TestApplication.
 * @param id The TestApplication id.
 * @returns The TestApplication found or null or undefined.
 */
export const queryTestApplication = async (id: string) => {
  const client = generateClient();

  const response = await client.graphql({
    query: customGetTestApplication,
    variables: {
      id,
    },
  });

  if (response.errors) {
    throw new CustomGraphQlError(
      'Error while querying testApplication.',
      response.errors
    );
  }

  return response.data.getTestApplication;
};

/**
 * Persists a new TestApplication.
 * @param testApplication The TestApplication to persist.
 * @returns The persisted TestApplication.
 */
export const newTestApplication = async (
  testApplication: CreateTestApplicationInput
) => {
  const client = generateClient();

  const response = await client.graphql({
    query: customCreateTestApplication,
    variables: {
      input: testApplication,
    },
  });

  if (response.errors) {
    throw new CustomGraphQlError(
      'Error while creating new testApplication.',
      response.errors
    );
  }

  return response.data.createTestApplication;
};

/**
 * Updates a TestApplication.
 * @param input The TestApplication with changes.
 * @returns The updated TestApplication.
 */
export const updateTestApplication = async (
  input: UpdateTestApplicationInput
) => {
  const client = generateClient();

  const response = await client.graphql({
    query: customUpdateTestApplication,
    variables: {
      input,
    },
  });

  if (response.errors) {
    throw new CustomGraphQlError(
      'Error while updating testApplication.',
      response.errors
    );
  }

  return response.data.updateTestApplication;
};

/**
 * Deletes a TestApplication.
 * @param id The TestApplication id.
 * @returns The deleted TestApplication.
 */
export const deleteTestApplication = async (id: string) => {
  const client = generateClient();

  const response = await client.graphql({
    query: customDeleteTestApplication,
    variables: {
      input: {
        id,
      },
    },
  });

  if (response.errors) {
    throw new CustomGraphQlError(
      'Error while deleting testApplication.',
      response.errors
    );
  }

  return response.data.deleteTestApplication;
};
