import { TestApplication, TestCycle, User } from 'API';
import { get } from 'aws-amplify/api';
import { defer, LoaderFunction } from 'react-router-dom';
import { queryAllHabitats } from 'services/graphql/Habitat';
import { queryRootFormsByHabitat } from 'services/graphql/RootForm';
import { queryTestApplicationsByTestCycle } from 'services/graphql/TestApplication';
import { queryTestCycleByRootForm } from 'services/graphql/TestCycle';
import { queryAllUsers } from 'services/graphql/User';
import { untilAmplifyFinishConfiguration } from 'utils/amplify';

export interface IUsersOfApplications extends User {
  attributes: {
    email: string;
    phone: string;
  };
}

const getUsers = async (habitat: string | undefined) => {
  await untilAmplifyFinishConfiguration();

  const habitats = await queryAllHabitats({
    filter: { urlName: { eq: habitat } },
  });

  if (habitats.length === 0) {
    throw new Response('Habitat not Found', { status: 404 });
  }

  const rootForms = await queryRootFormsByHabitat(habitats[0].id);

  const cyclesResponseArray = await Promise.allSettled(
    rootForms.map((rootForm) =>
      queryTestCycleByRootForm({ rootformID: rootForm.id })
    )
  );

  const cycles: TestCycle[] = cyclesResponseArray.reduce(
    (cyclesArray, cyclesResponseItem) => {
      if (cyclesResponseItem.status === 'fulfilled') {
        return cyclesArray.concat(
          cyclesResponseItem.value.map((cycle) => ({
            ...cycle,
            TestApplications: undefined,
          }))
        );
      }
      return cyclesArray;
    },
    [] as TestCycle[]
  );

  const allApplicationsResult = await Promise.allSettled(
    cycles.map((cycle) =>
      queryTestApplicationsByTestCycle({ testcycleID: cycle.id })
    )
  );

  const allApplications = allApplicationsResult
    .reduce((applicationsArray, currentApplications) => {
      if (currentApplications.status === 'fulfilled') {
        return applicationsArray.concat(
          currentApplications.value.map((application) => ({
            ...application,
            Decisions: undefined,
            FormAnswers: undefined,
            Notes: undefined,
          }))
        );
      }

      return applicationsArray;
    }, [] as TestApplication[])
    .sort(
      (a, b) =>
        new Date(b.submittedDate).getTime() -
        new Date(a.submittedDate).getTime()
    );

  const ownerIds = allApplications.reduce(
    (ownerIdsArray, currentApplication) => {
      if (
        currentApplication.ownerID &&
        !ownerIdsArray.includes(currentApplication.ownerID)
      ) {
        return [...ownerIdsArray, currentApplication.ownerID];
      }
      return ownerIdsArray;
    },
    [] as string[]
  );

  const usersResponseArray = await Promise.allSettled(
    ownerIds.map((ownerId) =>
      queryAllUsers({ filter: { owner: { eq: ownerId } } })
    )
  );

  const usersOfApplicationsWithoutAttributes = usersResponseArray.reduce(
    (usersArray, currentResponse) =>
      currentResponse.status === 'fulfilled'
        ? usersArray.concat(currentResponse.value)
        : usersArray,
    [] as User[]
  );

  const users: IUsersOfApplications[] = [];

  const promisesArray = usersOfApplicationsWithoutAttributes.map((user) =>
    (async () => {
      try {
        const attributesResponse = user.owner
          ? await get({
              apiName: 'userAPI',
              path: '/',
              options: {
                queryParams: {
                  sub: user.owner,
                },
              },
            }).response
          : undefined;

        if (attributesResponse?.statusCode === 200) {
          const body = (await attributesResponse.body.json()) as {
            attributes: Record<string, string>[];
          };

          const { attributes } = body;

          users.push({
            ...user,
            attributes: {
              email:
                attributes.find((attr) => attr.Name === 'email')?.Value || '',
              phone:
                attributes.find((attr) => attr.Name === 'phone_number')
                  ?.Value || '',
            },
          });
        }
      } catch (error) {
        console.error('User does not exist.');
      }
    })()
  );

  await Promise.allSettled(promisesArray);

  return users;
};

const usersLoader: LoaderFunction = async ({ params, request }) => {
  if (!request.url.endsWith('users')) {
    return;
  }

  const users = getUsers(params.habitat);

  return defer({
    users,
  });
};

export default usersLoader;
