import {
  CreateFormAnswerInput,
  FormAnswersByTestapplicationIDQueryVariables,
  ListFormAnswersQueryVariables,
  UpdateFormAnswerInput,
} from 'API';
import { generateClient } from 'aws-amplify/api';
import CustomGraphQlError from 'classes/GraphqlError';
import {
  customCreateFormAnswer,
  customDeleteFormAnswer,
  customUpdateFormAnswer,
} from 'myGraphql/myMutations';
import {
  customFormAnswersByTestapplicationID,
  customListFormAnswers,
} from 'myGraphql/myQueries';

/**
 * Query all the FormAnswers of a TestApplication that have the _deleted property not equal to true.
 * @param variables The variables to pass to the query.
 * @returns An array of FormAnswers.
 */
export const queryFormAnswersByTestApplication = async (
  variables: FormAnswersByTestapplicationIDQueryVariables
) => {
  const client = generateClient();

  let currentNextToken: string | null | undefined;

  const itemsToReturn = [];

  do {
    const response = await client.graphql({
      query: customFormAnswersByTestapplicationID,
      variables: {
        ...variables,
        filter: {
          ...variables.filter,
        },
        testapplicationID: variables.testapplicationID,
        nextToken: currentNextToken,
      },
    });

    if (response.errors) {
      throw new CustomGraphQlError(
        'Error while querying formAnswers by formSchema.',
        response.errors
      );
    }

    const { nextToken, items } = response.data.formAnswersByTestapplicationID;

    currentNextToken = nextToken;

    itemsToReturn.push(...items);
  } while (currentNextToken);

  return itemsToReturn;
};

/**
 * Persists a new FormAnswer.
 * @param formAnswer The FormAnswer to persist.
 * @returns The persisted FormAnswer.
 */
export const newFormAnswer = async (formAnswer: CreateFormAnswerInput) => {
  const client = generateClient();

  const response = await client.graphql({
    query: customCreateFormAnswer,
    variables: {
      input: formAnswer,
    },
  });

  if (response.errors) {
    throw new CustomGraphQlError(
      'Error while creating new formAnswer.',
      response.errors
    );
  }

  return response.data.createFormAnswer;
};

/**
 * Updates a FormAnswer.
 * @param input The FormAnswer with changes.
 * @returns The updated FormAnswer.
 */
export const updateFormAnswer = async (input: UpdateFormAnswerInput) => {
  const client = generateClient();

  const response = await client.graphql({
    query: customUpdateFormAnswer,
    variables: {
      input,
    },
  });

  if (response.errors) {
    throw new CustomGraphQlError(
      'Error while updating formAnswer.',
      response.errors
    );
  }

  return response.data.updateFormAnswer;
};

/**
 * Deletes a FormAnswer.
 * @param id The FormAnswer id.
 * @returns The deleted FormAnswer.
 */
export const deleteFormAnswer = async (id: string) => {
  const client = generateClient();

  const response = await client.graphql({
    query: customDeleteFormAnswer,
    variables: {
      input: {
        id,
      },
    },
  });

  if (response.errors) {
    throw new CustomGraphQlError(
      'Error while deleting formAnswer.',
      response.errors
    );
  }

  return response.data.deleteFormAnswer;
};

/**
 * Query all the FormAnswers.
 * @param variables The variables to pass to the query.
 * @returns An array of FormAnswers.
 */
export const queryAllFormAnswers = async (
  variables: ListFormAnswersQueryVariables | undefined = undefined
) => {
  const client = generateClient();

  let currentNextToken: string | null | undefined;

  const itemsToReturn = [];

  do {
    const response = await client.graphql({
      query: customListFormAnswers,
      variables: {
        ...variables,
        filter: {
          ...variables?.filter,
        },
        nextToken: currentNextToken,
      },
    });

    if (response.errors) {
      throw new CustomGraphQlError(
        'Error while querying all formAnswers.',
        response.errors
      );
    }

    const { nextToken, items } = response.data.listFormAnswers;

    currentNextToken = nextToken;

    itemsToReturn.push(...items);
  } while (currentNextToken);

  return itemsToReturn;
};
