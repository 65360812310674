const input = {
  color: { value: '{colors.neutral.100}' },
  borderColor: { value: '{colors.neutral.80}' },
  fontSize: { value: '{fontSizes.small}' },
  _focus: {
    borderColor: { value: '{colors.neutral.80}' },
  },
};

export default input;
