import { useTranslation } from 'react-i18next';
import { Flex, Text } from '@aws-amplify/ui-react';
import DecisionCard from 'components/DecisionCard';
import useHabitat from 'hooks/utils/useHabitat';
import { ReviewStatus, TestApplication } from 'API';
import { useCallback } from 'react';
import { queryDecisionsByTestApplication } from 'services/graphql/Decision';
import useAsync from 'hooks/utils/useAsync';
import style from './Decisions.module.css';

interface DecisionsProps {
  application: TestApplication;
}

const Decisions = ({ application }: DecisionsProps) => {
  const { habitat } = useHabitat();
  const { t } = useTranslation();

  const fetchDecisions = useCallback(async () => {
    const decisions = await queryDecisionsByTestApplication({
      testapplicationID: application.id,
    });

    return decisions.sort(
      (a, b) =>
        new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
    );
  }, [application]);

  const { value: decisions } = useAsync({
    asyncFunction: fetchDecisions,
  });

  return (
    <Flex className={`${style.decisionsContainer}`}>
      {decisions &&
        (decisions.length > 0 ? (
          decisions.map((data) => (
            <DecisionCard
              key={data.id}
              date={data.createdAt || ''}
              habitat={habitat?.longName || ''}
              status={ReviewStatus[data?.status || 'PENDING']}
              editorState={data.serializedEditorState}
            />
          ))
        ) : (
          <Text textAlign="center" fontWeight="bold">
            {t(
              'pages.habitat.applicant.cycle.components.tabs.decisions.noDecisions'
            )}
          </Text>
        ))}
    </Flex>
  );
};

export default Decisions;
