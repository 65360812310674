import {
  ReviewStatus,
  SubmissionStatus,
  TestApplication,
  TestCycle,
} from 'API';
import dayjs from 'dayjs';
import { defer, LoaderFunction } from 'react-router-dom';
import { queryAllHabitats } from 'services/graphql/Habitat';
import { queryRootFormsByHabitat } from 'services/graphql/RootForm';
import { queryTestApplicationsByTestCycle } from 'services/graphql/TestApplication';
import { queryTestCycleByRootForm } from 'services/graphql/TestCycle';
import { queryAllUsers } from 'services/graphql/User';
import { untilAmplifyFinishConfiguration } from 'utils/amplify';

const getHomeData = async (habitat: string | undefined) => {
  await untilAmplifyFinishConfiguration();

  const habitats = await queryAllHabitats({
    filter: { urlName: { eq: habitat } },
  });

  if (habitats.length === 0) {
    throw new Response('Habitat not Found', { status: 404 });
  }

  const rootForms = await queryRootFormsByHabitat(habitats[0].id);

  const cyclesResponseArray = await Promise.allSettled(
    rootForms.map((rootForm) =>
      queryTestCycleByRootForm({ rootformID: rootForm.id })
    )
  );

  const cycles: TestCycle[] = cyclesResponseArray.reduce(
    (cyclesArray, cyclesResponseItem) => {
      if (cyclesResponseItem.status === 'fulfilled') {
        return cyclesArray.concat(
          cyclesResponseItem.value.map((cycle) => ({
            ...cycle,
            TestApplications: undefined,
          }))
        );
      }
      return cyclesArray;
    },
    [] as TestCycle[]
  );

  const allApplicationsResult = await Promise.allSettled(
    cycles.map((cycle) =>
      queryTestApplicationsByTestCycle({ testcycleID: cycle.id })
    )
  );

  const allApplications = allApplicationsResult
    .reduce((applicationsArray, currentApplications) => {
      if (currentApplications.status === 'fulfilled') {
        return applicationsArray.concat(
          currentApplications.value.map((application) => ({
            ...application,
            Decisions: undefined,
            FormAnswers: undefined,
            Notes: undefined,
          }))
        );
      }

      return applicationsArray;
    }, [] as TestApplication[])
    .sort(
      (a, b) =>
        new Date(b.submittedDate).getTime() -
        new Date(a.submittedDate).getTime()
    );

  const latestPendingApplications = allApplications
    .filter(
      (application) =>
        application.reviewStatus === ReviewStatus.PENDING &&
        application.submissionStatus === SubmissionStatus.COMPLETED
    )
    .slice(0, 4);

  const usersOfLatestPendingApplications =
    latestPendingApplications.length > 0
      ? await queryAllUsers({
          filter: {
            or: latestPendingApplications.map((latestPendingApplication) => ({
              owner: { eq: latestPendingApplication.ownerID },
            })),
          },
        })
      : [];

  const sixMonthsAgoDate = dayjs().subtract(6, 'months').format('YYYY-MM-DD');

  const lastSixMonthsReviewedApplications =
    cycles.length > 0
      ? allApplications.filter(
          (application) =>
            application.submittedDate > sixMonthsAgoDate &&
            (application.reviewStatus === ReviewStatus.ACCEPTED ||
              application.reviewStatus === ReviewStatus.DENIED)
        )
      : [];

  const todayApplications =
    cycles.length > 0
      ? allApplications.filter(
          (application) =>
            application.submittedDate === dayjs().format('YYYY-MM-DD')
        )
      : [];

  return {
    latestPendingApplications,
    usersOfLatestPendingApplications,
    lastSixMonthsReviewedApplications,
    todayApplications,
    rootForms,
    cycles,
  };
};

export type THomeData = Awaited<Promise<ReturnType<typeof getHomeData>>>;

const homeLoader: LoaderFunction = async ({ params }) => {
  const { habitat } = params;

  const homeData = getHomeData(habitat);

  return defer({ homeData });
};

export default homeLoader;
