import { ListMaintenancesQueryVariables } from 'API';
import { generateClient } from 'aws-amplify/api';
import CustomGraphQlError from 'classes/GraphqlError';
import { customListMaintenances } from 'myGraphql/myQueries';

/**
 * Query all the Maintenances.
 * @param variables
 * @returns
 */
export const queryAllMaintenances = async (
  variables: ListMaintenancesQueryVariables | undefined = undefined
) => {
  const client = generateClient();

  const itemsToReturn = [];
  let currentNextToken: string | null | undefined;

  do {
    const response = await client.graphql({
      query: customListMaintenances,
      variables: {
        filter: {
          ...(variables ? variables.filter : {}),
        },
        nextToken: currentNextToken,
      },
    });

    if (response.errors) {
      throw new CustomGraphQlError(
        'Error while querying maintenances.',
        response.errors
      );
    }

    const { items, nextToken } = response.data.listMaintenances;

    currentNextToken = nextToken;

    itemsToReturn.push(...items);
  } while (currentNextToken);

  return itemsToReturn;
};
