import { Suspense, useCallback, useEffect, useRef, useState } from 'react';
import BreadCrumbs from 'components/BreadCrumbs/BreadCrumbs';
import {
  MdOutlineCalculate,
  MdOutlineDifference,
  MdOutlineLibraryAddCheck,
  MdOutlineNoteAlt,
  MdOutlineTextSnippet,
  MdPersonOutline,
} from 'react-icons/md';
import {
  Await,
  useBeforeUnload,
  useBlocker,
  useLoaderData,
  useLocation,
  useNavigate,
  useRevalidator,
  useRouteLoaderData,
} from 'react-router-dom';
import { getEditorStateWithFilesInBucket } from 'utils/lexicalEditor';
import { uploadData } from 'aws-amplify/storage';
import { get, post } from 'aws-amplify/api';
import { v4 } from 'uuid';
import { FileNode } from 'components/LexicalEditor/nodes/FileNode';
import { ImageNode } from 'components/LexicalEditor/nodes/ImageNode';
import GoBack from 'components/GoBack';
import { removeFiles } from 'utils/files';
import { EditorState } from 'lexical';
import { useAuthenticator, useBreakpointValue } from '@aws-amplify/ui-react';
import { usePostHog } from 'posthog-js/react';
import { GetObjectCommand, S3Client } from '@aws-sdk/client-s3';
import JSZIP from 'jszip';
import { saveAs } from 'file-saver';
import { flattenObject, getValueFromPath } from 'utils/objects';
import useHabitat from 'hooks/utils/useHabitat';
import { useTranslation } from 'react-i18next';
import Modal from 'components/Modal';
import CustomButton from 'components/CustomButton';
import { TApplicationData } from 'router/loaders/application';
import { TCycleData } from 'router/loaders/cycle';
import LocalNavigation from 'components/LocalNavigation';
import {
  ApplicationTypes,
  Note,
  ReviewStatus,
  SubmissionStatus,
  TestApplication,
  TestCycle,
} from 'API';
import {
  deleteNote,
  newNote,
  queryNotesByTestApplication,
} from 'services/graphql/Note';
import useAsync from 'hooks/utils/useAsync';
import {
  queryTestApplication,
  updateTestApplication,
} from 'services/graphql/TestApplication';
import { newDecision } from 'services/graphql/Decision';
import {
  deleteFormAnswer,
  newFormAnswer,
  queryFormAnswersByTestApplication,
  updateFormAnswer,
} from 'services/graphql/FormAnswer';
import { queryRootForm } from 'services/graphql/RootForm';
import { queryAllUsers } from 'services/graphql/User';
import style from './AffiliateApplicationDetailsPage.module.css';
import ApplicationTab from './components/ApplicationTab';
import NotesTab from './components/NotesTab';
import DecisionsTab from '../../../../../../components/DecisionsTab';
import CalculationsTab from './components/Calculations';
import { TDecideSchema } from './AffiliateApplicationDetailsPage.schema';
import Buttons from './components/Buttons';
import ApplicantTab from '../../../../../../components/ApplicantTab';

const s3client = new S3Client({
  region: 'us-east-1',
  credentials: {
    accessKeyId: process.env.REACT_APP_PUBLIC_S3_IDKEY || '',
    secretAccessKey: process.env.REACT_APP_PUBLIC_S3_SECRETKEY || '',
  },
});

const AffiliateApplicationDetailsPage = () => {
  const { cycleData } = useRouteLoaderData('cycle') as {
    cycleData: Promise<TCycleData>;
  };

  const { applicationData } = useLoaderData() as {
    applicationData: Promise<TApplicationData>;
  };

  const posthog = usePostHog();
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState(0);
  const [application, setApplication] = useState<TestApplication>();
  const [cycle, setCycle] = useState<TestCycle>();
  const [triggerNotes, setTriggerNotes] = useState(false);
  const [notesLoading, setNotesLoading] = useState(0);
  const [loading, setLoading] = useState(0);
  const [decideModalOpen, setDecideModalOpen] = useState(false);
  const [noteModal, setNoteModal] = useState(false);
  const [uploadingNote, setUploadingNote] = useState(false);
  const [deletingNote, setDeletingNote] = useState(false);
  const [downloadingFiles, setDownloadingFiles] = useState(0);
  const [reviseOpen, setReviseOpen] = useState(false);
  const [updatingRevision, setUpdatingRevision] = useState(false);
  const [leaveRevisionConfirmation, setLeaveRevisionConfirmation] =
    useState<number>();
  const revalidator = useRevalidator();
  const posthogHasBeenNotified = useRef(false);

  const navigate = useNavigate();

  const { pathname } = useLocation();

  const { habitat } = useHabitat();

  const { user } = useAuthenticator((context) => [context.user]);

  const titleStyle = useBreakpointValue({
    base: 'theme-subtitle-s1',
    large: 'theme-headline-medium',
  });

  const fetchNotes = useCallback(async () => {
    try {
      setNotesLoading((prevLoading) => prevLoading + 1);
      if (application) {
        String(triggerNotes);
        const notesArray = await queryNotesByTestApplication({
          testapplicationID: application?.id || '',
        });
        return notesArray;
      }
      return [];
    } catch (error) {
      console.log('Error while fetching notes.', error);
    } finally {
      setNotesLoading((prevLoading) => prevLoading - 1);
    }
  }, [application, triggerNotes]);

  const { value: notes } = useAsync({ asyncFunction: fetchNotes });

  const triggerNotesRefetch = () =>
    setTriggerNotes((prevTriggerNotes) => !prevTriggerNotes);

  const uploadDecisionFile = async (file: File) => {
    const { application } = await applicationData;
    const result = await uploadData({
      path: `public/decision/${habitat?.urlName}/${application.id}/${v4()}_${
        file.name
      }`,
      data: file,
    }).result;

    return result;
  };

  const handleDecideOnClick = () => setDecideModalOpen(true);

  const handleDecideModalOnClose = () =>
    loading === 0 && setDecideModalOpen(false);

  const handleOnValidDecide = async (data: TDecideSchema) => {
    setLoading((previousLoading) => previousLoading + 1);
    try {
      const { application: resolvedApplication } = await applicationData;

      const original = await queryTestApplication(resolvedApplication.id);

      if (!original) {
        return;
      }

      const persistedApplication = await updateTestApplication({
        id: original.id,
        reviewStatus: data.status,
        ...(data.status === ReviewStatus.RETURNED && original.ownerID
          ? {
              submissionStatus: SubmissionStatus.INCOMPLETE,
              version: (original.version || 0) + 1,
            }
          : {}),
      });

      const editorStateWithFilesInS3 = await getEditorStateWithFilesInBucket(
        data.message,
        uploadDecisionFile
      );

      const { application } = await applicationData;

      await newDecision({
        testapplicationID: application.id,
        status: data.status,
        serializedEditorState: JSON.stringify(editorStateWithFilesInS3),
      });

      let type = '';

      if (data.status === ReviewStatus.ACCEPTED) {
        type = 'application_accepted';
      } else if (data.status === ReviewStatus.DENIED) {
        type = 'application_denied';
      } else if (data.status === ReviewStatus.RETURNED) {
        type = 'application_returned';
        const formAnswersToDelete = await queryFormAnswersByTestApplication({
          testapplicationID: application.id,
          filter: {
            isCopy: { eq: true },
          },
        });

        await Promise.allSettled(
          formAnswersToDelete.map((formAnswer) =>
            deleteFormAnswer(formAnswer.id)
          )
        );
      } else {
        type = 'application_pending';
      }

      const { cycle } = await cycleData;

      posthog?.capture(type, {
        data,
        application,
        habitat,
        cycle,
        posthogAction: 'application_reviewed',
      });

      if (original.ownerID) {
        const applicationLink = `${window.origin}/${habitat?.urlName}/applicant/${cycle.id}`;
        await post({
          apiName: 'sendEmailToApplicantAPI',
          path: '/notify',
          options: {
            body: {
              subject: 'Status update on your Habitat for Humanity application',
              body: `<p>A decision has been made on your recent Habitat for Humanity application. Please click on the link below to see it.<br/><br/><a href="${applicationLink}">Application link</a></p>`,
              sub: persistedApplication.ownerID || '',
              habitat: habitat?.longName || '',
            },
          },
        }).response;
      }

      setDecideModalOpen(false);

      navigate('..');
    } catch (error) {
      console.log('An error ocurred while returning the application');
    }
    setLoading((previousLoading) => previousLoading - 1);
  };

  const handleNoteOpenClose = () => {
    if (!uploadingNote) {
      setNoteModal((prevNoteModal) => !prevNoteModal);
    }
  };

  const deleteFilesOfNote = async (note: Note) => {
    const editorState = JSON.parse(note.serializedEditorState);
    const s3Keys = [];
    for (const children of editorState.root.children) {
      if (
        children.type === FileNode.getType() ||
        (children.type === ImageNode.getType() && children.s3Key)
      ) {
        s3Keys.push(children.s3Key);
      }
    }
    await removeFiles(s3Keys);
  };

  const handleDeleteNote = async (note: Note) => {
    try {
      setDeletingNote(true);
      await deleteFilesOfNote(note);
      await deleteNote(note.id);
    } catch (error) {
      console.log('Error deleting note', error);
    } finally {
      setDeletingNote(false);
      triggerNotesRefetch();
    }
  };

  const uploadNoteFile = async (file: File) => {
    const { application } = await applicationData;

    const result = await uploadData({
      path: `public/notes/${habitat?.urlName}/${application.id}/${v4()}_${
        file.name
      }`,
      data: file,
    }).result;

    return result;
  };

  const handleOnSaveNote = async (editorState: EditorState) => {
    try {
      if (!user || !user.username) {
        return;
      }

      setUploadingNote(true);
      const editorStateWithS3Keys = await getEditorStateWithFilesInBucket(
        editorState,
        uploadNoteFile
      );
      const serializedEditorState = JSON.stringify(editorStateWithS3Keys);

      const { application } = await applicationData;

      await newNote({
        ownerID: user.username,
        serializedEditorState,
        testapplicationID: application.id,
      });

      handleNoteOpenClose();
    } catch (error) {
      console.log('Error saving note', error);
    } finally {
      triggerNotesRefetch();
      setUploadingNote(false);
    }
  };

  const handleDownloadApplication = async () => {
    try {
      setDownloadingFiles((prevDownloadingFiles) => prevDownloadingFiles + 1);

      const zip = new JSZIP();

      const { application, formAnswers } = await applicationData;

      const { cycle } = await cycleData;

      const pdfBase64Response = await get({
        apiName: 'habitat',
        path: `/application-pdf`,
        options: {
          headers: {
            Accept: 'application/pdf',
          },
          queryParams: {
            applicationId: application.id,
            language: 'en',
          },
        },
      }).response;

      const pdfBlob = await pdfBase64Response.body.blob();

      zip.file('Application.pdf', pdfBlob);

      for (const formAnswer of formAnswers) {
        const { values } = formAnswer;

        const flatValues = flattenObject(JSON.parse(values || '{}'));

        const fileValuesPath = flatValues.filter((flatValue) =>
          flatValue.path.endsWith('.originalName')
        );

        if (fileValuesPath.length > 0 && values) {
          for (const fileValuePath of fileValuesPath) {
            const path = fileValuePath.path.replace('.originalName', '');

            const fileValue = getValueFromPath(JSON.parse(values), path);

            const { url, bucket, data } = fileValue as {
              url: string;
              bucket?: string;
              data?: {
                bucket: string;
              };
            };

            const key = decodeURIComponent(
              url.replace(`${process.env.REACT_APP_FORMIO_UPLOAD}/s3/`, '')
            );

            const s3Name = key.split('/').at(-1);

            const command = new GetObjectCommand({
              Bucket: bucket || data?.bucket,
              Key: key,
            });

            const response = await s3client.send(command);

            const byteArr = await response.Body?.transformToByteArray();

            if (!byteArr) {
              return;
            }

            zip.file(`files/${s3Name}`, byteArr);
          }
        }
      }

      const rootForm = await queryRootForm(cycle?.rootformID);

      const userData = await queryAllUsers({
        filter: { owner: { eq: application?.ownerID || '' } },
      });

      zip.generateAsync({ type: 'blob' }).then((content) => {
        saveAs(
          content,
          `${rootForm?.name}-${cycle?.name}-${userData[0].firstName} ${userData[0].lastName}.zip`
        );
      });
    } catch (error) {
      console.log('Error downloading files', error);
    } finally {
      setDownloadingFiles((prevDownloadingFiles) => prevDownloadingFiles - 1);
    }
  };

  const handleReviseClick = () => setReviseOpen(true);

  const handleReviseCreate = async () => {
    try {
      const { application } = await applicationData;

      const original = await queryTestApplication(application.id);

      if (original) {
        await updateTestApplication({
          id: original.id,
          hasRevision: true,
        });
      }
    } catch (error) {
      console.log('Error creating revision.');
    }
    setReviseOpen(false);

    setActiveTab(2);

    revalidator.revalidate();
  };

  const handleReviseDisable = async () => {
    try {
      const { application } = await applicationData;

      const original = await queryTestApplication(application.id);

      if (original) {
        await updateTestApplication({
          id: original.id,
          hasRevision: false,
        });

        const formAnswersToDelete = await queryFormAnswersByTestApplication({
          testapplicationID: application.id,
          filter: {
            isCopy: { eq: true },
          },
        });

        await Promise.allSettled(
          formAnswersToDelete.map((formAnswer) =>
            deleteFormAnswer(formAnswer.id)
          )
        );
      }
    } catch (error) {
      console.log('Error disabling revision.');
    }
    setReviseOpen(false);

    setActiveTab(3);

    revalidator.revalidate();
  };

  const handleReviseClose = () => setReviseOpen(false);

  const handleReviseUpdate = async (submission: unknown) => {
    setUpdatingRevision(true);
    try {
      if (
        submission &&
        typeof submission === 'object' &&
        'data' in submission &&
        submission.data &&
        typeof submission.data === 'object'
      ) {
        const { application } = await applicationData;

        await Promise.allSettled(
          Object.entries(submission.data).map(([key, value]) =>
            (async () => {
              const originalFormAnswer =
                await queryFormAnswersByTestApplication({
                  testapplicationID: application.id,
                  filter: {
                    and: [
                      { page: { eq: key } },
                      { isCopy: { eq: true } },

                      { version: { eq: application.version } },
                    ],
                  },
                });

              if (originalFormAnswer.length > 0) {
                await updateFormAnswer({
                  id: originalFormAnswer[0].id,
                  values: JSON.stringify(value),
                });
              } else {
                await newFormAnswer({
                  page: key,
                  values: JSON.stringify(value),
                  testapplicationID: application.id,
                  isCopy: true,
                  version: application.version,
                });
              }
            })()
          )
        );
      }
    } catch (error) {
      console.log('error updating revision');
    }

    setUpdatingRevision(false);

    setActiveTab(5);

    revalidator.revalidate();
  };

  useEffect(() => {
    const getApplicationCycleData = async () => {
      const { application: resolvedApplication } = await applicationData;
      const { cycle: resolvedCycle } = await cycleData;
      if (!application && !cycle) {
        setActiveTab(resolvedApplication.type === 'ONLINE' ? 0 : 3);
      }
      setApplication(resolvedApplication);
      setCycle(resolvedCycle);
    };

    getApplicationCycleData();
  }, [application, applicationData, cycle, cycleData]);

  useEffect(() => {
    if (
      !posthogHasBeenNotified.current &&
      application &&
      cycle &&
      habitat &&
      posthog
    ) {
      posthog.capture('application_opened', {
        application,
        habitat,
        cycle,
      });
      posthogHasBeenNotified.current = true;
    }
  }, [application, cycle, habitat, posthog]);

  const handleLeavePage = useCallback(() => {
    if (application && cycle && habitat && posthog) {
      posthog.capture('application_closed', {
        application,
        habitat,
        cycle,
      });
    }
  }, [application, cycle, habitat, posthog]);

  useBeforeUnload(handleLeavePage);

  const blockLeaveRevision = useBlocker(() => {
    if (activeTab === 2) {
      return true;
    }
    handleLeavePage();
    return false;
  });

  const handleOnLeaveRevision = () => {
    if (blockLeaveRevision.state === 'blocked') {
      handleLeavePage();
      blockLeaveRevision.proceed && blockLeaveRevision.proceed();
    } else {
      leaveRevisionConfirmation && setActiveTab(leaveRevisionConfirmation);
      setLeaveRevisionConfirmation(undefined);
    }
  };

  const handleOnStayRevision = () => {
    blockLeaveRevision.reset && blockLeaveRevision.reset();
    setLeaveRevisionConfirmation(undefined);
  };

  useEffect(() => {
    setActiveTab(0);
    setApplication(undefined);
    setCycle(undefined);
  }, [pathname]);

  const breadCrumbsItems = [
    { label: t('pages.habitat.affiliate.forms.name'), to: '../../../forms' },
    { label: t('pages.habitat.affiliate.cycles.name'), to: '../..' },
    { label: t('pages.habitat.affiliate.cycles.cycle.name'), to: '..' },
    { label: t('pages.habitat.affiliate.cycles.cycle.application.name') },
  ];

  return (
    <div className={`${style.page}`}>
      <BreadCrumbs items={breadCrumbsItems} />
      <div className={`${style.ctaContainer}`}>
        <div className={`${style.cta}`}>
          <GoBack />
          <span className={`${titleStyle} ${style.title}`}>
            {t('pages.habitat.affiliate.cycles.cycle.application.title')}
          </span>
        </div>
      </div>
      {revalidator.state !== 'loading' &&
        application &&
        application.reviewStatus !== ReviewStatus.RETURNED && (
          <div>
            <Buttons
              application={application}
              decideModalOpen={decideModalOpen}
              handleDecideModalOnClose={handleDecideModalOnClose}
              handleOnValidDecide={handleOnValidDecide}
              handleDecideOnClick={handleDecideOnClick}
              handleDownloadApplicationOnClick={handleDownloadApplication}
              loading={loading}
              downloading={downloadingFiles > 0}
              reviseOpen={reviseOpen}
              handleClickRevise={handleReviseClick}
              handleCloseRevise={handleReviseClose}
              handleCreateRevise={handleReviseCreate}
              handleDisableRevise={handleReviseDisable}
              hasRevision={application?.hasRevision || false}
            />
          </div>
        )}
      <div className={`${style.detailsContainer}`}>
        <Suspense
          fallback={
            <LocalNavigation
              items={[
                {
                  skeleton: true,
                  numOfCharacters: 10,
                },

                {
                  skeleton: true,
                  numOfCharacters: 15,
                },
                {
                  skeleton: true,
                  numOfCharacters: 12,
                },
              ]}
              current={activeTab}
              onChange={() => undefined}
            />
          }
        >
          <Await resolve={Promise.allSettled([applicationData, cycleData])}>
            {([
              {
                value: { application },
              },
            ]: [{ value: TApplicationData }, { value: TCycleData }]) => {
              if (revalidator.state === 'loading') {
                return (
                  <LocalNavigation
                    items={[
                      {
                        skeleton: true,
                        numOfCharacters: 10,
                      },

                      {
                        skeleton: true,
                        numOfCharacters: 15,
                      },
                      {
                        skeleton: true,
                        numOfCharacters: 12,
                      },
                    ]}
                    current={activeTab}
                    onChange={() => undefined}
                  />
                );
              }
              return (
                <LocalNavigation
                  items={[
                    {
                      label: t(
                        'pages.habitat.affiliate.cycles.cycle.application.tabs.applicant'
                      ),
                      icon: <MdPersonOutline />,
                      hide: application?.type !== ApplicationTypes.ONLINE,
                    },
                    {
                      label: t(
                        'pages.habitat.affiliate.cycles.cycle.application.tabs.submission'
                      ),
                      icon: <MdOutlineNoteAlt />,
                    },
                    {
                      label: t(
                        'pages.habitat.affiliate.cycles.cycle.application.tabs.revision'
                      ),
                      icon: <MdOutlineDifference />,
                      hide:
                        !application?.hasRevision ||
                        application?.reviewStatus === ReviewStatus.RETURNED,
                    },
                    {
                      label: t(
                        'pages.habitat.affiliate.cycles.cycle.application.tabs.notes'
                      ),
                      icon: <MdOutlineTextSnippet />,
                    },
                    {
                      label: t(
                        'pages.habitat.affiliate.cycles.cycle.application.tabs.decisions'
                      ),
                      icon: <MdOutlineLibraryAddCheck />,
                    },
                    {
                      label: t(
                        'pages.habitat.affiliate.cycles.cycle.application.tabs.calculations'
                      ),
                      icon: <MdOutlineCalculate />,
                      hide: application?.type !== ApplicationTypes.ONLINE,
                    },
                  ]}
                  current={activeTab}
                  onChange={(newCurrent) => {
                    if (activeTab !== 2) {
                      setActiveTab(newCurrent);
                    } else {
                      setLeaveRevisionConfirmation(newCurrent);
                    }
                  }}
                />
              );
            }}
          </Await>
        </Suspense>
        <div className={style.tabContainer}>
          <Suspense fallback={<ApplicantTab skeleton />}>
            <Await resolve={Promise.allSettled([applicationData, cycleData])}>
              {([
                {
                  value: {
                    application,
                    attributes,
                    formAnswers,
                    decisions,
                    user,
                  },
                },
                {
                  value: { formSchema, translations },
                },
              ]: [{ value: TApplicationData }, { value: TCycleData }]) => {
                if (revalidator.state === 'loading') {
                  return <ApplicantTab skeleton />;
                }

                return (
                  <>
                    {activeTab === 0 && (
                      <ApplicantTab
                        user={user}
                        attributes={attributes?.attributes}
                      />
                    )}
                    {activeTab === 1 && (
                      <ApplicationTab
                        application={application}
                        formAnswers={formAnswers}
                        formSchema={formSchema}
                        showPrevious={
                          application.reviewStatus === ReviewStatus.RETURNED
                        }
                        translations={translations}
                      />
                    )}
                    {activeTab === 2 && (
                      <>
                        <Modal
                          title={t(
                            'pages.habitat.affiliate.cycles.cycle.application.confirmLeaveRevisionModal.title'
                          )}
                          open={
                            blockLeaveRevision.state === 'blocked' ||
                            typeof leaveRevisionConfirmation === 'number'
                          }
                          onClickClose={handleOnStayRevision}
                          width="500px"
                        >
                          <div className={style.leaveRevisionContent}>
                            <p>
                              {t(
                                'pages.habitat.affiliate.cycles.cycle.application.confirmLeaveRevisionModal.text'
                              )}
                            </p>
                            <div className={style.leaveRevisionContentButtons}>
                              <CustomButton
                                type="button"
                                onClick={handleOnLeaveRevision}
                              >
                                {t(
                                  'pages.habitat.affiliate.cycles.cycle.application.confirmLeaveRevisionModal.accept'
                                )}
                              </CustomButton>
                              <CustomButton
                                variation="secondary"
                                type="button"
                                onClick={handleOnStayRevision}
                              >
                                {t(
                                  'pages.habitat.affiliate.cycles.cycle.application.confirmLeaveRevisionModal.cancel'
                                )}
                              </CustomButton>
                            </div>
                          </div>
                        </Modal>
                        <ApplicationTab
                          application={application}
                          formAnswers={formAnswers}
                          revision
                          handleReviseUpdate={handleReviseUpdate}
                          updatingRevision={updatingRevision}
                          formSchema={formSchema}
                          translations={translations}
                        />
                      </>
                    )}
                    {activeTab === 3 && (
                      <NotesTab
                        notes={notes || []}
                        noteModal={noteModal}
                        uploadingNote={uploadingNote}
                        deletingNote={deletingNote}
                        handleDeleteNote={handleDeleteNote}
                        handleNoteOpenClose={handleNoteOpenClose}
                        handleOnSaveNote={handleOnSaveNote}
                        loading={notesLoading > 0}
                      />
                    )}
                    {activeTab === 4 && <DecisionsTab decisions={decisions} />}
                    {activeTab === 5 && (
                      <CalculationsTab
                        formAnswers={formAnswers}
                        hasRevision={application.hasRevision || false}
                        currentVersion={application.version || 0}
                        showPrevious={
                          application.reviewStatus === ReviewStatus.RETURNED
                        }
                      />
                    )}
                  </>
                );
              }}
            </Await>
          </Suspense>
        </div>
      </div>
    </div>
  );
};

export default AffiliateApplicationDetailsPage;
