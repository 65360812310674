import {
  CreateDecisionInput,
  DecisionsByTestapplicationIDQueryVariables,
  ListDecisionsQueryVariables,
  UpdateDecisionInput,
} from 'API';
import { generateClient } from 'aws-amplify/api';
import CustomGraphQlError from 'classes/GraphqlError';
import {
  customCreateDecision,
  customDeleteDecision,
  customUpdateDecision,
} from 'myGraphql/myMutations';
import {
  customDecisionsByTestapplicationID,
  customGetDecision,
  customListDecisions,
} from 'myGraphql/myQueries';

/**
 * Query all the Decisions of a TestApplication that have the _deleted property not equal to true.
 * @param testApplicationId The TestApplication id.
 * @returns An array of Decisions
 */
export const queryDecisionsByTestApplication = async (
  variables: DecisionsByTestapplicationIDQueryVariables
) => {
  const client = generateClient();

  let nextTokenFlag: string | null | undefined;

  const itemsToReturn = [];

  do {
    const response = await client.graphql({
      query: customDecisionsByTestapplicationID,
      variables: {
        ...variables,
        nextToken: nextTokenFlag,
      },
    });

    if (response.errors) {
      throw new CustomGraphQlError(
        'Error while querying decisions by testApplication.',
        response.errors
      );
    }

    const { nextToken, items } = response.data.decisionsByTestapplicationID;

    nextTokenFlag = nextToken;

    itemsToReturn.push(...items);
  } while (nextTokenFlag);

  return itemsToReturn;
};

/**
 * Query all the Decisions that have the _deleted property not equal to true.
 * @param variables The variables to pass to the query.
 * @returns An array of Decisions.
 */
export const queryAllDecisions = async (
  variables: ListDecisionsQueryVariables | undefined = undefined
) => {
  const client = generateClient();

  const itemsToReturn = [];

  let currentNextToken: string | null | undefined;

  do {
    const response = await client.graphql({
      query: customListDecisions,
      variables: {
        filter: {
          ...(variables ? variables.filter : {}),
        },
        nextToken: currentNextToken,
      },
    });

    if (response.errors) {
      throw new CustomGraphQlError(
        'Error while querying decisions.',
        response.errors
      );
    }

    const { items, nextToken } = response.data.listDecisions;

    currentNextToken = nextToken;

    itemsToReturn.push(...items);
  } while (currentNextToken);

  return itemsToReturn;
};

/**
 * Query one Decision.
 * @param id The Decision id.
 * @returns The Decision found or undefined or null.
 */
export const queryDecision = async (id: string) => {
  const client = generateClient();

  const response = await client.graphql({
    query: customGetDecision,
    variables: {
      id,
    },
  });

  if (response.errors) {
    throw new CustomGraphQlError(
      'Error while querying decision.',
      response.errors
    );
  }

  return response.data.getDecision;
};

/**
 * Persists a new Decision.
 * @param decision The Decision to persist.
 * @returns The Decision persisted.
 */
export const newDecision = async (decision: CreateDecisionInput) => {
  const client = generateClient();

  const response = await client.graphql({
    query: customCreateDecision,
    variables: {
      input: decision,
    },
  });

  if (response.errors) {
    throw new CustomGraphQlError(
      'Error while creating new decision.',
      response.errors
    );
  }

  return response.data.createDecision;
};

/**
 * Updates a Decision.
 * @param input The Decision with changes.
 * @returns The updated Decision.
 */
export const updateDecision = async (input: UpdateDecisionInput) => {
  const client = generateClient();

  const response = await client.graphql({
    query: customUpdateDecision,
    variables: {
      input,
    },
  });

  if (response.errors) {
    throw new CustomGraphQlError(
      'Error while updating decision.',
      response.errors
    );
  }

  return response.data.updateDecision;
};

/**
 * Deletes a Decision.
 * @param id The Decision id.
 * @returns The deleted Decision.
 */
export const deleteDecision = async (id: string) => {
  const client = generateClient();

  const response = await client.graphql({
    query: customDeleteDecision,
    variables: {
      input: {
        id,
      },
    },
  });

  if (response.errors) {
    throw new CustomGraphQlError(
      'Error while deleting decision.',
      response.errors
    );
  }

  return response.data.deleteDecision;
};
