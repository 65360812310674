import {
  CreateFormSchemaInput,
  ListFormSchemasQueryVariables,
  UpdateFormSchemaInput,
} from 'API';
import { generateClient } from 'aws-amplify/api';
import CustomGraphQlError from 'classes/GraphqlError';
import {
  customCreateFormSchema,
  customDeleteFormSchema,
  customUpdateFormSchema,
} from 'myGraphql/myMutations';
import {
  customGetFormSchema,
  customListFormSchemas,
} from 'myGraphql/myQueries';

/**
 * Query all the FormSchemas that have the _deleted property not equal to true.
 * @param variables The variables to pass to the query.
 * @returns An array of FormSchemas.
 */
export const queryAllFormSchemas = async (
  variables: ListFormSchemasQueryVariables | undefined = undefined
) => {
  const client = generateClient();

  const itemsToReturn = [];
  let currentNextToken: string | null | undefined;

  do {
    const response = await client.graphql({
      query: customListFormSchemas,
      variables: {
        filter: {
          ...(variables ? variables.filter : {}),
        },
        nextToken: currentNextToken,
      },
    });

    if (response.errors) {
      throw new CustomGraphQlError(
        'Error while querying formSchemas.',
        response.errors
      );
    }

    const { items, nextToken } = response.data.listFormSchemas;

    currentNextToken = nextToken;

    itemsToReturn.push(...items);
  } while (currentNextToken);

  return itemsToReturn;
};

/**
 * Query a FormSchema.
 * @param id The FormSchema id.
 * @returns The FormSchema found or null or undefined.
 */
export const queryFormSchema = async (id: string) => {
  const client = generateClient();

  const response = await client.graphql({
    query: customGetFormSchema,
    variables: {
      id,
    },
  });

  if (response.errors) {
    throw new CustomGraphQlError(
      'Error while querying formSchema.',
      response.errors
    );
  }

  return response.data.getFormSchema;
};

/**
 * Persist a new FormSchema.
 * @param formSchema The FormSchema to persist.
 * @returns The persisted FormSchema.
 */
export const newFormSchema = async (formSchema: CreateFormSchemaInput) => {
  const client = generateClient();

  const response = await client.graphql({
    query: customCreateFormSchema,
    variables: {
      input: formSchema,
    },
  });

  if (response.errors) {
    throw new CustomGraphQlError(
      'Error while creating new formSchema.',
      response.errors
    );
  }

  return response.data.createFormSchema;
};

/**
 * Updates a FormSchema.
 * @param input The FormSchema with changes.
 * @returns The updated FormSchema.
 */
export const updateFormSchema = async (input: UpdateFormSchemaInput) => {
  const client = generateClient();

  const response = await client.graphql({
    query: customUpdateFormSchema,
    variables: {
      input,
    },
  });

  if (response.errors) {
    throw new CustomGraphQlError(
      'Error while updating formSchema.',
      response.errors
    );
  }

  return response.data.updateFormSchema;
};

/**
 * Deletes a FormSchema.
 * @param id The FormSchema id.
 * @returns The deleted FormSchema.
 */
export const deleteFormSchema = async (id: string) => {
  const client = generateClient();

  const response = await client.graphql({
    query: customDeleteFormSchema,
    variables: {
      input: {
        id,
      },
    },
  });

  if (response.errors) {
    throw new CustomGraphQlError(
      'Error while deleting formSchema.',
      response.errors
    );
  }

  return response.data.deleteFormSchema;
};
