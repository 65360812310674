const field = {
  gap: { value: '0' },
  label: {
    color: { value: '{colors.neutral.100}' },
    fontSize: { value: '{fontSizes.small}' },
    lineHeight: { value: '{lineHeights.small}' },
    padding: { value: '4px 16px' },
  },
};

export default field;
