import { GraphQLResult } from 'aws-amplify/api';

/**
 * Class representing a graphql error.
 */
class CustomGraphQlError extends Error {
  /** Array of erros from graphql query. */
  errors: GraphQLResult['errors'];

  /**
   * Create a custom graphql error.
   * @param message The error message.
   * @param errors The array of errors from graphql query.
   * @param options The error options.
   */
  constructor(
    message: string | undefined,
    errors: GraphQLResult['errors'],
    options: ErrorOptions = {}
  ) {
    super(message, options);

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, CustomGraphQlError);
    }

    this.name = 'GraphqlError';

    this.errors = errors;
  }
}

export default CustomGraphQlError;
