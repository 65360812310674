import { Suspense, useCallback, useEffect, useState } from 'react';
import { useAuthenticator, useBreakpointValue } from '@aws-amplify/ui-react';
import { Await, useLoaderData, useLocation } from 'react-router-dom';
import useAsync from 'hooks/utils/useAsync/useAsync';
import { Status } from 'utils/enums';
import useHabitat from 'hooks/utils/useHabitat';
import { useTranslation } from 'react-i18next';
import { TCycleWithRootFormData } from 'router/loaders/cycle';
import GoBack from 'components/GoBack';
import BreadCrumbs from 'components/BreadCrumbs/BreadCrumbs';
import {
  newTestApplication,
  queryTestApplicationsByTestCycle,
} from 'services/graphql/TestApplication';
import { ApplicationTypes, ReviewStatus, SubmissionStatus } from 'API';
import Form from './components/Form';
import NoOpenCycle from './components/NoOpenCycle';
import SuccesfullySubmitted from './components/SuccesfullySubmitted';
import Loading from './components/Loading';
import Error from './components/Error';
import style from './ApplicantCyclePage.module.css';
import { DataProps, DISPLAY, ERROR } from './ApplicantCyclePage.types';
import Reviewed from './components/Reviewed/Reviewed';

const ApplicantCyclePage = () => {
  const { habitat } = useHabitat();

  const { cycleWithRootFormData } = useLoaderData() as {
    cycleWithRootFormData: Promise<TCycleWithRootFormData>;
  };

  const isSmall = useBreakpointValue({
    base: true,
    medium: false,
  });

  const titleStyle = useBreakpointValue({
    base: 'theme-subtitle-s1',
    large: 'theme-headline-medium',
  });

  const { t: rootFormT, i18n: rootFormI18n } = useTranslation('rootForm');

  const { t } = useTranslation();

  const { user } = useAuthenticator((context) => [context.user]);

  const location = useLocation();
  const [activeTab, setActiveTab] = useState(1);
  const [review, setReview] = useState(false);

  const onReview = () => {
    setActiveTab(0);
    setReview(true);
  };

  const getData = useCallback(async (): Promise<DataProps> => {
    try {
      const { cycle } = await cycleWithRootFormData;
      const applications = (
        await queryTestApplicationsByTestCycle({
          testcycleID: cycle.id,
          filter: {
            ownerID: { eq: user.username },
          },
        })
      ).sort((a, b) => (b.version || 0) - (a.version || 0));

      let [application] = applications;

      if (applications.length === 0) {
        if (!cycle.isOpen) {
          return {
            display: DISPLAY.NO_OPEN_CYCLE,
            data: {
              error: ERROR.CYCLE_NOT_OPEN,
            },
          };
        }

        if (sessionStorage.getItem('creatingApplication')) {
          return {
            display: DISPLAY.LOADING,
          };
        }

        sessionStorage.setItem('creatingApplication', 'true');

        const newApplication = await newTestApplication({
          ownerID: user.username,
          lastPage: 0,
          lastSection: location.pathname,
          submissionStatus: SubmissionStatus.INCOMPLETE,
          reviewStatus: ReviewStatus.PENDING,
          submittedDate: '0001-01-01',
          testcycleID: cycle.id,
          type: ApplicationTypes.ONLINE,
          version: 0,
        });

        application = newApplication;

        sessionStorage.removeItem('creatingApplication');

        return {
          display: DISPLAY.APPLICATION,
          data: {
            application: {
              ...application,
              Decisions: undefined,
              FormAnswers: undefined,
              Notes: undefined,
            },
          },
        };
      }

      const reviewed =
        application.reviewStatus === ReviewStatus.ACCEPTED ||
        application.reviewStatus === ReviewStatus.DENIED ||
        application.reviewStatus === ReviewStatus.RETURNED;

      if (reviewed || review) {
        return {
          display: DISPLAY.REVIEWED,
          data: {
            application: {
              ...application,
              Decisions: undefined,
              FormAnswers: undefined,
              Notes: undefined,
            },
          },
        };
      }

      if (application.submissionStatus === SubmissionStatus.COMPLETED) {
        return {
          display: DISPLAY.COMPLETED,
          data: {
            application: {
              ...application,
              Decisions: undefined,
              FormAnswers: undefined,
              Notes: undefined,
            },
          },
        };
      }

      if (!cycle.isOpen) {
        return {
          display: DISPLAY.NO_OPEN_CYCLE,
          data: {
            error: ERROR.CYCLE_NOT_OPEN,
            application: {
              ...application,
              Decisions: undefined,
              FormAnswers: undefined,
              Notes: undefined,
            },
          },
        };
      }

      return {
        display: DISPLAY.APPLICATION,
        data: {
          application: {
            ...application,
            Decisions: undefined,
            FormAnswers: undefined,
            Notes: undefined,
          },
        },
      };
    } catch (error) {
      return {
        display: DISPLAY.ERROR,
        data: {
          error: ERROR.UNEXPECTED_ERROR,
        },
      };
    }
  }, [cycleWithRootFormData, review, user.username, location.pathname]);

  useEffect(() => {
    const loadSupportQuestion = async () => {
      const { rootForm } = await cycleWithRootFormData;
      if (rootForm.supportQuestion) {
        rootFormI18n.addResource(
          'en',
          'rootForm',
          'supportQuestion',
          rootForm.supportQuestion?.en
        );

        rootFormI18n.addResource(
          'es',
          'rootForm',
          'supportQuestion',
          rootForm.supportQuestion?.es || rootForm.supportQuestion?.en
        );
      }
    };

    loadSupportQuestion();

    return () => {
      rootFormI18n.removeResourceBundle('en', 'rootForm');
      rootFormI18n.removeResourceBundle('es', 'rootForm');
    };
  }, [cycleWithRootFormData, rootFormI18n, rootFormT]);

  const { value, status } = useAsync({
    asyncFunction: getData,
  });

  const breadCrumbsItems = [
    {
      label: t(
        'pages.habitat.applicant.cycle.breadcrumbs.applicationDashboard'
      ),
      to: '../applications',
    },
    { label: 'Detail' },
  ];

  if (status === Status.PENDING) {
    return <Loading />;
  }

  if (status === Status.REJECTED) {
    return <Error />;
  }

  if (value?.display === DISPLAY.LOADING) {
    return <Loading />;
  }

  if (value?.display === DISPLAY.ERROR) {
    return <Error error={value.data.error} />;
  }

  if (!habitat) {
    return <Error error={ERROR.HABITAT_NOT_FOUND} />;
  }

  if (value?.display === DISPLAY.REVIEWED) {
    return (
      <div className={`${style.page}`}>
        <div>
          {!isSmall && <BreadCrumbs items={breadCrumbsItems} />}
          <div className={`${style.titleContainer}`}>
            <GoBack to="../applications" />
            <span className={`${titleStyle} ${style.title}`}>
              {t('pages.habitat.applicant.cycle.title')}
            </span>
          </div>
        </div>
        <Suspense>
          <Await resolve={cycleWithRootFormData}>
            {({
              cycle,
              rootForm,
              formSchema,
              translations,
            }: TCycleWithRootFormData) => (
              <Reviewed
                habitat={habitat}
                cycle={cycle}
                rootForm={rootForm}
                application={value.data.application}
                activeTab={activeTab}
                setActiveTab={setActiveTab}
                formSchema={formSchema}
                translations={translations}
              />
            )}
          </Await>
        </Suspense>
      </div>
    );
  }

  if (value?.display === DISPLAY.NO_OPEN_CYCLE) {
    return (
      <div className={`${style.page}`}>
        <Suspense>
          <Await resolve={cycleWithRootFormData}>
            {({ cycle }: TCycleWithRootFormData) => (
              <NoOpenCycle
                cycle={cycle}
                onReview={onReview}
                showReview={
                  value?.data?.application?.submissionStatus ===
                  SubmissionStatus.COMPLETED
                }
              />
            )}
          </Await>
        </Suspense>
      </div>
    );
  }

  if (value?.display === DISPLAY.COMPLETED) {
    return (
      <Suspense>
        <Await resolve={cycleWithRootFormData}>
          {({ rootForm }: TCycleWithRootFormData) => (
            <div className={`${style.page}`}>
              <SuccesfullySubmitted onReview={onReview} rootForm={rootForm} />
            </div>
          )}
        </Await>
      </Suspense>
    );
  }

  return (
    <Suspense>
      <Await resolve={cycleWithRootFormData}>
        {({
          cycle,
          rootForm,
          formSchema,
          translations,
        }: TCycleWithRootFormData) => (
          <Form
            application={value?.data?.application}
            cycle={cycle}
            rootForm={rootForm}
            formContainer={false}
            formSchema={formSchema}
            serializedHelpContent={rootFormT('supportQuestion')}
            translations={translations}
          />
        )}
      </Await>
    </Suspense>
  );
};

export default ApplicantCyclePage;
