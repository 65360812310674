import { TestimonialsProps } from '../types';

const testimonials: TestimonialsProps = [
  {
    id: 1,
    text: `I love the Habitat App! It has completely streamlined our application process, making it easy for applicants to submit online applications and for our staff to review them. The Habitat App team has been incredibly patient and supportive throughout the onboarding process, and they’ve responded promptly to any change requests we had.`,
    organization: {
      name: 'Alachua',
      logo: 'https://hfh-app-storage-bucket134315-formio.s3.amazonaws.com/public/test/landing/Alachua.png',
    },
    author: {
      name: 'Susan Meadows',
      title: 'Family Services Coordinator',
    },
  },
  {
    id: 2,
    text: `Habitat App has saved our affiliate countless hours by streamlining the Homeowner Selection committee's application review process. The platform centralizes applicant records, ensuring organization and consistency. It also offers automated metrics like Area Median Income (AMI) and Debt to Income (DTI) ratio, enabling quick decision-making. The Habitat App development team and customer service have been exceptional.`,
    organization: {
      name: 'Kenosha',
      logo: 'https://hfh-app-storage-bucket134315-formio.s3.amazonaws.com/public/test/landing/Kenosha_Hz_White.png',
    },
    author: {
      name: 'Angela Elliott',
      title: 'Executive Director',
    },
  },
];

export default testimonials;
