import { Flex, Loader } from '@aws-amplify/ui-react';
import CustomButton from 'components/CustomButton/CustomButton';
import { MdOutlineEdit, MdOutlineMail, MdOutlinePrint } from 'react-icons/md';
import { useTranslation } from 'react-i18next';
import { ApplicationTypes, SubmissionStatus, TestApplication } from 'API';
import DecideModal from './components/DecideModal';
import { TDecideSchema } from '../../AffiliateApplicationDetailsPage.schema';
import ReviseModal from './components/ReviseModal';

interface ButtonsProps {
  application?: TestApplication;
  decideModalOpen: boolean;
  handleDecideModalOnClose: () => void;
  handleOnValidDecide: (data: TDecideSchema) => void;
  handleDecideOnClick: () => void;
  handleDownloadApplicationOnClick: () => void;
  loading: number;
  downloading: boolean;
  reviseOpen: boolean;
  handleClickRevise: () => void;
  handleCreateRevise: () => void;
  handleDisableRevise: () => void;
  handleCloseRevise: () => void;
  hasRevision: boolean;
}

const Buttons = ({
  loading,
  application,
  decideModalOpen,
  handleDecideModalOnClose,
  handleOnValidDecide,
  handleDecideOnClick,
  handleDownloadApplicationOnClick,
  downloading,
  reviseOpen,
  handleClickRevise,
  handleCreateRevise,
  handleDisableRevise,
  handleCloseRevise,
  hasRevision,
}: ButtonsProps) => {
  const { t } = useTranslation();

  const isOnline = application?.type === ApplicationTypes.ONLINE;

  if (!application) return null;
  if (!(application?.submissionStatus === SubmissionStatus.COMPLETED))
    return null;

  return (
    <Flex
      direction={{
        base: 'column',
        medium: 'row',
      }}
      alignItems="end"
      justifyContent="end"
    >
      <ReviseModal
        open={reviseOpen}
        onCreate={handleCreateRevise}
        onClose={handleCloseRevise}
        onDisable={handleDisableRevise}
        hasRevision={hasRevision}
      />
      <DecideModal
        open={decideModalOpen}
        onClose={handleDecideModalOnClose}
        onValid={handleOnValidDecide}
        loading={loading}
        isOnline={isOnline}
      />
      {isOnline && (
        <CustomButton
          icon={<MdOutlineEdit />}
          variation="primary"
          onClick={handleClickRevise}
        >
          {hasRevision
            ? t(
                'pages.habitat.affiliate.cycles.cycle.application.components.buttons.closeRevision'
              )
            : t(
                'pages.habitat.affiliate.cycles.cycle.application.components.buttons.revise'
              )}
        </CustomButton>
      )}

      {isOnline && (
        <CustomButton
          icon={downloading ? <Loader size="large" /> : <MdOutlinePrint />}
          variation="primary"
          onClick={handleDownloadApplicationOnClick}
          disabled={downloading}
        >
          {t(
            'pages.habitat.affiliate.cycles.cycle.application.components.buttons.print'
          )}
        </CustomButton>
      )}
      <CustomButton
        variation="primary"
        onClick={handleDecideOnClick}
        icon={<MdOutlineMail />}
      >
        {t(
          'pages.habitat.affiliate.cycles.cycle.application.components.buttons.decide'
        )}
      </CustomButton>
    </Flex>
  );
};

export default Buttons;
