import startCase from 'lodash.startcase';

export const stringToHumanReadable = (value) => startCase(value.toLowerCase());

export const isJsonString = (stringToEvaluate) => {
  try {
    JSON.parse(stringToEvaluate);
  } catch (e) {
    return false;
  }
  return true;
};

export const chunkSubstr = (str, size) => {
  const numChunks = Math.ceil(str.length / size);
  const chunks = new Array(numChunks);

  for (let i = 0, o = 0; i < numChunks; ++i, o += size) {
    chunks[i] = str.substr(o, size);
  }

  return chunks;
};
