import dayjs from 'dayjs';
import { defer, LoaderFunction } from 'react-router-dom';
import {
  queryAllFormSchemas,
  queryFormSchema,
} from 'services/graphql/FormSchema';
import { queryFormTranslationsByFormSchema } from 'services/graphql/FormTranslations';
import { untilAmplifyFinishConfiguration } from 'utils/amplify';
import { generateComponents } from 'utils/formio';

const getFormsData = async () => {
  await untilAmplifyFinishConfiguration();
  const formSchemas = (await queryAllFormSchemas()).sort((a, b) => {
    const dateA = dayjs(a.createdAt);
    const dateB = dayjs(b.createdAt);

    if (dateB.isBefore(dateA)) {
      return -1;
    }

    if (dateB.isAfter(dateA)) {
      return 1;
    }

    return 0;
  });

  return { formSchemas };
};

export type TFormsData = Awaited<Promise<ReturnType<typeof getFormsData>>>;

const formsLoader: LoaderFunction = async () =>
  defer({ formsData: getFormsData() });

export default formsLoader;

const getNewFormData = async (url: string) => {
  const original = new URL(url).searchParams.get('original');

  if (!original) {
    return null;
  }

  await untilAmplifyFinishConfiguration();

  const formSchema = await queryFormSchema(original);

  if (!formSchema) {
    return null;
  }

  const components = await generateComponents(formSchema.id);

  const formSchemaWithComponents = {
    title: `${formSchema.title} copy`,
    path: `${formSchema.path} copy`,
    name: `${formSchema.name} copy`,
    display: formSchema.display,
    components,
  };

  return { formSchema: formSchemaWithComponents };
};

export type TNewFormData = Awaited<Promise<ReturnType<typeof getNewFormData>>>;

export const newFormLoader: LoaderFunction = async ({ request }) =>
  defer({ newFormData: getNewFormData(request.url) });

const getFormData = async (formId: string | undefined) => {
  if (!formId) {
    return null;
  }

  await untilAmplifyFinishConfiguration();

  const formSchema = await queryFormSchema(formId);

  if (!formSchema) {
    throw new Response('Form not found.', { status: 404 });
  }

  const components = await generateComponents(formSchema.id);

  const formSchemaWithComponents = {
    title: formSchema.title,
    path: formSchema.path,
    name: formSchema.name,
    display: formSchema.display,
    components,
  };

  const translations = await queryFormTranslationsByFormSchema(formId);

  return { formSchema: formSchemaWithComponents, translations };
};

export type TFormData = Awaited<Promise<ReturnType<typeof getFormData>>>;

export const formLoader: LoaderFunction = async ({ params }) => {
  const { formId } = params;

  return defer({ formData: getFormData(formId) });
};
