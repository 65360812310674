import React, { Suspense, useState } from 'react';
import { Await, Link, useLoaderData } from 'react-router-dom';
import { TFormsData } from 'router/loaders/forms';
import { TextField, useBreakpointValue } from '@aws-amplify/ui-react';
import { useTranslation } from 'react-i18next';
import ResultsCounter from 'components/ResultsCounter';
import TableWithPaginator from 'components/TableWithPaginator';
import IconButton from 'components/IconButton';
import {
  MdAdd,
  MdOutlineClose,
  MdOutlineContentCopy,
  MdOutlineOpenInNew,
  MdOutlineSearch,
} from 'react-icons/md';
import CustomButton from 'components/CustomButton';
import { ROUTES } from 'utils/constants';
import styles from './AdminFormsPage.module.css';

const AdminFormsPage = () => {
  const { formsData } = useLoaderData() as {
    formsData: Promise<TFormsData>;
  };
  const titleStyle = useBreakpointValue({
    base: 'theme-subtitle-s1',
    large: 'theme-headline-medium',
  });
  const { t } = useTranslation();
  const [search, setSearch] = useState('');

  return (
    <div className={styles.page}>
      <div>
        <div className={`${styles.titleContainer}`}>
          <span className={`${titleStyle} ${styles.title}`}>
            {t('pages.admin.forms.title')}
          </span>
        </div>
      </div>
      <div>
        <div className={`${styles.resultsAndSearchContainer}`}>
          <div className={`${styles.resultsContainer}`}>
            <span className="theme-subtitle-s2">
              {t('pages.admin.forms.results')}
            </span>
            <Suspense fallback={<ResultsCounter number={10} skeleton />}>
              <Await resolve={formsData}>
                {({ formSchemas }: TFormsData) => (
                  <ResultsCounter number={formSchemas.length} />
                )}
              </Await>
            </Suspense>
          </div>
          <div className={`${styles.buttonContainer}`}>
            <Link to={ROUTES.ADMIN_FORMS_NEW}>
              <CustomButton icon={<MdAdd />}>
                {t('pages.admin.forms.add')}
              </CustomButton>
            </Link>
          </div>
          <div className={styles.searchContainer}>
            <TextField
              label
              labelHidden
              placeholder={t('pages.admin.forms.search')}
              innerStartComponent={
                <div className={styles.search}>
                  <MdOutlineSearch size={32} />
                </div>
              }
              innerEndComponent={
                <div className={`${search ? styles.close : styles.hidden}`}>
                  <IconButton
                    variation="not-outlined"
                    className={styles.closeButton}
                    onClick={() => setSearch('')}
                  >
                    <MdOutlineClose />
                  </IconButton>
                </div>
              }
              className={styles.searchField}
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
        </div>

        <Suspense fallback={<h1>Loading</h1>}>
          <Await resolve={formsData}>
            {({ formSchemas }: TFormsData) => (
              <TableWithPaginator
                headers={[
                  {
                    id: 'name',
                    value: t('pages.admin.forms.table.name'),
                  },
                  {
                    id: 'path',
                    value: t('pages.admin.forms.table.path'),
                  },
                  {
                    id: 'actions',
                    value: t('pages.admin.forms.table.actions'),
                    width: '128px',
                  },
                ]}
                data={formSchemas
                  .filter((form) =>
                    form.name
                      .toLocaleLowerCase()
                      .includes(search.toLocaleLowerCase())
                  )
                  .map((form) => ({
                    id: form.id,
                    cells: [
                      {
                        id: 'name',
                        value: form.name,
                      },
                      {
                        id: 'path',
                        value: form.path,
                      },
                      {
                        id: 'actions',
                        value: (
                          <div className={`${styles.actionsButtonsContainer}`}>
                            <div className={styles.actionButtonContainer}>
                              <Link to={`./new?original=${form.id}`}>
                                <IconButton
                                  className={styles.actionButton}
                                  variation="not-outlined"
                                >
                                  <MdOutlineContentCopy
                                    size="24px"
                                    color="var(--amplify-colors-neutral-90)"
                                  />
                                </IconButton>
                              </Link>
                            </div>
                            <div className={styles.actionButtonContainer}>
                              <Link to={`${form.id}`}>
                                <IconButton
                                  className={styles.actionButton}
                                  variation="not-outlined"
                                >
                                  <MdOutlineOpenInNew
                                    size="24px"
                                    color="var(--amplify-colors-neutral-90)"
                                  />
                                </IconButton>
                              </Link>
                            </div>
                          </div>
                        ),
                      },
                    ],
                  }))}
              />
            )}
          </Await>
        </Suspense>
      </div>
    </div>
  );
};

export default AdminFormsPage;
