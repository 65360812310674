import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { MdInsertDriveFile } from 'react-icons/md';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import CustomButton from 'components/CustomButton/CustomButton';
import { INSERT_FILE_COMMAND } from '../../../FilePlugin';
import pluginStyle from '../../ToolbarPlugin.module.css';
import style from './InsertFileButton.module.css';

const InsertFileButton = () => {
  const inputFile = useRef();
  const [editor] = useLexicalComposerContext();
  const { t } = useTranslation();

  const handleFileChange = (event) => {
    const newFiles = [...event.currentTarget.files];
    if (newFiles.length > 0) {
      editor.dispatchCommand(INSERT_FILE_COMMAND, {
        path: URL.createObjectURL(newFiles[0]),
        name: newFiles[0]?.name,
      });

      inputFile.current.value = '';
    }
  };

  return (
    <>
      <input
        type="file"
        className={style.inputFile}
        multiple={false}
        ref={inputFile}
        onChange={handleFileChange}
      />
      <CustomButton
        variation="text-only"
        onClick={() => {
          inputFile.current.click();
        }}
        aria-label={t(
          'components.lexicalEditor.plugins.ToolbarPlugin.components.InsertFileButton.insertFile'
        )}
        title={t(
          'components.lexicalEditor.plugins.ToolbarPlugin.components.InsertFileButton.insertFile'
        )}
        className={pluginStyle.toolbarButton}
      >
        <MdInsertDriveFile />
      </CustomButton>
    </>
  );
};

export default InsertFileButton;
