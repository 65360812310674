const CustomModule = {
  options: {
    form: {
      evalContext: {
        checkMultiple: (max, row, component) => {
          try {
            const { key } = component;

            const values = row[key];

            if (values) {
              return values.length >= max;
            }
          } catch (error) {
            console.error('Error disabling multiple on file component.', error);
          }

          return false;
        },
        getValueFromPath: (object, path) => {
          const pathArray = path.split('.');
          let value = object;
          for (const key of pathArray) {
            if (
              typeof value === 'object' &&
              value &&
              Object.prototype.hasOwnProperty.call(value, key)
            ) {
              value = value[key];
            }
          }
          return value;
        },
        hfhPrintGetView: (component, data, getView) => {
          switch (component.type) {
            case 'file': {
              return `${data ? data.map((file) => file.name).join(', ') : ''}`;
            }
            default: {
              return getView(component, data);
            }
          }
        },
        setFiltered: (instance) => {
          instance.options.setFiltered && instance.options.setFiltered();
        },
        hfhHelloWorl: () => {
          console.log('Hola mundo!');
        },
      },
    },
    builder: {
      builder: {
        custom: {
          title: 'Custom',
          weight: -2,
          components: {
            cityComponent: {
              title: 'City',
              key: 'city',
              icon: 'map-marker',
              schema: {
                type: 'city',
                key: 'city',
                input: true,
                customClass: 'hfh_formio_select',
                tableView: false,
                clearOnRefresh: true,
                label: 'City',
                validateOn: 'blur',
                validate: {
                  required: true,
                },
              },
            },
          },
        },
        preFab: {
          title: 'Prefabs',
          weight: -1,
          components: {
            preFabTextField: {
              title: 'Text Field',
              key: 'textField',
              icon: 'terminal',
              schema: {
                type: 'textfield',
                customClass: 'hfh_formio_text_input',
                validate: {
                  required: true,
                },
              },
            },
            preFabTextArea: {
              title: 'Text Area',
              key: 'textArea',
              icon: 'font',
              schema: {
                type: 'textarea',
                customClass: 'hfh_formio_text_input',
                validate: {
                  required: true,
                },
              },
            },
            preFabNumber: {
              title: 'Number',
              key: 'preFabNumber',
              icon: 'hashtag',
              schema: {
                type: 'number',
                customClass: 'hfh_formio_number_field',
                validate: {
                  required: true,
                },
              },
            },
            preFabCheckbox: {
              title: 'Checkbox',
              key: 'preFabCheckbox',
              icon: 'check-square',
              schema: {
                type: 'checkbox',
                customClass: 'hfh_formio_checkbox',
                validate: {
                  required: true,
                },
              },
            },
            preFabSelect: {
              title: 'Select',
              key: 'preFabSelect',
              icon: 'th-list',
              schema: {
                type: 'select',
                customClass: 'hfh_formio_select',
                validate: {
                  required: true,
                },
              },
            },
            preFabRadio: {
              title: 'Radio',
              key: 'preFabRadio',
              icon: 'dot-circle-o',
              schema: {
                type: 'radio',
                customClass: 'hfh_formio_radio',
                validate: {
                  required: true,
                },
              },
            },
            preFabPhoneNumber: {
              title: 'Phone Number',
              key: 'preFabPhoneNumber',
              icon: 'phone-square',
              schema: {
                type: 'phoneNumber',
                customClass: 'hfh_formio_text_input',
                validate: {
                  required: true,
                },
              },
            },
            preFabDateTime: {
              title: 'Date / Time',
              key: 'preFabDateTime',
              icon: 'calendar',
              schema: {
                type: 'datetime',
                customClass: 'hfh_formio_date_time',
                validate: {
                  required: true,
                },
              },
            },
            preFabCurrency: {
              title: 'Currency',
              key: 'preFabCurrency',
              icon: 'usd',
              schema: {
                type: 'currency',
                customClass: 'hfh_formio_number_field',
                validate: {
                  required: true,
                },
              },
            },
            preFabEditGrid: {
              title: 'Edit Grid',
              key: 'preFabEditGrid',
              icon: 'tasks',
              schema: {
                type: 'editgrid',
                customClass: 'hfh_formio_edit_grid',
                templates: {
                  header:
                    '<div class="row hfh_formio_hide_on_print">\n  {% util.eachComponent(components, function(component) { %}\n    {% if (displayValue(component)) { %}\n      <div class="col-sm-3">{{ t(component.label) }}</div>\n    {% } %}\n  {% }) %}\n</div>\n<div class="row hfh_formio_show_on_print">\n  <div class="col-12">Records</div>\n</div>',
                  row: '<div class="row hfh_formio_hide_on_print">\n      \n  {% util.eachComponent(components, function(component) { %}\n    {% if (displayValue(component)) { %}\n      <div class="col-sm-3">\n        {{ isVisibleInRow(component) ? getView(component, row[component.key]) : \'\'}}\n      </div>\n    {% } %}\n  {% }) %}\n  {% if (!instance.options.readOnly && !instance.disabled) { %}\n    <div class="col-2">\n      <div class="btn-group pull-right">\n        <button class="btn btn-default btn-light btn-sm editRow"><i class="{{ iconClass(\'edit\') }}"></i></button>\n        {% if (!instance.hasRemoveButtons || instance.hasRemoveButtons()) { %}\n          <button class="btn btn-danger btn-sm removeRow"><i class="{{ iconClass(\'trash\') }}"></i></button>\n        {% } %}\n      </div>\n    </div>\n  {% } else { %}\n    <div class="col-2">\n      <div class="btn-group pull-right">\n        <button class="btn btn-default btn-light btn-sm editRow"><i class="{{ iconClass(\'eye\') }}"></i></button>\n      </div>\n    </div>\n  {% } %}\n</div>\n<div class="row hfh_formio_show_on_print">\n  {% util.eachComponent(components, function(component) { %}\n    <div class="row p-3">\n      <div class="col-12">\n        {{ component.label }}: {{ hfhPrintGetView(component, row[component.key], getView) }}\n      </div>\n    </div>\n  {% }) %}\n</div>',
                },
              },
            },
            preFabFile: {
              title: 'File',
              key: 'preFabFile',
              icon: 'file',
              schema: {
                tableView: false,
                storage: 's3',
                dir: 'formName/componentKey',
                webcam: false,
                customClass: 'hfh_formio_file',
                fileTypes: [
                  {
                    label: '',
                    value: '',
                  },
                ],
                fileMaxSize: '3MB',
                multiple: true,
                validate: {
                  required: true,
                },
                validateWhenHidden: false,
                logic: [
                  {
                    name: 'disable_multiple_on_10',
                    trigger: {
                      type: 'javascript',
                      javascript: 'result = checkMultiple(10, row, component);',
                    },
                    actions: [
                      {
                        name: 'disable_multiple',
                        type: 'mergeComponentSchema',
                        schemaDefinition: 'schema = { multiple: false }',
                      },
                    ],
                  },
                ],
                type: 'file',
                useMultipartUpload: false,
                input: true,
              },
            },
          },
        },
      },
    },
  },
};

export default CustomModule;
