import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Flex, Text, View, useAuthenticator } from '@aws-amplify/ui-react';
import DecisionCard from 'components/DecisionCard';
import useHabitat from 'hooks/utils/useHabitat';
import { Decision, ReviewStatus, TestApplication, TestCycle } from 'API';
import { queryTestCycleByRootForm } from 'services/graphql/TestCycle';
import { queryTestApplicationsByTestCycle } from 'services/graphql/TestApplication';
import { queryDecisionsByTestApplication } from 'services/graphql/Decision';
import { queryRootFormsByHabitat } from 'services/graphql/RootForm';
import Skeleton from 'components/Skeleton';
import style from './ApplicantDecisionsPage.module.css';

type DataProps =
  | {
      decisions: Decision[];
      applications: TestApplication[];
    }
  | undefined;

const ApplicantDecisionsPage = () => {
  const { user } = useAuthenticator((context) => [context.user]);

  const { habitat } = useHabitat();

  const [data, setData] = useState<DataProps>(undefined);

  const [loading, setLoading] = useState(0);

  const { t } = useTranslation();

  useEffect(() => {
    if (habitat) {
      const fetch = async () => {
        try {
          setLoading((prevLoading) => prevLoading + 1);
          const rootFormsResponse = await queryRootFormsByHabitat(habitat.id);

          const cyclesResponsesArray = await Promise.allSettled(
            rootFormsResponse.map((rootForm) =>
              queryTestCycleByRootForm({ rootformID: rootForm.id })
            )
          );

          const cycles = cyclesResponsesArray.reduce(
            (cyclesArray, currentCycleResponse) =>
              currentCycleResponse.status === 'fulfilled'
                ? cyclesArray.concat(
                    currentCycleResponse.value.map((cycle) => ({
                      ...cycle,
                      TestApplications: undefined,
                    }))
                  )
                : cyclesArray,
            [] as TestCycle[]
          );

          const applicationsResponseArray = await Promise.allSettled(
            cycles.map((cycle) =>
              queryTestApplicationsByTestCycle({
                testcycleID: cycle.id,
                filter: {
                  ownerID: { eq: user?.username },
                },
              })
            )
          );

          const applications = applicationsResponseArray.reduce(
            (applicationsArray, currentApplicationResponse) =>
              currentApplicationResponse.status === 'fulfilled'
                ? applicationsArray.concat(
                    currentApplicationResponse.value.map((application) => ({
                      ...application,
                      Decisions: undefined,
                      FormAnswers: undefined,
                      Notes: undefined,
                    }))
                  )
                : applicationsArray,
            [] as TestApplication[]
          );

          const newDecisions = (
            await Promise.allSettled(
              applications.map((application) =>
                queryDecisionsByTestApplication({
                  testapplicationID: application.id,
                })
              )
            )
          )
            .filter((resolved) => resolved.status === 'fulfilled')
            .reduce(
              (array, item) => array.concat(item.value),
              [] as Decision[]
            );

          setData({
            applications,
            decisions: newDecisions.sort((a, b) => {
              if (a.updatedAt && b.updatedAt) {
                return (
                  new Date(b.updatedAt).getTime() -
                  new Date(a.updatedAt).getTime()
                );
              }
              return 0;
            }),
          });
        } catch (error) {
          console.log('Error fetching decisions data.');
        } finally {
          setLoading((prevLoading) => prevLoading - 1);
        }
      };

      fetch();
    }
  }, [habitat, user]);

  return (
    <View padding="32px">
      <Flex className={`${style.cta}`} direction="column" gap="0px">
        <h3 className="theme-headline-medium">
          {t('pages.habitat.applicant.decisions.title')}
        </h3>
        <View className={`theme-body-medium ${style.subtitle}`}>
          <Text color="inherit">
            {t('pages.habitat.applicant.decisions.description')}
          </Text>
        </View>
      </Flex>
      <Flex className={`${style.decisionsContainer}`}>
        {loading > 0 &&
          [0, 1, 2, 3].map((key) => (
            <Skeleton key={key} className={style.skeleton} />
          ))}
        {loading === 0 &&
          (data?.decisions.length ? (
            data?.decisions.map((decision) => (
              <DecisionCard
                key={decision.id}
                date={decision.updatedAt || ''}
                habitat={habitat?.longName || ''}
                status={ReviewStatus[decision?.status || 'PENDING']}
                editorState={decision.serializedEditorState}
                applicationRoute={
                  decision?.status === ReviewStatus.RETURNED
                    ? `../${
                        data.applications.find(
                          (application) =>
                            decision.testapplicationID === application.id
                        )?.testcycleID
                      }`
                    : undefined
                }
              />
            ))
          ) : (
            <View className={`theme-body-medium ${style.subtitle}`}>
              <Text style={{ textAlign: 'center' }} color="inherit">
                {t('pages.habitat.applicant.decisions.empty')}
              </Text>
            </View>
          ))}
      </Flex>
    </View>
  );
};

export default ApplicantDecisionsPage;
