import React, { Suspense, useState } from 'react';
import {
  Await,
  useLoaderData,
  useParams,
  useRevalidator,
} from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import BreadCrumbs from 'components/BreadCrumbs/BreadCrumbs';
import GoBack from 'components/GoBack';
import { Loader, Tabs, useBreakpointValue } from '@aws-amplify/ui-react';
import { TFormData } from 'router/loaders/forms';
import { FormEdit } from '@formio/react';
import { chunkSubstr } from 'utils/strings';
import { queryFormSchema, updateFormSchema } from 'services/graphql/FormSchema';
import {
  deleteComponentSchemaa,
  newComponentSchema,
  queryComponentSchemasByFormSchema,
} from 'services/graphql/ComponentSchema';
import style from './AdminEditFormPage.module.css';
import ErrorModal from './components/ErrorModal/ErrorModal';
import TranslationsTab from './components/TranslationsTab';

const AdminEditFormPage = () => {
  const { revalidate, state: revalidatorState } = useRevalidator();

  const [loading, setLoading] = useState(false);

  const { formId } = useParams();

  const [error, setError] = useState(false);

  const isSmall = useBreakpointValue({
    base: true,
    medium: false,
  });

  const titleStyle = useBreakpointValue({
    base: 'theme-subtitle-s1',
    large: 'theme-headline-medium',
  });

  const { t } = useTranslation();

  const { formData } = useLoaderData() as {
    formData: Promise<TFormData>;
  };

  const breadCrumbsItems = [
    {
      label: t('pages.admin.forms.edit.breadcrumbs.formsDashboard'),
      to: '..',
    },
    {
      label: t('pages.admin.forms.edit.breadcrumbs.newForm'),
    },
  ];

  const handleUpdateForm = async (formSchema: {
    title?: string;
    name?: string;
    display?: string;
    path?: string;
    components: object[];
  }) => {
    try {
      setLoading(true);

      const { title, name, display, path, components } = formSchema;

      if (!title || !name || !path || display === 'pdf') {
        setError(true);
        return;
      }

      const originalForm = await queryFormSchema(formId || '');

      if (!originalForm) {
        throw new Error('Original form not found.');
      }

      const formWithChanges = {
        id: originalForm.id,
        title,
        name,
        path,
        display: display || 'form',
      };

      const updatedForm = await updateFormSchema(formWithChanges);

      const componentsToDelete = await queryComponentSchemasByFormSchema({
        formschemaID: updatedForm.id,
      });

      const componentsToDeletePromisesArray = componentsToDelete.map(
        (componentToDelete) => deleteComponentSchemaa(componentToDelete.id)
      );

      await Promise.allSettled(componentsToDeletePromisesArray);

      const componentsStrings = chunkSubstr(JSON.stringify(components), 10_000);

      let index = 0;

      for (const componentString of componentsStrings) {
        await newComponentSchema({
          formschemaID: formWithChanges.id,
          schema: componentString,
          index,
        });

        index++;
      }

      revalidate();
    } catch (error) {
      console.log('Error while updating the form.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={style.page}>
      <div>
        {!isSmall && <BreadCrumbs items={breadCrumbsItems} />}
        <div className={`${style.titleContainer}`}>
          <GoBack />
          <span className={`${titleStyle} ${style.title}`}>
            {t('pages.admin.forms.edit.title')}
          </span>
        </div>
      </div>
      <ErrorModal open={error} onClickClose={() => setError(false)} />
      {loading && (
        <div className={`${style.loadingContainer}`}>
          {t('pages.admin.forms.edit.updating')} <Loader />
        </div>
      )}
      <div>
        <Suspense
          fallback={
            <div className={`${style.loadingContainer}`}>
              {t('pages.admin.forms.edit.loading')} <Loader />
            </div>
          }
        >
          <Await resolve={formData}>
            {(newFormData: TFormData) => {
              if (revalidatorState === 'loading') {
                return (
                  <div className={`${style.loadingContainer}`}>
                    {t('pages.admin.forms.edit.loading')} <Loader />
                  </div>
                );
              }

              return (
                <Tabs
                  defaultValue="formEditor"
                  className={` ${loading ? style.hidden : ''} `}
                  items={[
                    {
                      label: t('pages.admin.forms.edit.tabs.formEditor'),
                      value: 'formEditor',
                      content: (
                        <div
                          className={`hfh_form_builder ${style.tabContainer}`}
                        >
                          <FormEdit
                            key="formEditor"
                            form={newFormData?.formSchema || {}}
                            saveText={t('pages.admin.forms.edit.update')}
                            saveForm={handleUpdateForm}
                          />
                        </div>
                      ),
                    },
                    {
                      label: t('pages.admin.forms.edit.tabs.translations'),
                      value: 'translation',
                      content: (
                        <div className={`${style.tabContainer}`}>
                          <TranslationsTab
                            formTranslations={newFormData?.translations || []}
                            formSchemaId={formId || ''}
                            setLoading={setLoading}
                            revalidate={revalidate}
                          />
                        </div>
                      ),
                    },
                  ]}
                  isLazy
                />
              );
            }}
          </Await>
        </Suspense>
      </div>
    </div>
  );
};

export default AdminEditFormPage;
