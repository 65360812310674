import {
  CreateTestCycleInput,
  ListTestCyclesQueryVariables,
  TestCyclesByRootformIDQueryVariables,
  UpdateTestCycleInput,
} from 'API';
import { generateClient } from 'aws-amplify/api';
import CustomGraphQlError from 'classes/GraphqlError';
import {
  customCreateTestCycle,
  customUpdateTestCycle,
} from 'myGraphql/myMutations';
import {
  customGetTestCycle,
  customListTestCycles,
  customTestCyclesByRootformID,
} from 'myGraphql/myQueries';

/**
 * Query all the TestCycles of a RootForm that have the _deleted property not equal to true.
 * @param variables The variables passed to the query.
 * @returns An array of RootForms.
 */
export const queryTestCycleByRootForm = async (
  variables: TestCyclesByRootformIDQueryVariables
) => {
  const client = generateClient();

  let nextTokenFlag: string | null | undefined;

  const itemsToReturn = [];

  do {
    const response = await client.graphql({
      query: customTestCyclesByRootformID,
      variables: {
        ...variables,
        nextToken: nextTokenFlag,
      },
    });

    if (response.errors) {
      throw new CustomGraphQlError(
        'Error while querying testCycle by rootForm.',
        response.errors
      );
    }

    const { nextToken, items } = response.data.testCyclesByRootformID;

    nextTokenFlag = nextToken;

    itemsToReturn.push(...items);
  } while (nextTokenFlag);

  return itemsToReturn;
};

/**
 * Query all the TestCycles that have the _deleted property not equal to true.
 * @param variables The variables passed to the query.
 * @returns An array of RootForms.
 */
export const queryAllTestCycles = async (
  variables: ListTestCyclesQueryVariables | undefined = undefined
) => {
  const client = generateClient();

  const itemsToReturn = [];
  let currentNextToken: string | null | undefined;

  do {
    const response = await client.graphql({
      query: customListTestCycles,
      variables: {
        filter: {
          ...(variables ? variables.filter : {}),
        },
        nextToken: currentNextToken,
      },
    });

    if (response.errors) {
      throw new CustomGraphQlError(
        'Error while querying testCycles.',
        response.errors
      );
    }

    const { items, nextToken } = response.data.listTestCycles;

    currentNextToken = nextToken;

    itemsToReturn.push(...items);
  } while (currentNextToken);

  return itemsToReturn;
};

/**
 * Query a TestCycle.
 * @param id The TestCycle id.
 * @returns The TestCycle found or null or undefined.
 */
export const queryTestCycle = async (id: string) => {
  const client = generateClient();

  const response = await client.graphql({
    query: customGetTestCycle,
    variables: {
      id,
    },
  });

  if (response.errors) {
    throw new CustomGraphQlError(
      'Error while querying testCycle.',
      response.errors
    );
  }

  return response.data.getTestCycle;
};

/**
 * Persists a new TestCycle.
 * @param testCycle The TestCycle to persist.
 * @returns The persisted TestCycle.
 */
export const newTestCycle = async (testCycle: CreateTestCycleInput) => {
  const client = generateClient();

  const response = await client.graphql({
    query: customCreateTestCycle,
    variables: {
      input: testCycle,
    },
  });

  if (response.errors) {
    throw new CustomGraphQlError(
      'Error while creating new testCycle.',
      response.errors
    );
  }

  return response.data.createTestCycle;
};

/**
 * Updates a TestCycle.
 * @param input The TestCycle with changes.
 * @returns The updated TestCycle.
 */
export const updateTestCycle = async (input: UpdateTestCycleInput) => {
  const client = generateClient();

  const response = await client.graphql({
    query: customUpdateTestCycle,
    variables: {
      input,
    },
  });

  if (response.errors) {
    throw new CustomGraphQlError(
      'Error while updating testCycle.',
      response.errors
    );
  }

  return response.data.updateTestCycle;
};
