import { Amplify } from 'aws-amplify';

export const untilAmplifyFinishConfiguration = async () =>
  new Promise<void>((resolve, reject) => {
    try {
      if (Object.keys(Amplify.getConfig()).length > 0) {
        resolve();
      }

      const interval = setInterval(() => {
        const config = Amplify.getConfig();
        if (Object.keys(config).length > 0) {
          clearInterval(interval);
          resolve();
        }
      }, 5);
    } catch (error) {
      reject(error);
    }
  });
