import { defer, LoaderFunction } from 'react-router-dom';
import { queryAllFormSchemas } from 'services/graphql/FormSchema';
import { queryFormTranslationsByFormSchema } from 'services/graphql/FormTranslations';
import { untilAmplifyFinishConfiguration } from 'utils/amplify';
import { generateComponents } from 'utils/formio';

const getPrintData = async (url: string) => {
  const path = new URL(url).searchParams.get('path');

  if (!path) {
    return null;
  }

  await untilAmplifyFinishConfiguration();

  const formSchemas = await queryAllFormSchemas({
    filter: { path: { eq: path } },
  });

  if (formSchemas.length === 0) {
    throw new Response('Form schema not found.', { status: 404 });
  }

  const originalFormSchema = formSchemas[0];

  const components = await generateComponents(originalFormSchema.id);

  const formSchema = {
    title: originalFormSchema.title,
    path: originalFormSchema.path,
    name: originalFormSchema.name,
    display: originalFormSchema.display,
    components,
  };

  const translations = await queryFormTranslationsByFormSchema(
    originalFormSchema.id
  );

  return { formSchema, translations };
};

const printLoader: LoaderFunction = async ({ request }) =>
  defer({
    printData: getPrintData(request.url),
  });

export type TPrintData = Awaited<Promise<ReturnType<typeof getPrintData>>>;

export default printLoader;
