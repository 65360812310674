import { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';
import { Amplify } from 'aws-amplify';
import { ThemeProvider } from '@aws-amplify/ui-react';
import { PostHogProvider } from 'posthog-js/react';
import theme from 'styles/theme';
import 'styles';
import 'components/Formio';
import { Formio } from 'formiojs';
import HabitatProvider from 'components/HabitatProvider';
import CustomModule from 'formio/module';
import App from './App';
import amplifyconfig from './amplifyconfiguration.json';

Amplify.configure(amplifyconfig);

Formio.use(CustomModule);

const listener = () => {
  navigator.serviceWorker.register('/request-interceptor.js').then(
    (registration) => {
      console.debug(
        'Service worker registered with scope: ',
        registration.scope
      );
      registration.active?.postMessage({
        formioUploadURL: process.env.REACT_APP_FORMIO_UPLOAD,
      });
    },
    (err) => {
      console.debug('ServiceWorker registration failed: ', err);
    }
  );
};
window.addEventListener('load', listener);

const rootElement = document.getElementById('root');

const options = {
  api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST,
};

if (rootElement) {
  const root = ReactDOM.createRoot(rootElement);
  root.render(
    <StrictMode>
      <ThemeProvider theme={theme}>
        <PostHogProvider
          apiKey={process.env.REACT_APP_PUBLIC_POSTHOG_KEY}
          options={options}
        >
          <HabitatProvider>
            <App />
          </HabitatProvider>
        </PostHogProvider>
      </ThemeProvider>
    </StrictMode>
  );
}
