import React from 'react';
import StatusChip from 'components/StatusChip';
import { convertDateYYYYMMDDtoDDMMYYYY } from 'utils/dates';
import TableWithPaginator from 'components/TableWithPaginator';
import { useTranslation } from 'react-i18next';
import Skeleton from 'components/Skeleton';
import { RootForm, TestApplication, TestCycle } from 'API';
import style from './ApplicationsTab.module.css';

interface IApplicationsTabProps {
  applications: TestApplication[];
  rootForms: RootForm[];
  cycles: TestCycle[];
  skeleton?: boolean;
}

const ApplicationsTab = ({
  applications,
  rootForms,
  cycles,
  skeleton,
}: IApplicationsTabProps) => {
  const { t } = useTranslation();
  return (
    <div className={style.container}>
      {skeleton ? (
        <Skeleton className={style.skeletonTable} />
      ) : (
        <TableWithPaginator
          headers={[
            {
              id: 'rootForm',
              value: t(
                'pages.habitat.affiliate.users.userDetails.components.applicationsTab.table.rootForm'
              ),
            },
            {
              id: 'cycle',
              value: t(
                'pages.habitat.affiliate.users.userDetails.components.applicationsTab.table.cycle'
              ),
            },
            {
              id: 'dateSubmitted',
              value: t(
                'pages.habitat.affiliate.users.userDetails.components.applicationsTab.table.dateSubmitted'
              ),
            },
            {
              id: 'reviewStatus',
              value: t(
                'pages.habitat.affiliate.users.userDetails.components.applicationsTab.table.status'
              ),
            },
          ]}
          data={applications.map((application, index) => {
            const cycle = cycles.find((c) => c.id === application.testcycleID);

            const rootForm = rootForms.find((r) => r.id === cycle?.rootformID);

            return {
              id: index,
              cells: [
                {
                  id: 'rootForm',
                  value: rootForm?.name,
                },
                {
                  id: 'cycle',
                  value: cycle?.name,
                },

                {
                  id: 'dateSubmitted',
                  value: convertDateYYYYMMDDtoDDMMYYYY(
                    application.submittedDate
                  ),
                },
                {
                  id: 'reviewStatus',
                  value: application.reviewStatus && (
                    <div className={`${style.statusContainer}`}>
                      <StatusChip status={application.reviewStatus} />
                    </div>
                  ),
                },
              ],
            };
          })}
        />
      )}
    </div>
  );
};

export default ApplicationsTab;
