import { defer, LoaderFunction } from 'react-router-dom';
import { queryAllHabitats } from 'services/graphql/Habitat';
import { queryReportsByHabitat } from 'services/graphql/Report';
import { queryRootFormsByHabitat } from 'services/graphql/RootForm';
import { queryTestCycleByRootForm } from 'services/graphql/TestCycle';
import { untilAmplifyFinishConfiguration } from 'utils/amplify';

const getReportsData = async (habitat: string | undefined) => {
  await untilAmplifyFinishConfiguration();

  const habitats = await queryAllHabitats({
    filter: { urlName: { eq: habitat } },
  });

  if (habitats.length === 0) {
    throw new Response('Habitat not Found', { status: 404 });
  }

  const reports = await queryReportsByHabitat(habitats[0].id);

  const rootForms = await queryRootFormsByHabitat(habitats[0].id);

  const cyclesResponse = await Promise.allSettled(
    rootForms.map((rootForm) =>
      queryTestCycleByRootForm({ rootformID: rootForm.id })
    )
  );

  const cycles = cyclesResponse
    .map((response) => {
      if (response.status === 'fulfilled') {
        return response.value;
      }
      return [];
    })
    .flat();

  return {
    reports,
    rootForms,
    cycles,
  };
};

export type TReportsData = Awaited<Promise<ReturnType<typeof getReportsData>>>;

const reportsLoader: LoaderFunction = async ({ params }) => {
  const { habitat } = params;

  return defer({ reportsData: getReportsData(habitat) });
};

export default reportsLoader;
