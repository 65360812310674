/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedQuery<InputType, OutputType> = string & {
  __generatedQueryInput: InputType;
  __generatedQueryOutput: OutputType;
};

export const customGetFormTranslation = /* GraphQL */ `query GetFormTranslation($id: ID!) {
  getFormTranslation(id: $id) {
    id
    autoTexts
    manualTexts
    formschemaID
    lang
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetFormTranslationQueryVariables,
  APITypes.GetFormTranslationQuery
>;
export const customListFormTranslations = /* GraphQL */ `query ListFormTranslations(
  $filter: ModelFormTranslationFilterInput
  $limit: Int
  $nextToken: String
) {
  listFormTranslations(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      autoTexts
      manualTexts
      formschemaID
      lang
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListFormTranslationsQueryVariables,
  APITypes.ListFormTranslationsQuery
>;
export const customFormTranslationsByFormschemaID = /* GraphQL */ `query FormTranslationsByFormschemaID(
  $formschemaID: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelFormTranslationFilterInput
  $limit: Int
  $nextToken: String
) {
  formTranslationsByFormschemaID(
    formschemaID: $formschemaID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      autoTexts
      manualTexts
      formschemaID
      lang
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.FormTranslationsByFormschemaIDQueryVariables,
  APITypes.FormTranslationsByFormschemaIDQuery
>;
export const customGetComponentSchema = /* GraphQL */ `query GetComponentSchema($id: ID!) {
  getComponentSchema(id: $id) {
    id
    schema
    formschemaID
    index
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetComponentSchemaQueryVariables,
  APITypes.GetComponentSchemaQuery
>;
export const customListComponentSchemas = /* GraphQL */ `query ListComponentSchemas(
  $filter: ModelComponentSchemaFilterInput
  $limit: Int
  $nextToken: String
) {
  listComponentSchemas(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      schema
      formschemaID
      index
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListComponentSchemasQueryVariables,
  APITypes.ListComponentSchemasQuery
>;
export const customComponentSchemasByFormschemaID = /* GraphQL */ `query ComponentSchemasByFormschemaID(
  $formschemaID: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelComponentSchemaFilterInput
  $limit: Int
  $nextToken: String
) {
  componentSchemasByFormschemaID(
    formschemaID: $formschemaID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      schema
      formschemaID
      index
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ComponentSchemasByFormschemaIDQueryVariables,
  APITypes.ComponentSchemasByFormschemaIDQuery
>;
export const customGetFormSchema = /* GraphQL */ `query GetFormSchema($id: ID!) {
  getFormSchema(id: $id) {
    id
    title
    name
    display
    path
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetFormSchemaQueryVariables,
  APITypes.GetFormSchemaQuery
>;
export const customListFormSchemas = /* GraphQL */ `query ListFormSchemas(
  $filter: ModelFormSchemaFilterInput
  $limit: Int
  $nextToken: String
) {
  listFormSchemas(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      title
      name
      display
      path
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListFormSchemasQueryVariables,
  APITypes.ListFormSchemasQuery
>;
export const customGetReport = /* GraphQL */ `query GetReport($id: ID!) {
  getReport(id: $id) {
    id
    content
    testCycleId
    title
    status
    habitatID
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetReportQueryVariables, APITypes.GetReportQuery>;
export const customListReports = /* GraphQL */ `query ListReports(
  $filter: ModelReportFilterInput
  $limit: Int
  $nextToken: String
) {
  listReports(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      content
      testCycleId
      title
      status
      habitatID
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListReportsQueryVariables,
  APITypes.ListReportsQuery
>;
export const customReportsByHabitatID = /* GraphQL */ `query ReportsByHabitatID(
  $habitatID: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelReportFilterInput
  $limit: Int
  $nextToken: String
) {
  reportsByHabitatID(
    habitatID: $habitatID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      content
      testCycleId
      title
      status
      habitatID
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ReportsByHabitatIDQueryVariables,
  APITypes.ReportsByHabitatIDQuery
>;
export const customGetMaintenance = /* GraphQL */ `query GetMaintenance($id: ID!) {
  getMaintenance(id: $id) {
    id
    maintenance
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetMaintenanceQueryVariables,
  APITypes.GetMaintenanceQuery
>;
export const customListMaintenances = /* GraphQL */ `query ListMaintenances(
  $filter: ModelMaintenanceFilterInput
  $limit: Int
  $nextToken: String
) {
  listMaintenances(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      maintenance
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListMaintenancesQueryVariables,
  APITypes.ListMaintenancesQuery
>;
export const customGetUser = /* GraphQL */ `query GetUser($id: ID!) {
  getUser(id: $id) {
    id
    firstName
    lastName
    dateOfBirth
    sex
    phoneNumber
    affiliateProps {
      titleAtHabitat
      roleDescription
      joinDate
      joinMonth
      joinYear
      __typename
    }
    applicantProps {
      state
      city
      street
      householdMembersNumber
      householdAnnualIncome
      currentlyUnemployed
      currentWorkTitle
      nameOfEmployer
      howDidYouHearAbout
      firstTimeApplying
      whatAreYouInterestedIn
      __typename
    }
    type
    owner
    verified
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetUserQueryVariables, APITypes.GetUserQuery>;
export const customListUsers = /* GraphQL */ `query ListUsers(
  $filter: ModelUserFilterInput
  $limit: Int
  $nextToken: String
) {
  listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      firstName
      lastName
      dateOfBirth
      sex
      phoneNumber
      affiliateProps {
        titleAtHabitat
        roleDescription
        joinDate
        joinMonth
        joinYear
        __typename
      }
      applicantProps {
        state
        city
        street
        householdMembersNumber
        householdAnnualIncome
        currentlyUnemployed
        currentWorkTitle
        nameOfEmployer
        howDidYouHearAbout
        firstTimeApplying
        whatAreYouInterestedIn
        __typename
      }
      type
      owner
      verified
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<APITypes.ListUsersQueryVariables, APITypes.ListUsersQuery>;
export const customGetRootForm = /* GraphQL */ `query GetRootForm($id: ID!) {
  getRootForm(id: $id) {
    id
    name
    status
    description
    files
    habitatID
    formUrls
    supportQuestion {
      en
      es
      __typename
    }
    confirmSubmissionText
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetRootFormQueryVariables,
  APITypes.GetRootFormQuery
>;
export const customListRootForms = /* GraphQL */ `query ListRootForms(
  $filter: ModelRootFormFilterInput
  $limit: Int
  $nextToken: String
) {
  listRootForms(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      status
      description
      files
      habitatID
      formUrls
      supportQuestion {
        en
        es
        __typename
      }
      confirmSubmissionText
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListRootFormsQueryVariables,
  APITypes.ListRootFormsQuery
>;
export const customRootFormsByHabitatID = /* GraphQL */ `query RootFormsByHabitatID(
  $habitatID: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelRootFormFilterInput
  $limit: Int
  $nextToken: String
) {
  rootFormsByHabitatID(
    habitatID: $habitatID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      name
      status
      description
      files
      habitatID
      formUrls
      supportQuestion {
        en
        es
        __typename
      }
      confirmSubmissionText
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.RootFormsByHabitatIDQueryVariables,
  APITypes.RootFormsByHabitatIDQuery
>;
export const customGetDecision = /* GraphQL */ `query GetDecision($id: ID!) {
  getDecision(id: $id) {
    id
    status
    serializedEditorState
    testapplicationID
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetDecisionQueryVariables,
  APITypes.GetDecisionQuery
>;
export const customListDecisions = /* GraphQL */ `query ListDecisions(
  $filter: ModelDecisionFilterInput
  $limit: Int
  $nextToken: String
) {
  listDecisions(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      status
      serializedEditorState
      testapplicationID
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListDecisionsQueryVariables,
  APITypes.ListDecisionsQuery
>;
export const customDecisionsByTestapplicationID = /* GraphQL */ `query DecisionsByTestapplicationID(
  $testapplicationID: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelDecisionFilterInput
  $limit: Int
  $nextToken: String
) {
  decisionsByTestapplicationID(
    testapplicationID: $testapplicationID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      status
      serializedEditorState
      testapplicationID
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.DecisionsByTestapplicationIDQueryVariables,
  APITypes.DecisionsByTestapplicationIDQuery
>;
export const customGetFormAnswer = /* GraphQL */ `query GetFormAnswer($id: ID!) {
  getFormAnswer(id: $id) {
    id
    values
    page
    section
    testapplicationID
    isCopy
    version
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetFormAnswerQueryVariables,
  APITypes.GetFormAnswerQuery
>;
export const customListFormAnswers = /* GraphQL */ `query ListFormAnswers(
  $filter: ModelFormAnswerFilterInput
  $limit: Int
  $nextToken: String
) {
  listFormAnswers(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      values
      page
      section
      testapplicationID
      isCopy
      version
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListFormAnswersQueryVariables,
  APITypes.ListFormAnswersQuery
>;
export const customFormAnswersByTestapplicationID = /* GraphQL */ `query FormAnswersByTestapplicationID(
  $testapplicationID: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelFormAnswerFilterInput
  $limit: Int
  $nextToken: String
) {
  formAnswersByTestapplicationID(
    testapplicationID: $testapplicationID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      values
      page
      section
      testapplicationID
      isCopy
      version
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.FormAnswersByTestapplicationIDQueryVariables,
  APITypes.FormAnswersByTestapplicationIDQuery
>;
export const customGetNote = /* GraphQL */ `query GetNote($id: ID!) {
  getNote(id: $id) {
    id
    serializedEditorState
    ownerID
    testapplicationID
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetNoteQueryVariables, APITypes.GetNoteQuery>;
export const customListNotes = /* GraphQL */ `query ListNotes(
  $filter: ModelNoteFilterInput
  $limit: Int
  $nextToken: String
) {
  listNotes(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      serializedEditorState
      ownerID
      testapplicationID
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<APITypes.ListNotesQueryVariables, APITypes.ListNotesQuery>;
export const customNotesByTestapplicationID = /* GraphQL */ `query NotesByTestapplicationID(
  $testapplicationID: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelNoteFilterInput
  $limit: Int
  $nextToken: String
) {
  notesByTestapplicationID(
    testapplicationID: $testapplicationID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      serializedEditorState
      ownerID
      testapplicationID
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.NotesByTestapplicationIDQueryVariables,
  APITypes.NotesByTestapplicationIDQuery
>;
export const customGetTestCycle = /* GraphQL */ `query GetTestCycle($id: ID!) {
  getTestCycle(id: $id) {
    id
    startDate
    endDate
    isOpen
    props
    rootformID
    name
    closedCycleMessage
    formUrl
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetTestCycleQueryVariables,
  APITypes.GetTestCycleQuery
>;
export const customListTestCycles = /* GraphQL */ `query ListTestCycles(
  $filter: ModelTestCycleFilterInput
  $limit: Int
  $nextToken: String
) {
  listTestCycles(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      startDate
      endDate
      isOpen
      props
      rootformID
      name
      closedCycleMessage
      formUrl
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListTestCyclesQueryVariables,
  APITypes.ListTestCyclesQuery
>;
export const customTestCyclesByRootformID = /* GraphQL */ `query TestCyclesByRootformID(
  $rootformID: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelTestCycleFilterInput
  $limit: Int
  $nextToken: String
) {
  testCyclesByRootformID(
    rootformID: $rootformID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      startDate
      endDate
      isOpen
      props
      rootformID
      name
      closedCycleMessage
      formUrl
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.TestCyclesByRootformIDQueryVariables,
  APITypes.TestCyclesByRootformIDQuery
>;
export const customGetTestApplication = /* GraphQL */ `query GetTestApplication($id: ID!) {
  getTestApplication(id: $id) {
    id
    ownerID
    lastSection
    submittedDate
    reviewStatus
    submissionStatus
    props
    type
    testcycleID
    customStatus
    lastPage
    hasRevision
    filtered
    version
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetTestApplicationQueryVariables,
  APITypes.GetTestApplicationQuery
>;
export const customListTestApplications = /* GraphQL */ `query ListTestApplications(
  $filter: ModelTestApplicationFilterInput
  $limit: Int
  $nextToken: String
) {
  listTestApplications(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      ownerID
      lastSection
      submittedDate
      reviewStatus
      submissionStatus
      props
      type
      testcycleID
      customStatus
      lastPage
      hasRevision
      filtered
      version
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListTestApplicationsQueryVariables,
  APITypes.ListTestApplicationsQuery
>;
export const customTestApplicationsByTestcycleID = /* GraphQL */ `query TestApplicationsByTestcycleID(
  $testcycleID: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelTestApplicationFilterInput
  $limit: Int
  $nextToken: String
) {
  testApplicationsByTestcycleID(
    testcycleID: $testcycleID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      ownerID
      lastSection
      submittedDate
      reviewStatus
      submissionStatus
      props
      type
      testcycleID
      customStatus
      lastPage
      hasRevision
      filtered
      version
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.TestApplicationsByTestcycleIDQueryVariables,
  APITypes.TestApplicationsByTestcycleIDQuery
>;
export const customGetHabitat = /* GraphQL */ `query GetHabitat($id: ID!) {
  getHabitat(id: $id) {
    id
    name
    longName
    state
    city
    props {
      customStatus
      gallery {
        id
        image
        title
        message
        __typename
      }
      sidebarName {
        name
        fontSize
        __typename
      }
      closedCycleMessages
      __typename
    }
    users
    authenticationHeader
    urlName
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetHabitatQueryVariables,
  APITypes.GetHabitatQuery
>;
export const customListHabitats = /* GraphQL */ `query ListHabitats(
  $filter: ModelHabitatFilterInput
  $limit: Int
  $nextToken: String
) {
  listHabitats(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      longName
      state
      city
      props {
        customStatus
        closedCycleMessages
        __typename
      }
      users
      authenticationHeader
      urlName
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListHabitatsQueryVariables,
  APITypes.ListHabitatsQuery
>;
