import { defer, LoaderFunction } from 'react-router-dom';
import { queryRootForm } from 'services/graphql/RootForm';
import { untilAmplifyFinishConfiguration } from 'utils/amplify';

const getRootFormData = async (formId: string | undefined) => {
  if (!formId) {
    throw new Response('Form not specified.', { status: 400 });
  }

  await untilAmplifyFinishConfiguration();

  const rootForm = await queryRootForm(formId);

  if (!rootForm) {
    throw new Response('Root form not found.', { status: 404 });
  }

  return { rootForm };
};

export type TRootFormData = Awaited<
  Promise<ReturnType<typeof getRootFormData>>
>;

const rootFormLoader: LoaderFunction = async ({ params }) => {
  const { formId } = params;

  return defer({ rootFormData: getRootFormData(formId) });
};

export default rootFormLoader;
