import { defer, LoaderFunction } from 'react-router-dom';
import { queryAllFormSchemas } from 'services/graphql/FormSchema';
import { queryFormTranslationsByFormSchema } from 'services/graphql/FormTranslations';
import { queryRootForm } from 'services/graphql/RootForm';
import { queryTestCycle } from 'services/graphql/TestCycle';
import { untilAmplifyFinishConfiguration } from 'utils/amplify';
import { generateComponents } from 'utils/formio';

const getCycleData = async (cycleId: string | undefined) => {
  if (!cycleId) {
    throw new Response('Cycle not specified.', { status: 400 });
  }

  await untilAmplifyFinishConfiguration();

  const cycle = await queryTestCycle(cycleId);

  if (!cycle) {
    throw new Response('Cycle not found.', { status: 404 });
  }

  const formSchemas = await queryAllFormSchemas({
    filter: {
      path: { eq: cycle.formUrl },
    },
  });

  if (formSchemas.length === 0) {
    throw new Response('Form schema not found.', { status: 404 });
  }

  const originalFormSchema = formSchemas[0];

  const components = await generateComponents(originalFormSchema.id);

  const formSchema = {
    title: originalFormSchema.title,
    path: originalFormSchema.path,
    name: originalFormSchema.name,
    display: originalFormSchema.display,
    components,
  };

  const translations = await queryFormTranslationsByFormSchema(
    originalFormSchema.id
  );

  return { cycle, formSchema, translations };
};

const cycleLoader: LoaderFunction = async ({ params }) => {
  const { cycleId } = params;

  return defer({
    cycleData: getCycleData(cycleId),
  });
};

export type TCycleData = Awaited<Promise<ReturnType<typeof getCycleData>>>;

const getCycleWithRootFormData = async (cycleId: string | undefined) => {
  if (!cycleId) {
    throw new Response('Cycle not specified.', { status: 400 });
  }

  await untilAmplifyFinishConfiguration();

  const cycle = await queryTestCycle(cycleId);

  if (!cycle) {
    throw new Response('Cycle not found.', { status: 404 });
  }

  const formSchemas = await queryAllFormSchemas({
    filter: { path: { eq: cycle.formUrl } },
  });

  if (formSchemas.length === 0) {
    throw new Response('Form schema not found.', { status: 404 });
  }

  const originalFormSchema = formSchemas[0];

  const components = await generateComponents(originalFormSchema.id);

  const formSchema = {
    title: originalFormSchema.title,
    path: originalFormSchema.path,
    name: originalFormSchema.name,
    display: originalFormSchema.display,
    components,
  };

  const translations = await queryFormTranslationsByFormSchema(
    originalFormSchema.id
  );

  const rootForm = await queryRootForm(cycle.rootformID);

  if (!rootForm) {
    throw new Response('RootForm not found.', { status: 404 });
  }

  return { cycle, rootForm, formSchema, translations };
};

export type TCycleWithRootFormData = Awaited<
  Promise<ReturnType<typeof getCycleWithRootFormData>>
>;

export const cycleWithRootFormLoader: LoaderFunction = async ({ params }) => {
  const { cycleId } = params;

  return defer({
    cycleWithRootFormData: getCycleWithRootFormData(cycleId),
  });
};

export default cycleLoader;
