import { useCallback } from 'react';
import { Outlet } from 'react-router-dom';
import { Text, useAuthenticator } from '@aws-amplify/ui-react';
import Authentication from 'components/Authentication';
import useAsync from 'hooks/utils/useAsync';
import BaseLayout from 'layouts/BaseLayout';
import { AUTHENTICATION_STATUS } from 'utils/constants';
import { Status } from 'utils/enums';
import { queryAllUsers } from 'services/graphql/User';
import { UserTypes } from 'API';
import LoadingOverlay from 'components/LoadingOverlay';
import style from './AdminLayout.module.css';

const AdminLayout = () => {
  const { authStatus, user } = useAuthenticator((context) => [
    context.authStatus,
    context.user,
  ]);

  const getData = useCallback(async () => {
    try {
      if (user) {
        const users = await queryAllUsers({
          filter: { owner: { eq: user.username } },
        });

        return {
          userData: users[0],
        };
      }
    } catch (error) {
      console.log('Error fetching user data.', error);
    }
  }, [user]);

  const { value, status } = useAsync({
    asyncFunction: getData,
  });

  if (
    authStatus === AUTHENTICATION_STATUS.CONFIGURING ||
    status === Status.PENDING
  ) {
    return <LoadingOverlay />;
  }

  if (authStatus !== AUTHENTICATION_STATUS.AUTHENTICATED) {
    return <Authentication type="admin" />;
  }

  if (!value || !user) {
    return <LoadingOverlay />;
  }

  const { userData } = value;

  const userAllow = userData.type === UserTypes.ADMIN;

  if (!userAllow) {
    return (
      <div>
        <div className={style.notAllowedContainer}>
          <Text>You don't have authorization to access this page.</Text>
        </div>
      </div>
    );
  }

  return (
    <div>
      <BaseLayout variation="admin" hideSideBar={!userAllow}>
        <Outlet />
      </BaseLayout>
    </div>
  );
};

export default AdminLayout;
