import { FormAnswer } from 'API';
import { queryComponentSchemasByFormSchema } from 'services/graphql/ComponentSchema';

interface ISubmission {
  data: {
    [key: string]: unknown;
  };
}

export const generateSubmission = (
  formAnswers: FormAnswer[],
  version: number | null | undefined,
  isRevision = false
): ISubmission => {
  const submissionData: ISubmission['data'] = {};

  const copies = formAnswers
    .filter((formAnswer) => formAnswer.isCopy)
    .sort((a, b) => (a.version || 0) - (b.version || 0));

  const originals = formAnswers
    .filter((formAnswer) => !formAnswer.isCopy)
    .sort((a, b) => (a.version || 0) - (b.version || 0));

  for (const formAnswer of originals) {
    if (formAnswer.page && formAnswer.values) {
      if (
        isRevision &&
        copies.map((copy) => copy.page).includes(formAnswer.page)
      ) {
        const revision = copies.find((copy) => copy.page === formAnswer.page);

        if (
          revision &&
          revision.values &&
          (revision.version || 0) <= (version || 0)
        ) {
          submissionData[formAnswer.page] = JSON.parse(revision.values);
        }
      } else if ((formAnswer.version || 0) <= (version || 0)) {
        submissionData[formAnswer.page] = JSON.parse(formAnswer.values);
      }
    }
  }

  const submission = {
    data: submissionData,
  };

  return submission;
};

export const generateComponents = async (formSchemaId: string) => {
  const components = await queryComponentSchemasByFormSchema({
    formschemaID: formSchemaId,
  });

  return JSON.parse(
    components
      .sort((a, b) => (a?.index || 0) - (b?.index || 0))
      .map((component) => component?.schema || '')
      .join('')
  );
};
