import { CreateRootFormInput, UpdateRootFormInput } from 'API';
import { generateClient } from 'aws-amplify/api';
import CustomGraphQlError from 'classes/GraphqlError';
import {
  customCreateRootForm,
  customUpdateRootForm,
} from 'myGraphql/myMutations';
import {
  customGetRootForm,
  customRootFormsByHabitatID,
} from 'myGraphql/myQueries';

/**
 * Query all the RootForms of a Habitat that have the _deleted property not equal to true.
 * @param habitatId The Habitat id.
 * @returns An array of RootForms.
 */
export const queryRootFormsByHabitat = async (habitatId: string) => {
  const client = generateClient();

  let nextTokenFlag: string | null | undefined;

  const itemsToReturn = [];

  do {
    const response = await client.graphql({
      query: customRootFormsByHabitatID,
      variables: {
        habitatID: habitatId,
        nextToken: nextTokenFlag,
      },
    });

    if (response.errors) {
      throw new CustomGraphQlError(
        'Error while querying rootForms by habitat.',
        response.errors
      );
    }

    const { nextToken, items } = response.data.rootFormsByHabitatID;

    nextTokenFlag = nextToken;

    itemsToReturn.push(...items);
  } while (nextTokenFlag);

  return itemsToReturn;
};

/**
 * Query a RootForm.
 * @param id The RootForm id.
 * @returns The RootForm found or null or undefined.
 */
export const queryRootForm = async (id: string) => {
  const client = generateClient();

  const response = await client.graphql({
    query: customGetRootForm,
    variables: {
      id,
    },
  });

  if (response.errors) {
    throw new CustomGraphQlError(
      'Error while querying rootForm.',
      response.errors
    );
  }

  return response.data.getRootForm;
};

/**
 * Persists a new RootForm.
 * @param note The RootForm to persist.
 * @returns The persisted RootForm.
 */
export const newRootForm = async (note: CreateRootFormInput) => {
  const client = generateClient();

  const response = await client.graphql({
    query: customCreateRootForm,
    variables: {
      input: note,
    },
  });

  if (response.errors) {
    throw new CustomGraphQlError(
      'Error while creating new rootForm.',
      response.errors
    );
  }

  return response.data.createRootForm;
};

/**
 * Updates a RootForm.
 * @param input The RootForm with changes.
 * @returns The updated RootForm.
 */
export const updateRootForm = async (input: UpdateRootFormInput) => {
  const client = generateClient();

  const response = await client.graphql({
    query: customUpdateRootForm,
    variables: {
      input,
    },
  });

  if (response.errors) {
    throw new CustomGraphQlError(
      'Error while updating rootForm.',
      response.errors
    );
  }

  return response.data.updateRootForm;
};
