import { useCallback, useEffect, useState } from 'react';
import { Form as FormioForm, Wizard } from '@formio/react';
import { Link, useNavigate } from 'react-router-dom';
import { generateSubmission } from 'utils/formio';
import { Options } from '@formio/react/lib/components/Form';
import Modal from 'components/Modal';
import dayjs from 'dayjs';
import { usePostHog } from 'posthog-js/react';
import { Button, Flex, Text } from '@aws-amplify/ui-react';
import CustomButton from 'components/CustomButton/CustomButton';
import { useTranslation } from 'react-i18next';
import useAsync from 'hooks/utils/useAsync/useAsync';
import { Status } from 'utils/enums';
import useHabitat from 'hooks/utils/useHabitat';
import FormLayout from 'components/FormLayout';
import { convertTranlationsToObject } from 'utils/translations';
import { queryFormAnswersByTestApplication } from 'services/graphql/FormAnswer';
import {
  queryTestApplication,
  updateTestApplication,
} from 'services/graphql/TestApplication';
import { ReviewStatus, SubmissionStatus } from 'API';
import FormProps, { DataProps, DISPLAY, ERROR } from './Form.types';
import uploadSubmission from './services/uploadSubmission';
import style from './Form.module.css';

const Form = ({
  application,
  cycle,
  rootForm,
  formContainer = true,
  formSchema,
  serializedHelpContent,
  translations,
}: FormProps) => {
  const { i18n } = useTranslation();
  const { t } = useTranslation();
  const { habitat } = useHabitat();
  const [reviewSubmission, setReviewSubmission] = useState<unknown>(undefined);
  const [triggerRefetch, setTriggerRefetch] = useState(false);
  const [formReady, setFormReady] = useState<typeof Wizard>();
  const posthog = usePostHog();
  const navigate = useNavigate();
  const [showSubmitModal, setShowSubmitModal] = useState(false);
  const [redirectTimer, setRedirectTimer] = useState<number>();

  const { language } = i18n;

  const getData = useCallback(async (): Promise<DataProps> => {
    try {
      if (cycle?.rootformID === undefined)
        return {
          display: DISPLAY.ERROR,
          data: {
            error: ERROR.CYCLE_NOT_FOUND,
          },
        };

      const formAnswers = await queryFormAnswersByTestApplication({
        testapplicationID: application?.id || '',
      });

      if (!rootForm) {
        return {
          display: DISPLAY.ERROR,
          data: {
            error: ERROR.CYCLE_NOT_FOUND,
          },
        };
      }

      return {
        display: DISPLAY.APPLICATION,
        data: {
          formAnswers,
        },
      };
    } catch (error) {
      return {
        display: DISPLAY.ERROR,
        data: {
          error: ERROR.UNEXPECTED_ERROR,
        },
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    cycle?.rootformID,
    cycle?.formUrl,
    language,
    application?.id,
    triggerRefetch,
  ]);

  const { value, status } = useAsync({
    asyncFunction: getData,
  });

  const handleOnReview = async (submission: unknown) => {
    try {
      if (application && cycle) {
        await uploadSubmission({ submission, application });
        setReviewSubmission(submission);
      }
    } catch (error) {
      console.log('Error updating application');
    }
  };

  const handleOnSubmit = async () => {
    try {
      if (application && cycle) {
        const original = await queryTestApplication(application.id);

        if (original) {
          posthog?.capture('application_submitted', {
            application,
            cycle,
            habitat,
          });
          posthog?.capture('application_pending', {
            application,
            habitat,
            cycle,
          });

          await updateTestApplication({
            id: original.id,
            submissionStatus: SubmissionStatus.COMPLETED,
            reviewStatus: ReviewStatus.PENDING,
            submittedDate: dayjs().format('YYYY-MM-DD'),
            filtered: false,
          });

          setRedirectTimer(10);
          setInterval(
            () =>
              setRedirectTimer(
                (prevRedirectTimer) => (prevRedirectTimer || 0) - 1
              ),
            1000
          );
        }
      }
    } catch (error) {
      console.log('Error updating application');
    }
  };

  const handleOnClickGoBack = async () => {
    if (application) {
      const currentApplication = await queryTestApplication(application.id);

      if (currentApplication) {
        await updateTestApplication({
          id: currentApplication.id,
          lastPage: 0,
        });
      }
    }

    setTriggerRefetch((prevTriggerRefetch) => !prevTriggerRefetch);
    setReviewSubmission(undefined);
  };

  const handleOnClickSubmit = () => {
    setShowSubmitModal(true);
  };

  const handleOnClickSubmitModalClose = () => {
    setShowSubmitModal(false);
  };

  const handleFormReady = (f: typeof Wizard) => {
    setFormReady(f);
  };

  const handleNextPage = ({
    submission,
    page,
  }: {
    submission: unknown;
    page: number;
  }) => {
    uploadSubmission({
      submission,
      application,
      nextPage: page,
    });
  };

  const setFiltered = async () => {
    try {
      const originalApplication = await queryTestApplication(
        application?.id || ''
      );

      if (!originalApplication || originalApplication.filtered) {
        return;
      }

      await updateTestApplication({
        id: originalApplication.id,
        filtered: true,
      });
    } catch (error) {
      console.log('Error updating application');
    }
  };

  useEffect(() => {
    if (redirectTimer === 0) {
      navigate('../applications');
    }
  }, [navigate, redirectTimer]);

  if (status === Status.PENDING || !value) {
    return null;
  }

  if (status === Status.REJECTED) {
    return <div>Error</div>;
  }

  if (value.display === DISPLAY.ERROR || !habitat || !cycle || !application) {
    return <div>Error</div>;
  }

  const submission = generateSubmission(
    value.data.formAnswers || [],
    application.version
  );

  if (
    reviewSubmission ||
    application?.submissionStatus === SubmissionStatus.COMPLETED
  ) {
    const reviewOptions = {
      readOnly: true,
      renderMode: 'flat',
      language: translations.length === 0 ? 'en' : language,
      i18n: convertTranlationsToObject(translations),
    } as Options;

    return (
      <div style={{ padding: 0 }}>
        <div>
          <div
            className={
              formContainer
                ? style.formContainer
                : `${style.formContainer} ${style.newpadding}`
            }
          >
            <p className="theme-body-medium">
              {t('pages.habitat.applicant.cycle.components.form.reviewMessage')}
            </p>
            <br />
            <FormioForm
              key={`review-${language}`}
              form={formSchema}
              options={reviewOptions}
              submission={reviewSubmission || submission}
            />

            <Modal
              title={t(
                'pages.habitat.applicant.cycle.components.form.alert.title'
              )}
              width={{ base: '95%', medium: '30rem' }}
              open={showSubmitModal}
              onClickClose={handleOnClickSubmitModalClose}
            >
              <Text>
                {t(
                  'pages.habitat.applicant.cycle.components.form.alert.message'
                )}
              </Text>
              <br />
              <Flex width="100%" justifyContent="end">
                <Button variation="primary" onClick={handleOnSubmit}>
                  {t(
                    'pages.habitat.applicant.cycle.components.form.alert.accept'
                  )}
                </Button>
                <Button onClick={handleOnClickSubmitModalClose}>
                  {t(
                    'pages.habitat.applicant.cycle.components.form.alert.cancel'
                  )}
                </Button>
              </Flex>
            </Modal>
            <Modal
              title={t(
                'pages.habitat.applicant.cycle.components.form.redirect.title'
              )}
              width={{ base: '95%', medium: '30rem' }}
              open={Boolean(redirectTimer)}
              onClickClose={() => undefined}
            >
              <Text>
                {t(
                  'pages.habitat.applicant.cycle.components.form.redirect.messages.0'
                )}
              </Text>
              <br />
              <span className={`theme-body-small ${style.redirect}`}>
                {t(
                  'pages.habitat.applicant.cycle.components.form.redirect.messages.1',
                  {
                    time: redirectTimer,
                  }
                )}
              </span>
            </Modal>
            <Flex justifyContent="space-between">
              {application?.submissionStatus !== SubmissionStatus.COMPLETED ? (
                <>
                  <CustomButton
                    onClick={handleOnClickGoBack}
                    variation="secondary"
                  >
                    {t(
                      'pages.habitat.applicant.cycle.components.form.goBackToEdit'
                    )}
                  </CustomButton>
                  <CustomButton
                    onClick={handleOnClickSubmit}
                    variation="primary"
                  >
                    {t('pages.habitat.applicant.cycle.components.form.submit')}
                  </CustomButton>
                </>
              ) : (
                <Link to="../applications">
                  <CustomButton variation="primary">
                    {t('pages.habitat.applicant.cycle.components.form.goBack')}
                  </CustomButton>
                </Link>
              )}
            </Flex>
          </div>
        </div>
      </div>
    );
  }

  const options = {
    additional: {
      application,
      habitat,
      openCycle: cycle,
    },
    language: translations.length === 0 ? 'en' : language,
    i18n: convertTranlationsToObject(translations),
    setFiltered,
  } as Options;

  return (
    <div style={{ padding: 0 }}>
      <div>
        <FormLayout
          formReady={formReady}
          application={application}
          cycle={cycle}
          serializedHelpContent={serializedHelpContent}
        >
          <div
            className={`${style.formContainer}`}
            style={{ padding: '2rem 1rem' }}
          >
            <FormioForm
              key={`real-${language}`}
              form={formSchema}
              onSubmit={handleOnReview}
              options={options}
              submission={submission}
              onNextPage={handleNextPage}
              formReady={handleFormReady}
            />
          </div>
        </FormLayout>
      </div>
    </div>
  );
};

export default Form;
