import React from 'react';
import { useTranslation } from 'react-i18next';
import Skeleton from 'components/Skeleton';
import styles from './ResultsCounter.module.css';

interface IResultsCounterProps {
  number: number;
  skeleton?: boolean;
}

const ResultsCounter = ({ number, skeleton }: IResultsCounterProps) => {
  const { t } = useTranslation();
  return (
    <span className={styles.text}>
      {skeleton ? (
        <Skeleton variation="text" numOfCharacters={8} />
      ) : (
        `${number} ${t('components.resultsCounter.results')}`
      )}
    </span>
  );
};

export default ResultsCounter;
