import React, { Suspense, useState } from 'react';
import { Await, useLoaderData, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import BreadCrumbs from 'components/BreadCrumbs/BreadCrumbs';
import GoBack from 'components/GoBack';
import { Loader, useBreakpointValue } from '@aws-amplify/ui-react';
import { TNewFormData } from 'router/loaders/forms';
import { FormEdit } from '@formio/react';
import { chunkSubstr } from 'utils/strings';
import { newFormSchema } from 'services/graphql/FormSchema';
import { newComponentSchema } from 'services/graphql/ComponentSchema';
import style from './AdminNewFormPage.module.css';
import ErrorModal from './components/ErrorModal/ErrorModal';

const AdminNewFormPage = () => {
  const [loading, setLoading] = useState(false);

  const [error, setError] = useState(false);

  const isSmall = useBreakpointValue({
    base: true,
    medium: false,
  });

  const titleStyle = useBreakpointValue({
    base: 'theme-subtitle-s1',
    large: 'theme-headline-medium',
  });

  const { t } = useTranslation();

  const { newFormData } = useLoaderData() as {
    newFormData: Promise<TNewFormData>;
  };

  const navigate = useNavigate();

  const breadCrumbsItems = [
    {
      label: t('pages.admin.forms.new.breadcrumbs.formsDashboard'),
      to: '..',
    },
    {
      label: t('pages.admin.forms.new.breadcrumbs.newForm'),
    },
  ];

  const handleSaveForm = async (formSchema: {
    title?: string;
    name?: string;
    display?: string;
    path?: string;
    components: object[];
  }) => {
    try {
      setLoading(true);

      const { title, name, display, path, components } = formSchema;

      if (!title || !name || !path || display === 'pdf') {
        setError(true);
        return;
      }

      const newForm = await newFormSchema({
        title,
        path,
        name,
        display: display || 'form',
      });

      const componentsStrings = chunkSubstr(JSON.stringify(components), 10_000);

      let index = 0;
      for (const componentString of componentsStrings) {
        await newComponentSchema({
          formschemaID: newForm.id,
          schema: componentString,
          index,
        });

        index++;
      }

      navigate(`../${newForm.id}`);
    } catch (error) {
      console.log('Error while saving the form.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={style.page}>
      <div>
        {!isSmall && <BreadCrumbs items={breadCrumbsItems} />}
        <div className={`${style.titleContainer}`}>
          <GoBack />
          <span className={`${titleStyle} ${style.title}`}>
            {t('pages.admin.forms.new.title')}
          </span>
        </div>
      </div>
      <ErrorModal open={error} onClickClose={() => setError(false)} />
      {loading && (
        <div className={`${style.loadingContainer}`}>
          {t('pages.admin.forms.new.saving')} <Loader />
        </div>
      )}
      <div>
        <Suspense
          fallback={
            <div className={`${style.loadingContainer}`}>
              {t('pages.admin.forms.new.loading')} <Loader />
            </div>
          }
        >
          <Await resolve={newFormData}>
            {(newFormData: TNewFormData) => (
              <div
                className={`hfh_form_builder ${loading ? style.hidden : ''}`}
              >
                <FormEdit
                  form={newFormData?.formSchema || {}}
                  saveText={t('pages.admin.forms.new.save')}
                  saveForm={handleSaveForm}
                />
              </div>
            )}
          </Await>
        </Suspense>
      </div>
    </div>
  );
};

export default AdminNewFormPage;
