import React from 'react';
import Chip from 'components/Chip';
import { stringToHumanReadable } from 'utils/strings';
import { ReportStatus } from 'API';

const StatusChip = ({ status }: { status: keyof typeof ReportStatus }) => {
  switch (status) {
    case ReportStatus.PENDING:
      return <Chip variation="warning" text={stringToHumanReadable(status)} />;
    case ReportStatus.COMPLETED:
      return <Chip variation="success" text={stringToHumanReadable(status)} />;
    default:
      return <Chip variation="disabled" text={stringToHumanReadable(status)} />;
  }
};

export default StatusChip;
