import {
  CreateNoteInput,
  NotesByTestapplicationIDQueryVariables,
  UpdateNoteInput,
} from 'API';
import { generateClient } from 'aws-amplify/api';
import CustomGraphQlError from 'classes/GraphqlError';
import {
  customCreateNote,
  customDeleteNote,
  customUpdateNote,
} from 'myGraphql/myMutations';
import { customNotesByTestapplicationID } from 'myGraphql/myQueries';

/**
 * Query all the Notes of a TestApplication.
 * @param variables The variables passed to the query.
 * @returns An array of Notes.
 */
export const queryNotesByTestApplication = async (
  variables: NotesByTestapplicationIDQueryVariables
) => {
  const client = generateClient();

  let nextTokenFlag: string | null | undefined;

  const itemsToReturn = [];

  do {
    const response = await client.graphql({
      query: customNotesByTestapplicationID,
      variables: {
        ...variables,
        nextToken: nextTokenFlag,
      },
    });

    if (response.errors) {
      throw new CustomGraphQlError(
        'Error while querying notes by testApplication.',
        response.errors
      );
    }

    const { nextToken, items } = response.data.notesByTestapplicationID;

    nextTokenFlag = nextToken;

    itemsToReturn.push(...items);
  } while (nextTokenFlag);

  return itemsToReturn;
};

/**
 * Persists a new Note.
 * @param note The Note to persist.
 * @returns The persisted Note.
 */
export const newNote = async (note: CreateNoteInput) => {
  const client = generateClient();

  const response = await client.graphql({
    query: customCreateNote,
    variables: {
      input: note,
    },
  });

  if (response.errors) {
    throw new CustomGraphQlError(
      'Error while creating new note.',
      response.errors
    );
  }

  return response.data.createNote;
};

/**
 * Updates a Note.
 * @param input The Note with changes.
 * @returns The updated Note.
 */
export const updateNote = async (input: UpdateNoteInput) => {
  const client = generateClient();

  const response = await client.graphql({
    query: customUpdateNote,
    variables: {
      input,
    },
  });

  if (response.errors) {
    throw new CustomGraphQlError('Error while updating note.', response.errors);
  }

  return response.data.updateNote;
};

/**
 * Deletes a Note.
 * @param id The Note id.
 * @returns The deleted Note.
 */
export const deleteNote = async (id: string) => {
  const client = generateClient();

  const response = await client.graphql({
    query: customDeleteNote,
    variables: {
      input: {
        id,
      },
    },
  });

  if (response.errors) {
    throw new CustomGraphQlError('Error while deleting note.', response.errors);
  }

  return response.data.deleteNote;
};
