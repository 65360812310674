import { Decision, RootForm, TestCycle, User } from 'API';
import { get } from 'aws-amplify/api';
import { defer, LoaderFunction } from 'react-router-dom';
import { queryDecisionsByTestApplication } from 'services/graphql/Decision';
import { queryRootForm } from 'services/graphql/RootForm';
import { queryAllTestApplications } from 'services/graphql/TestApplication';
import { queryTestCycle } from 'services/graphql/TestCycle';
import { queryAllUsers } from 'services/graphql/User';
import { untilAmplifyFinishConfiguration } from 'utils/amplify';

export interface IUsersOfApplications extends User {
  attributes: {
    email: string;
    phone: string;
  };
}

const getUserData = async (userId: string | undefined) => {
  await untilAmplifyFinishConfiguration();

  const users = await queryAllUsers({
    filter: { owner: { eq: userId || '' } },
  });

  if (users.length === 0) {
    throw new Response('User not Found', { status: 404 });
  }

  const user = users[0];

  const attributesResponse = user.owner
    ? await get({
        apiName: 'userAPI',
        path: '/',
        options: {
          queryParams: {
            sub: user.owner,
          },
        },
      }).response
    : undefined;

  if (attributesResponse?.statusCode !== 200) {
    throw new Response('User does not exist.', { status: 404 });
  }

  const body = (await attributesResponse.body.json()) as {
    attributes: { Name: string; Value: string }[];
  };

  const { attributes } = body;

  const applications = (
    await queryAllTestApplications({
      filter: { ownerID: { eq: user.owner } },
    })
  ).map((application) => ({
    ...application,
    Decisions: undefined,
    FormAnswers: undefined,
    Notes: undefined,
  }));

  const decisionsResponsesArray = await Promise.allSettled(
    applications.map((application) =>
      queryDecisionsByTestApplication({ testapplicationID: application.id })
    )
  );

  const decisions = decisionsResponsesArray.reduce(
    (decisionsArray, currentDecisionResponse) =>
      currentDecisionResponse.status === 'fulfilled'
        ? decisionsArray.concat(currentDecisionResponse.value)
        : decisionsArray,
    [] as Decision[]
  );

  const cyclesIds: string[] = [];

  applications.forEach((application) => {
    if (
      application.testcycleID &&
      !cyclesIds.includes(application.testcycleID)
    ) {
      cyclesIds.push(application.testcycleID);
    }
  });

  const cyclesResponsesArray = await Promise.allSettled(
    cyclesIds.map((cycleId) => queryTestCycle(cycleId))
  );

  const cycles = cyclesResponsesArray.reduce(
    (cyclesArray, currentCycleResponse) =>
      currentCycleResponse.status === 'fulfilled' && currentCycleResponse.value
        ? [...cyclesArray, currentCycleResponse.value]
        : cyclesArray,
    [] as TestCycle[]
  );

  const rootFormsIds: string[] = [];

  cycles.forEach((cycle) => {
    if (cycle.rootformID && !rootFormsIds.includes(cycle.rootformID)) {
      rootFormsIds.push(cycle.rootformID);
    }
  });

  const rootFormsResponsesArray = await Promise.allSettled(
    rootFormsIds.map((rootFormId) => queryRootForm(rootFormId))
  );

  const rootForms = rootFormsResponsesArray.reduce(
    (rootFormsArray, currentRootFormResponse) =>
      currentRootFormResponse.status === 'fulfilled' &&
      currentRootFormResponse.value
        ? [...rootFormsArray, currentRootFormResponse.value]
        : rootFormsArray,
    [] as RootForm[]
  );

  return {
    user,
    decisions,
    cycles,
    rootForms,
    attributes,
    applications,
  };
};

export type TUserData = Awaited<Promise<ReturnType<typeof getUserData>>>;

const userLoader: LoaderFunction = async ({ params }) => {
  const { userId } = params;

  return defer({ userData: getUserData(userId) });
};

export default userLoader;
