import { post } from 'aws-amplify/api';
import { TJson } from 'pages/admin/forms/[formId]/components/TranslationsTab/components/JsonField/JsonField';
import { queryFormSchema } from 'services/graphql/FormSchema';
import {
  newFormTranslation,
  queryFormTranslationsByFormSchema,
  updateFormTranslation,
} from 'services/graphql/FormTranslations';
import { FormTranslation, Language } from 'API';
import { generateComponents } from './formio';

const findStrings: (components: object[]) => string[] = (components) => {
  const results: string[] = [];

  for (const component of components) {
    let type = '';

    if ('type' in component) {
      type = component.type as string;
    }

    if (type !== 'content' && type !== 'container') {
      if ('label' in component && typeof component.label === 'string') {
        const { label } = component;

        if (!('hideLabel' in component && component.hideLabel)) {
          results.push(label);
        }
      }

      if (
        'placeholder' in component &&
        typeof component.placeholder === 'string'
      ) {
        const { placeholder } = component;
        results.push(placeholder);
      }

      if ('legend' in component && typeof component.legend === 'string') {
        const { legend } = component;
        results.push(legend);
      }
    }

    if (
      'errors' in component &&
      typeof component.errors === 'object' &&
      component.errors
    ) {
      for (const error of Object.values(component.errors)) {
        results.push(error);
      }
    }
    if ('values' in component) {
      const { values } = component;
      results.push(...findStrings(values as object[]));
    }
    if ('columns' in component) {
      const { columns } = component;
      results.push(...findStrings(columns as object[]));
    }
    if ('components' in component) {
      const { components } = component;
      results.push(...findStrings(components as object[]));
    }
  }

  return results;
};

const filterNumbers = (texts: string[]) =>
  texts.filter((text) => {
    const regex1 = /^\d+$/g;

    const regex2 = /^\+?\d[\d\s-]{7,}$/g;

    return !text.match(regex1) && !text.match(regex2);
  });

const filterEmptyStrings = (texts: string[]) => texts.filter(Boolean);

const filterDuplicateStrings = (texts: string[]) => [...new Set(texts)];

const extractTextOfComponents = (components: object[]) => {
  const texts = findStrings(components);

  const textsWithoutNumbers = filterNumbers(texts);

  const textsWithoutEmptyStrings = filterEmptyStrings(textsWithoutNumbers);

  const textsWithoutDuplicates = filterDuplicateStrings(
    textsWithoutEmptyStrings
  );

  return textsWithoutDuplicates;
};

const generateTranslationObjectFromComponents = (components: object[]) => {
  const texts = extractTextOfComponents(components);

  const translationObject: { [key: string]: string } = {};
  for (const text of texts) {
    translationObject[text] = text;
  }

  return translationObject;
};

export const generateTranslatiosOfForm = async (formId: string) => {
  const formSchema = await queryFormSchema(formId);

  if (!formSchema) {
    throw new Error('Form not found.');
  }

  const components = await generateComponents(formSchema.id);

  const englishTranslationObject =
    generateTranslationObjectFromComponents(components);

  const oldTranslations = await queryFormTranslationsByFormSchema(formId);

  const oldEnglishTranslation = oldTranslations.find(
    (oldTranslation) => oldTranslation.lang === Language.EN
  );

  if (oldEnglishTranslation) {
    await updateFormTranslation({
      id: oldEnglishTranslation.id,
      autoTexts: JSON.stringify(englishTranslationObject),
    });
  } else {
    await newFormTranslation({
      formschemaID: formId,
      autoTexts: JSON.stringify(englishTranslationObject),
      manualTexts: JSON.stringify({}),
      lang: Language.EN,
    });
  }

  const languages = Object.values(Language);

  for (const language of languages) {
    if (language !== Language.EN) {
      const newTranslationObject = { ...englishTranslationObject };

      for (const key in newTranslationObject) {
        if (Object.prototype.hasOwnProperty.call(newTranslationObject, key)) {
          const textToTranslate = newTranslationObject[key];
          const translatedTextResponse = await post({
            apiName: 'habitat',
            path: '/translate',
            options: {
              body: {
                source: Language.EN.toLowerCase(),
                target: language.toLowerCase(),
                text: textToTranslate,
              },
            },
          }).response;

          const translatedText = await translatedTextResponse.body.text();

          newTranslationObject[key] = translatedText;
        }
      }

      const oldLanguageTranslation = oldTranslations.find(
        (oldTranslation) => oldTranslation.lang === language
      );

      if (oldLanguageTranslation) {
        await updateFormTranslation({
          id: oldLanguageTranslation.id,
          autoTexts: JSON.stringify(newTranslationObject),
        });
      } else {
        await newFormTranslation({
          formschemaID: formId,
          autoTexts: JSON.stringify(newTranslationObject),
          manualTexts: JSON.stringify({}),
          lang: language,
        });
      }
    }
  }
};

export const convertTranlationsToObject = (translations: FormTranslation[]) => {
  const translationsObject = translations.reduce(
    (prevValue: { [key: string]: TJson }, currentValue: FormTranslation) => {
      prevValue[currentValue.lang.toLowerCase()] = {
        ...(JSON.parse(currentValue.autoTexts) as TJson),
        ...(JSON.parse(currentValue.manualTexts) as TJson),
      };
      return prevValue;
    },
    {}
  );

  return translationsObject;
};
