import { ListHabitatsQueryVariables, UpdateHabitatInput } from 'API';
import { generateClient } from 'aws-amplify/api';
import CustomGraphQlError from 'classes/GraphqlError';
import { customUpdateHabitat } from 'myGraphql/myMutations';
import { customGetHabitat, customListHabitats } from 'myGraphql/myQueries';

/**
 * Query all the Habitats that have the _deleted property not equal to true.
 * @param variables
 * @returns
 */
export const queryAllHabitats = async (
  variables: ListHabitatsQueryVariables | undefined = undefined
) => {
  const client = generateClient();

  const itemsToReturn = [];
  let currentNextToken: string | null | undefined;

  do {
    const response = await client.graphql({
      query: customListHabitats,
      variables: {
        filter: {
          ...(variables ? variables.filter : {}),
        },
        nextToken: currentNextToken,
      },
    });

    if (response.errors) {
      throw new CustomGraphQlError(
        'Error while querying habitats.',
        response.errors
      );
    }

    const { items, nextToken } = response.data.listHabitats;

    currentNextToken = nextToken;

    itemsToReturn.push(...items);
  } while (currentNextToken);

  return itemsToReturn;
};

/**
 * Query a Habitat.
 * @param id The Habitat id.
 * @returns The Habitat found or null or undefined.
 */
export const queryHabitat = async (id: string) => {
  const client = generateClient();

  const response = await client.graphql({
    query: customGetHabitat,
    variables: {
      id,
    },
  });

  if (response.errors) {
    throw new CustomGraphQlError(
      'Error while querying habitat.',
      response.errors
    );
  }

  return response.data.getHabitat;
};

/**
 * Updates a Habitat.
 * @param input The Habitat with changes.
 * @returns The updated Habitat.
 */
export const updateHabitat = async (input: UpdateHabitatInput) => {
  const client = generateClient();

  const response = await client.graphql({
    query: customUpdateHabitat,
    variables: {
      input,
    },
  });

  if (response.errors) {
    throw new CustomGraphQlError(
      'Error while updating habitat.',
      response.errors
    );
  }

  return response.data.updateHabitat;
};
