import { TestApplication } from 'API';
import Skeleton from 'components/Skeleton';
import useAsync from 'hooks/utils/useAsync/useAsync';

import { useCallback } from 'react';
import { queryAllUsers } from 'services/graphql/User';

interface UsernameProps {
  application: TestApplication;
}

const Username = ({ application }: UsernameProps) => {
  const getUsername = useCallback(async () => {
    try {
      const ownerId = application.ownerID;
      if (!(typeof ownerId === 'string')) return 'unknown';

      const user = await queryAllUsers({ filter: { owner: { eq: ownerId } } });

      const currentUser = user[0];
      if (!currentUser) return 'unknown';
      return `${currentUser.firstName} ${currentUser.lastName}` || 'unknown';
    } catch (e) {
      return 'unknown';
    }
  }, [application]);

  const { value } = useAsync({
    asyncFunction: getUsername,
  });

  return value || <Skeleton variation="text" numOfCharacters={12} />;
};

export default Username;
