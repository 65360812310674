import React, { ReactNode } from 'react';
import { useBreakpointValue } from '@aws-amplify/ui-react';
import Skeleton from 'components/Skeleton';
import style from './LocalNavigation.module.css';

interface IItem {
  label: string;
  icon: ReactNode;
  hide?: boolean;
}

interface ILoadingItem {
  skeleton: boolean;
  numOfCharacters: number;
}

type TItem = IItem | ILoadingItem;

interface IProperties {
  current: number;
  items: TItem[];
  onChange: (index: number) => void;
}

const LocalNavigation = ({ current, items, onChange }: IProperties) => {
  const isMobile = useBreakpointValue({ base: true, medium: false });

  return (
    <div className={`${style.container}`}>
      {items.map((item, index) =>
        'skeleton' in item ? (
          <button
            key={`${index}`}
            type="button"
            className={`${style.menuItem} ${
              current === index ? style.active : ''
            }`}
            onClick={() => onChange(index)}
          >
            <Skeleton className={style.iconSkeleton} />
            {!isMobile && (
              <span className={style.label}>
                <Skeleton
                  variation="text"
                  numOfCharacters={item.numOfCharacters}
                />
              </span>
            )}
          </button>
        ) : (
          !item.hide && (
            <button
              key={`${item.label}_${index}`}
              type="button"
              className={`${style.menuItem} ${
                current === index ? style.active : ''
              }`}
              onClick={() => onChange(index)}
            >
              <div className={style.icon}>{item.icon}</div>
              {!isMobile && <span className={style.label}>{item.label}</span>}
            </button>
          )
        )
      )}
    </div>
  );
};

export default LocalNavigation;
