import {
  ComponentSchemasByFormschemaIDQueryVariables,
  CreateComponentSchemaInput,
} from 'API';
import { generateClient } from 'aws-amplify/api';
import CustomGraphQlError from 'classes/GraphqlError';
import {
  customCreateComponentSchema,
  customDeleteComponentSchema,
} from 'myGraphql/myMutations';
import { customComponentSchemasByFormschemaID } from 'myGraphql/myQueries';

/**
 * Query all the ComponentSchemas of a FormSchema that have the _deleted property not equal to true.
 * @param formSchemaId The FormSchema id.
 * @returns An array of ComponentSchemas.
 */
export const queryComponentSchemasByFormSchema = async (
  variables: ComponentSchemasByFormschemaIDQueryVariables
) => {
  const client = generateClient();

  let nextTokenFlag: string | null | undefined;

  const itemsToReturn = [];

  do {
    const response = await client.graphql({
      query: customComponentSchemasByFormschemaID,
      variables: {
        ...variables,
        nextToken: nextTokenFlag,
      },
    });

    if (response.errors) {
      throw new CustomGraphQlError(
        'Error while querying componentSchemas by formSchema.',
        response.errors
      );
    }

    const { nextToken, items } = response.data.componentSchemasByFormschemaID;
    nextTokenFlag = nextToken;

    itemsToReturn.push(...items);
  } while (nextTokenFlag);

  return itemsToReturn;
};

/**
 * Persists a new ComponentSchema.
 * @param componentSchema The ComponentSchema to persist.
 * @returns The ComponentSchema persisted.
 */
export const newComponentSchema = async (
  componentSchema: CreateComponentSchemaInput
) => {
  const client = generateClient();

  const response = await client.graphql({
    query: customCreateComponentSchema,
    variables: {
      input: componentSchema,
    },
  });

  if (response.errors) {
    throw new CustomGraphQlError(
      'Error while creating new componentSchema.',
      response.errors
    );
  }

  return response.data.createComponentSchema;
};

/**
 * Deletes a ComponentSchema.
 * @param id The ComponentSchema id.
 * @returns The deleted ComponentSchema.
 */
export const deleteComponentSchemaa = async (id: string) => {
  const client = generateClient();

  const response = await client.graphql({
    query: customDeleteComponentSchema,
    variables: {
      input: {
        id,
      },
    },
  });

  if (response.errors) {
    throw new CustomGraphQlError(
      'Error while deleting componentSchema.',
      response.errors
    );
  }

  return response.data.deleteComponentSchema;
};
