import { CreateFormTranslationInput, UpdateFormTranslationInput } from 'API';
import { generateClient } from 'aws-amplify/api';
import CustomGraphQlError from 'classes/GraphqlError';
import {
  customCreateFormTranslation,
  customUpdateFormTranslation,
} from 'myGraphql/myMutations';
import {
  customFormTranslationsByFormschemaID,
  customGetFormTranslation,
} from 'myGraphql/myQueries';

/**
 * Query all the FormTranslations of a FormSchema with the _deleted property not equal to true.
 * @param formSchemaId The FormSchema id.
 * @returns An array of FormTranslations.
 */
export const queryFormTranslationsByFormSchema = async (
  formSchemaId: string
) => {
  const client = generateClient();

  let nextTokenFlag: string | null | undefined;

  const itemsToReturn = [];

  do {
    const translationsResponse = await client.graphql({
      query: customFormTranslationsByFormschemaID,
      variables: {
        formschemaID: formSchemaId,
        nextToken: nextTokenFlag,
      },
    });

    if (translationsResponse.errors) {
      throw new CustomGraphQlError(
        'Error while querying formTranslations by formSchema.',
        translationsResponse.errors
      );
    }

    nextTokenFlag =
      translationsResponse.data.formTranslationsByFormschemaID.nextToken;

    itemsToReturn.push(
      ...translationsResponse.data.formTranslationsByFormschemaID.items
    );
  } while (nextTokenFlag);

  return itemsToReturn;
};

/**
 * Query a FormTranslation.
 * @param id The FormTranslation id.
 * @returns The FormTranslation found or null or undefined.
 */
export const queryFormTranslation = async (id: string) => {
  const client = generateClient();

  const response = await client.graphql({
    query: customGetFormTranslation,
    variables: {
      id,
    },
  });

  if (response.errors) {
    throw new CustomGraphQlError(
      'Error while querying formTranslation.',
      response.errors
    );
  }

  return response.data.getFormTranslation;
};

/**
 * Persists a FormTranslation.
 * @param formSchema The FormTranslation to persist.
 * @returns The persisted FormTranslation.
 */
export const newFormTranslation = async (
  formSchema: CreateFormTranslationInput
) => {
  const client = generateClient();

  const response = await client.graphql({
    query: customCreateFormTranslation,
    variables: {
      input: formSchema,
    },
  });

  if (response.errors) {
    throw new CustomGraphQlError(
      'Error while creating new formTranslation.',
      response.errors
    );
  }

  return response.data.createFormTranslation;
};

/**
 * Updates a FormTranslation.
 * @param input The FormTranslation with changes.
 * @returns The updated FormTranslation.
 */
export const updateFormTranslation = async (
  input: UpdateFormTranslationInput
) => {
  const client = generateClient();

  const response = await client.graphql({
    query: customUpdateFormTranslation,
    variables: {
      input,
    },
  });

  if (response.errors) {
    throw new CustomGraphQlError(
      'Error while updating formTranslation.',
      response.errors
    );
  }

  return response.data.updateFormTranslation;
};
