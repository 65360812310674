import { throttle } from 'lodash';
import {
  queryTestApplication,
  updateTestApplication,
} from 'services/graphql/TestApplication';
import {
  newFormAnswer,
  queryFormAnswersByTestApplication,
  updateFormAnswer,
} from 'services/graphql/FormAnswer';
import uploadSubmissionProps from './uploadSubmission.types';

const uploadSubmission = async ({
  submission,
  application,
  nextPage,
}: uploadSubmissionProps) => {
  try {
    if (application) {
      const submissionEntries = Object.entries(submission.data);

      const currentApplication = await queryTestApplication(application.id);

      if (currentApplication) {
        await updateTestApplication({
          id: currentApplication.id,
          lastPage: Number(nextPage),
        });
      }

      await Promise.allSettled(
        submissionEntries.map(([page, values]) =>
          (async () => {
            const persistedFormAnswer = await queryFormAnswersByTestApplication(
              {
                testapplicationID: application.id,
                filter: {
                  and: [
                    { page: { eq: page } },
                    { isCopy: { ne: true } },
                    { version: { eq: application.version } },
                  ],
                },
              }
            );

            if (persistedFormAnswer.length > 0) {
              await updateFormAnswer({
                id: persistedFormAnswer[0].id,
                values: JSON.stringify(values),
              });
            } else {
              await newFormAnswer({
                testapplicationID: application.id,
                page,
                values: JSON.stringify(values),
                version: application.version,
              });
            }
          })()
        )
      );
    }
  } catch (error) {
    console.log('Error persisting submission', error);
  }
};

export default throttle(uploadSubmission, 50);
