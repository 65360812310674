import { defer, LoaderFunction } from 'react-router-dom';
import { queryAllHabitats, queryHabitat } from 'services/graphql/Habitat';
import { untilAmplifyFinishConfiguration } from 'utils/amplify';

const getHabitatData = async (habitat: string | undefined) => {
  await untilAmplifyFinishConfiguration();

  const habitats = await queryAllHabitats({
    filter: { urlName: { eq: habitat } },
  });

  if (habitats.length === 0) {
    throw new Response('Habitat not Found', { status: 404 });
  }

  const habitatData = await queryHabitat(habitats[0].id);

  if (!habitatData) {
    throw new Response('Habitat not Found', { status: 404 });
  }

  return habitatData;
};

export type THabitatData = Awaited<Promise<ReturnType<typeof getHabitatData>>>;

const habitatLoader: LoaderFunction = async ({ params }) => {
  const { habitat } = params;

  return defer({ habitatData: getHabitatData(habitat) });
};

export default habitatLoader;
